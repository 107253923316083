import { Component, Injector, OnInit,Renderer2, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';



const debug = Debug('dgoods:shop:PortraitModePage');
@Component({
  selector: 'app-page-portrait-mode',
  templateUrl: './portrait-mode.page.view.html',
  styleUrls: ['./portrait-mode.page.view.scss']
})
export class PortraitModePage extends BasePageComponent implements OnInit, OnDestroy{

  constructor(injector: Injector, private render: Renderer2) {
    super(injector);
  }

  @ViewChild('root', { static: true })
  public rootEl: ElementRef;

  onOrientationChange;

  ngOnInit(): void {
    this.checkOrientation();

    this.onOrientationChange = window.addEventListener('orientationchange', (event: any) => {
      this.checkOrientation();
   });

    super.ngOnInit();
  }

  ngOnDestroy() {
    window.removeEventListener('orientationchange', this.onOrientationChange, false);
    super.ngOnDestroy();
  }

  checkOrientation() {
    switch (window.orientation) {
      case -90: case 90:
        //Landscape
        this.render.setStyle(this.rootEl.nativeElement, "display", "flex");
        this.render.setStyle(document.documentElement, "overflow", "hidden");
        this.render.setProperty(document.body, "scroll", "no");
        break;
      default:
        //Portrait
        this.render.setStyle(document.documentElement, "overflow", "scroll");
        this.render.setProperty(document.body, "scroll", "yes");

        this.render.setStyle(this.rootEl.nativeElement, "display", "none");
        break;
    }
  }
}

