import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../reducers/products.reducer';
import { ProductLine } from '../models/product.model';
import { Observable } from 'rxjs';
import * as fromActions from '../actions/products.actions';
import { map } from 'rxjs/operators';
import {CategoryDto, INewstickerResponse, ISpotLight, ProductLineDto} from '@modeso/types__dgoods-products';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';

@Injectable({
  providedIn: 'root'
})
export class ProductStoreProvider {

  constructor(public store: Store<fromFeature.AppState>) { }

  public fetchAllProducts() {
    this.store.dispatch(fromActions.getAllProducts());
  }

  public getAllProducts$(): Observable<ProductLineDto[]> {
    return this.store.pipe(
      select(fromFeature.selectFeatureProducts),
      map((productLine: ProductLineDto[]) => {
        return productLine;
      }));
  }

  public getProduct$(productLineId: string): Observable<ProductLineDto> {
    return this.store.pipe(select(fromFeature.selectProductById, { id: productLineId }),
      map((product: ProductLine) => {
        // retreive saved productLine array from storage if the store is empty
        const savedProducts = JSON.parse(localStorage.getItem('products'));
        const savedProduct = product ? product :
          (savedProducts && savedProducts.length ? savedProducts.find(x => x.productLineId === productLineId)
            : null);
        return savedProduct;
      })
    );
  }

  public getCategories$(): Observable<CategoryDto[]> {
    return this.store.pipe(select(fromFeature.selectFeaturCategories));
  }

  public getFitleredProducts$(): Observable<ProductLineDto[]> {
    return this.store.pipe(select(fromFeature.selectFilteredProducts));
  }

  public getTermsAndConditions$(productLineId: string): Observable<string> {
    return this.store.pipe(select(fromFeature.selectProductById, { id: productLineId }),
      map((product: ProductLine) => {
        // retreive saved products array from storage if the store is empty
        const savedProducts = JSON.parse(localStorage.getItem('products'));
        const savedProduct = product ? product :
          (savedProducts && savedProducts.length ? savedProducts.find(x => x.productLineId === productLineId)
            : null);
        if (savedProduct) {
          return savedProduct.termsAndConditions;
        }
      })
    );
  }

  public getNewsTicker(status: any): Observable<INewstickerResponse> {
    this.store.dispatch(fromActions.getNewsTicker({ payload: status }));
    return this.store.pipe(select(fromFeature.selectFeatureNewsTicker));
  }

  public selectNewsTicker(): Observable<INewstickerResponse> {
    return this.store.pipe(select(fromFeature.selectFeatureNewsTicker));
  }

  public getActiveFilter$():  Observable< CategoryDto | string> {
    return this.store.pipe(select(fromFeature.selectActiveFilter));
  }

  public setFilter(category: CategoryDto | string) {
    this.store.dispatch(fromActions.setActiveFilter({ payload: category }));
  }

  public getMenuItems$(): Observable<any> {
    this.store.dispatch(fromActions.getAllContentPages());

    return this.store.pipe(select(fromFeature.selectMenuItems));
  }
  public getAllContentPages$(): Observable<IContentPageResponse[]> {
    this.store.dispatch(fromActions.getAllContentPages());
    return this.store.pipe(select(fromFeature.selectFeatureContentPagesList));
  }
  public selectAllContentPages$(): Observable<IContentPageResponse[]> {
    return this.store.pipe(select(fromFeature.selectFeatureContentPagesList));
  }

  public getSpotlights$(){
    this.store.dispatch(fromActions.getSpotlights())
  }

  public selectSpotlightFromStore$(): Observable<any>{
    return this.store.pipe(select(fromFeature.selectSpotlights));
  }

  public selectUserHasSpotlightsFromStore$(): Observable<any>{
    return this.store.pipe(select(fromFeature.selectUserHasSpotlights));
  }

  public markSpotlightAsRead$(spotlightId){
    this.store.dispatch(fromActions.markSpotlightAsRead({payload: spotlightId}))
  }

  public getPublishedContentBox$(productlineId: string): Observable<IContentBoxResponse> {
    this.store.dispatch(fromActions.getPublishedContentBox({ payload: productlineId}));
    return this.store.pipe(select(fromFeature.selectFeatureContentBox));
  }

}

