import { Component, Input } from '@angular/core';

@Component({
  selector: 'order-confirmation-details2',
  templateUrl: './order-confirmation-details.component2.html',
  styleUrls: ['./order-confirmation-details.component2.scss']
})
export class DgoodsOrderConfirmationDetailsComponent2 {
  @Input() title?: string;
  @Input() injectedTitle?: string;

  constructor() { }



}
