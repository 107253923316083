import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { IDGoodsButtonDelegate, DGoodsOverlayComponent } from '@modeso/twint-lib-core-fe';

const debug = Debug('dgoods:shop:LoginPage');
@Component({
  selector: 'app-page-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePageComponent implements OnInit, IDGoodsButtonDelegate {

  @ViewChild('incognitoOverlay', { static: false }) incognitoOverlay: DGoodsOverlayComponent;

  constructor(injector: Injector, private store: Store<fromApp.AppState>) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.detectIncognitoModeOnChrome();
    this.detectPrivateModeOnSafari();
  }

  onButtonClicked() {
    debug('Open Twint app');
    this.switchToAppAndCloseWindow();
  }

  switchToAppAndCloseWindow() {
    const protocol = localStorage.getItem('protocol');
    const device = localStorage.getItem('device');
    if (!protocol || !device) {
      debug('state is not defined');
      return;
    }
    debug(protocol);
    debug(device);
    if (device === 'ios') {
      const redirectURL = `${protocol}://ch.twint.wallet`;
      debug(redirectURL);
      window.location.href = redirectURL;
    } else if (device === 'android') {
      // tslint:disable-next-line:max-line-length
      const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
      ////intent://ch.twint.wallet#Intent;package=ch.twint.payment.configurable;scheme=twint;end
      debug(`android browser-app-switch-redirect ${redirectURL}`);
      debug(redirectURL);
      window.location.href = redirectURL;
    }
  }

  async detectIncognitoModeOnChrome() {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { usage, quota } = await navigator.storage.estimate();
      console.log(`Using ${usage} out of ${quota} bytes.`);

      if (quota < 120000000) {
        this.incognitoOverlay.hidden = false;
      } else {
        debug('Not Incognito');
      }
    } else {
      debug('Can not detect');
    }
  }

  detectPrivateModeOnSafari() {
    try {
      window.localStorage.setItem('test', '1');
    } catch (e) {
      this.incognitoOverlay.hidden = false;
    }
  }

}

