<div class="order-confirmation-container">
  <app-hamburger-menu-component *ngIf="directedFromHistory" [itemTitle]="'voucher_details_title'" [backBtnRoute]="backBtnRoute" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>

  <div *ngIf="!dataSource" class="bordered">
    <dgoods-dots-loading-indicator></dgoods-dots-loading-indicator>
  </div>

  <order-confirmation2 *ngIf="dataSource"
    [productConfiguration]="dataSource.productConfiguration"
    [productImage]="image"
    [currency]="dataSource.currency"
    [price]="dataSource.price"
    [productName]="dataSource.productName"
    [date]="dataSource.date"
    [email]="dataSource.email"
    [status]="dataSource.status"
    [isInProductionMode]="isInProductionMode"
    [discounts]="dataSource.discounts"
    [caption] = "dataSource.productCaption"
    >
  </order-confirmation2>

</div>


