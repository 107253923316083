// loading-spinner.component.ts
import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  landingPageLoading$: Observable<boolean>;
  spotlightsLoading$: Observable<boolean>;
  ordersHistoryLoading$: Observable<boolean>;

  constructor(private loadingService: LoadingService, private renderer: Renderer2) {
    this.landingPageLoading$ = this.loadingService.landingPageLoading$;
    this.spotlightsLoading$ = this.loadingService.spotlightsLoading$;
    this.ordersHistoryLoading$ = this.loadingService.ordersHistoryLoading$;

  }

  setBodyOverFlow(loading) {
    if (loading) {
      this.renderer.setAttribute(document.body, 'style', 'overflow-y: hidden !important;');
    } else{
      this.renderer.setAttribute(document.body, 'style', '');
    }
    return loading;
  }
}
