import { Component, Injector, Input, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { SpendingLimitType } from '@modeso/types__dgoods-cart';
import { AmountLimitExceededService } from '../../shared/services/amountLimitExceededService.service';


const debug = Debug('dgoods:shop:ErrorPage');
@Component({
  selector: 'app-page-error',
  templateUrl: './error.page.view.html',
  styleUrls: ['./error.page.view.scss']
})
export class ErrorPage extends BasePageComponent implements OnInit, IDGoodsButtonDelegate {
  queryParamUpgraded: string;
  canUpgrade: boolean;
  errorMessageKey: string;
  year: number;
  amount: number;
  annualAmount:number;
  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private limitExceededService:AmountLimitExceededService,
    injector: Injector
  ) {
    super(injector);
    this.queryParamUpgraded = this.route.snapshot.queryParamMap.get('canUpgradeLimit');
    this.amount = limitExceededService.getAmount();
    this.annualAmount = limitExceededService.getAnnualAmount();
    const type = this.route.snapshot.queryParamMap.get('type');
    this.canUpgrade = this.queryParamUpgraded === 'true';
    this.errorMessageKey = !this.queryParamUpgraded ? "dgoods_shop_default_error_msg" :
    (this.canUpgrade ? 'dgoods_sweepay_exceedlimit_error_message_with_upgrade'
    : (type === SpendingLimitType.Yearly ? "dgoods_sweepay_exceedlimit_yearly_error_message_without_upgrade" : (
      type === SpendingLimitType.Daily ? "dgoods_sweepay_exceedlimit_daily_error_message_without_upgrade" : "dgoods_sweepay_general_error_message"
    )
));

    this.year =  new Date().getFullYear() + 1;
    debug('Error Page');
  }
  onButtonClicked(sender: DGoodsButtonComponent): void {
    if(sender.state === 'backToApp') {
      this.redirectToLandingBuy();
    } else if (sender.state ==='toRegisteration') {
      window.open(sessionStorage.getItem('registerationUrl'),'__blank');
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(!this.queryParamUpgraded) {
      setTimeout(() => {
        this.switchToAppAndCloseWindow();
      }, 1000);
    }
  
  }

  switchToAppAndCloseWindow() {
    const protocol = localStorage.getItem('protocol');
    const device = localStorage.getItem('device');
    debug(protocol);
    debug(device);
    if (!protocol || !device) {
      debug('state is not defined');
      debug('can not switch to app');
      return;
    }
    if (device === 'ios') {
      const redirectURL = `${protocol}://ch.twint.wallet`;
      debug(redirectURL);
      window.location.href = redirectURL;
    } else if (device === 'android') {
      // tslint:disable-next-line:max-line-length
      const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
      //intent://ch.twint.wallet#Intent;package=ch.twint.payment.configurable;scheme=twint;end
      debug(`android browser-app-switch-redirect ${redirectURL}`);
      debug(redirectURL);
      window.location.href = redirectURL;
    }
  }

  private redirectToLandingBuy() {
    this.router.navigateByUrl('/de-ch/landing-buy');
  }
}

