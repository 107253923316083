<div class="error-page-container">
  <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
  <div class="error-content">
    <div class="error-msg-img">
      <img class="error-logo" src="assets/icon-denied.png" />
      <span></span>
      <span class="error-msg">
        {{ errorMessageKey | localize | async |  replace:'{amount}': amount | replace:'{annualAmount}': annualAmount |replace:'{year}': year }}</span>
    </div>
  </div>
  <div >
    <dgoods-button [delegate]="this" state="toRegisteration" class="register-btn-position" *ngIf="canUpgrade">
    {{'dgoods_sweepay_exceededlimit_registeration_btn' | localize | async }}
     </dgoods-button>
    <dgoods-button [delegate]="this" [ngClass]="{'white-btn': canUpgrade }"
    state="backToApp" class="close-btn-position" *ngIf="queryParamUpgraded">
      {{ (!canUpgrade ? 'dgoods_sweepay_exceededlimit_return_to_home_page_btn' : 
      'dgoods_sweepay_exceededlimit_cancel_purchase_btn' )| localize | async}}
     </dgoods-button>
  </div>

</div>
