
import { Component, OnInit, Injector } from '@angular/core';
import { DGoodsTableDataSource } from '../../components/VoucherList/dgoods-table/dgoods-table.datasource';
import moment from 'moment';
import Debug from 'debug';
import { PurchasingHistoryPageControllerDirective } from './purchasing-history.page.controller';
import { Location } from '@angular/common';
import { CartProvider, OrderMetadata } from '@modeso/dgoods-lib-cart-fe';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { skip } from 'rxjs';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { LoadingService } from '../../shared/services/loading.service';
const debug = Debug('dgoods:shared:PurchasingHistoryPageUiTest');

@Component({
  selector: 'app-purchasing-history-page',
  templateUrl: './purchasing-history.page.view.html',
  styleUrls: ['./purchasing-history.page.view.scss']
})
export class PurchasingHistoryPage extends PurchasingHistoryPageControllerDirective implements OnInit {
  constructor(analyticsProvider: AnalyticsProvider,location: Location, injector: Injector, private cartProvider: CartProvider,
    router: Router, private route: ActivatedRoute, private loadingService: LoadingService) {
    super(analyticsProvider, location, injector, router);
    this.getPurchasedProducts();
  }

  dataSource: DGoodsTableDataSource[] = [];
  public loading = true;

  menuItems = [];
  backBtnRoute = '../../de-ch/landing-buy';
  menuState = MenuItemStates.CHILD;


  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push( this.cartProvider.getError$().subscribe((x) => {
      if(x) {
        this.router.navigate(['../../de-ch/error']);
      }
    }))
  }

  getPurchasedProducts() {
    this.loadingService.setOrdersHistoryLoading(true);
    this.loading = true;
    this.subscriptions.push(
      this.cartProvider.getPurchasedProducts$().pipe(skip(1))
        .subscribe((purchasedProducts: OrderMetadata[]) => {
          if (purchasedProducts) {
            purchasedProducts.forEach((element) => {
              const newElement = {...element};
              const tempOrderURL = element.orderReceiptUrl.split('/');
              newElement.reference = tempOrderURL[tempOrderURL.length - 1];
              this.dataSource.push(new DGoodsTableDataSource(newElement.productName, newElement.reference, moment(newElement.date).toDate(),
              newElement.orderReceiptUrl, newElement.price , newElement.productLineImages, newElement.discounts, newElement.productCaption));
            });
            this.loading = false;
            this.loadingService.setOrdersHistoryLoading(false);
          }
        })
    );


  }

  // onRowClick(data: IDGoodsTableDataSource);

  onClickedDataTable(data: DGoodsTableDataSource) {
    debug(data);
    const linkParts = data.link.split('/');
    debug(linkParts[linkParts.length - 1]);
    this.router.navigate(['../../de-ch/order-confirmation/', linkParts[linkParts.length - 1], { history: 'true' } ]);
  }

}
