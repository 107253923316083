import { createAction, Action, props } from '@ngrx/store';
import { ISavePageViewResponse, ISavePageViewRequest } from '../models/interfaces/savePage.interface';

export const onSavePageView = createAction(
  '[Dgoods save page view] save page view',
  props<{ payload: ISavePageViewRequest }>()
);

export const onSavePageViewSuccess = createAction(
  '[Dgoods save page view successfully] save page view successfully',
  props< { payload: ISavePageViewResponse }>()
);

export const onSavePageViewFailed = createAction(
  '[Dgoods save page view failed] save page view failed'
);

export interface IActionWithPayload<T> extends Action {
  payload: T;
}
