
<div class="otp-overlay">

  <app-hamburger-menu-component [backBtnRoute]="sweepayOTPNavigation.redirectFrom" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>

  <form [formGroup]="otpForm">

  <div class="page-padding">
    <p class="header-style">{{"dgoods_otp_title" | localize | async}}</p>


    <div class="input_inactive">
      <mat-form-field class="mat-form-field-display" appearance="outline">
        <mat-label>{{"dgoods_otp_code" | localize | async}}</mat-label>
        <input matInput  formControlName="otpValue" (blur)="onChange($event.target.value)" placeholder="{{ 'dgoods_otp' | localize | async }}"
        type="tel" required
        />
      </mat-form-field>

      <div class="validation-style" *ngIf="otpValue.invalid && (otpValue.dirty || otpValue.touched)">
        <div *ngIf="otpValue.errors?.required || otpValue.errors?.minlength ||otpValue.errors?.maxlength">
          {{ "dgoods_shop_otp_validation" | localize | async }}
        </div>
      </div>

      <div class="validation-style"  *ngIf="!isMsgDisappear">
        <span> {{ errorMsg | localize | async }}</span>
      </div>
    </div>
    <div class="text-style">

    <span class="span-text"
      [innerHTML]="'dgoods_shop_otp_info' | localize | async | replace:'{phoneNumber}': phoneNumber">
    </span>
      <br>
      {{"dgoods_shop_otp_recieve_SMS" | localize | async}}
      <a (click)="urlfn()"> {{'dgoods_shop_otp_send_code' | localize | async}} </a>
    </div>

  </div >
</form>
  <div class="fixed-padding"></div>

  <app-checkout-flyout-component *ngIf="navigationData"
      [twintTC]="navigationData.twintTC"
      [product]="navigationData.product"
      [price]="navigationData.amount"
      [disablePurchaseBtn]="disablePurchaseBtn"
      [couponState]="navigationData.couponState"
      [currency]="navigationData.currency"
      [redirectFrom]="redirectFrom"
      (userPurchasedProduct)="onButtonClicked()">
  </app-checkout-flyout-component>

  <app-product-checkout-component #productCheckout></app-product-checkout-component>

</div>






