import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalizationService, LocalizationResponse } from '../services/localization.service';
import * as fromActions from './../actions/localization.actions';
import * as fromReducer from './../reducers/localization.reducer';
import { mergeMap, map, catchError, retry, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { LocalizationStoreProvider } from '../providers/localizationStore.provider';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizationEffects');

@Injectable()
export class LocalizationEffects {
  constructor(
    private actions$: Actions,
    private service: LocalizationService,
    private localizationStoreProvider: LocalizationStoreProvider
  ) {}

  loadLocalizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLocalizations.type),
      withLatestFrom(this.localizationStoreProvider.store.select(fromReducer.selectFeature)),
      mergeMap(([actions,localizations]) => {
        if(localizations.ids.length > 0)
        {
          return EMPTY;
        }
        return this.service.getLocalizations().pipe(
          retry(1),
          tap((response: LocalizationResponse) => {
            // send to dependecies
            this.localizationStoreProvider.notifyObservers(response.localizations);
          }),
          map((response) => fromActions.onLocalizationsLoadedSuccessfully({ payload: response.localizations })),
          catchError((error) => of(fromActions.onLocalizationsLoadingFailed({ payload: error })))
        );
      })
    )
  );

  errorOnLoadLocalizations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onLocalizationsLoadingFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadLocalizationsErrors(action.payload))
      ),
    { dispatch: false }
  );

  loadLocalizationByKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ActionWithContentId>(fromActions.getLocalizationByKey.type),
      mergeMap((action) => {
        debug(`content should be loaded for ${action.key}`);
        return this.service.getLocalizationByKey(action.key).pipe(
          retry(1),
          tap((response) => this.log(response)),
          map((response) => fromActions.onLocalizationByKeyLoadedSuccessfully({ payload: response.localization })),
          catchError((error) => of(fromActions.onLocalizationByKeyLoadingFailed({ payload: error })))
        );
      })
    )
  );

  loadLocalizationsForAllLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ActionWithPayload<any>>(fromActions.getLocalizationsForAllLanguages.type),
      mergeMap(() => {
        return this.service.getLocalizationsForAllLanguages().pipe(
          retry(1),
          tap((response) => this.log(response)),
          map((response) =>
            fromActions.onLocalizationsForAllLanguagesLoadedSuccessfully({ payload: response.translations })
          ),
          catchError((error) => of(fromActions.onLocalizationsForAllLanguagesLoadingFailed({ payload: error })))
        );
      })
    )
  );

  updateLocalization$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ActionWithPayload<any>>(fromActions.updateLocalization.type),
      mergeMap((action) => {
        return this.service.updateLocalization(action.payload.key, action.payload.updatedTranslations).pipe(
          retry(1),
          tap((response) => this.log(response)),
          map((response) => fromActions.onUpdateLocalizationSuccessfully({ payload: response.translations })),
          catchError((error) => of(fromActions.onUpdateLocalizationSuccessfully({ payload: error })))
        );
      })
    )
  );

  errorOnLoadLocalizationByKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.onLocalizationByKeyLoadingFailed.type),
        tap((action: fromActions.ActionWithPayload<any>) => this.handleOnLoadLocalizationsErrors(action.payload))
      ),
    { dispatch: false }
  );

  handleOnLoadLocalizationsErrors(error: any) {
    debug(error);
    return error;
  }

  log(data: any) {
    debug(data);
  }
}
