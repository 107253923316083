/*
 * Public API Surface of products
 */

export * from './services/products.service';
export * from './components/digital-product-list/digital-product-list.component';
export * from './components/digital-product-tile/digital-product-tile.component';
export * from './provider/productStore.provider';
export * from './models/product.model';
export * from './products.config';
export * from './products.module';
