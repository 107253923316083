import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthHandlerDelegate } from '@modeso/twint-lib-session-fe';
import Debug from 'debug';
const debug = Debug('dgoods:shop:SessionOrchestrator');

@Injectable({
  providedIn: 'root'
})
export class SessionOrchestrator implements IAuthHandlerDelegate {

  constructor(private router: Router) {}

  onSessionExired() {
    debug(this.router.url);
    if (!this.router.isActive('/de-ch/login', false)) {
      debug('onSessionExired redirect to mocked');
      this.router.navigateByUrl('/de-ch/login');
    }

  }

}
