import { Component, OnInit } from '@angular/core';
import Debug from 'debug';
import { Router } from '@angular/router';

const debug = Debug('dgoods:project:MaintenancePage');

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.page.html',
  styleUrls: ['./maintenance.page.scss']
})
export class MaintenancePage implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

  }

}
