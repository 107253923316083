import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './../actions/dgoods.analytics.actions';


export const analyticsFeatureKey = 'modesoAnalyticsMicroservice';

// State Declarations - START

export interface AppState {
  modesoAnalyticsMicroservice;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoAnalyticsMicroservice;

// Selectors Declarations - END

// Reducer Declarations - START


const analyticsReducer = createReducer(
  {},
  on(fromActions.onSavePageView, (state) => ({ ...state })),
  on(fromActions.onSavePageViewSuccess, (state) => ({ ...state })),
  on(fromActions.onSavePageViewFailed, (state) => ({ ...state }))
);

export function reducer(state ,action: Action) {
  return analyticsReducer(state, action);
}
