import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPreventVerticalScroll]'
})
export class PreventVerticalScrollDirective {

    private startY = 0;

  constructor(private el: ElementRef) { }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e: TouchEvent) {
    this.startY = e.touches[0].clientY;

    // Add an innocuous event handler to fix iOS 10 scrolling bug
    window.addEventListener('scroll', this.innocuousScrollHandler, { passive: false });
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e: TouchEvent) {
    const deltaY = e.touches[0].clientY - this.startY;
    if (Math.abs(deltaY) > 10) {
      e.preventDefault();
    }
  }

  private innocuousScrollHandler = () => {
    // This handler does nothing, but prevents iOS 10 scrolling bug
  };

}