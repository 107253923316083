export interface ProductLine {
  catalogId: string;
  productLineId: string;
  productLineName: string;
  productLineDescription: string;
  locale: string;
  productLineImages: ProductLineImage[];
  availableProducts: Product[];
  fulfillmentCharacteristics: BHNFulfillmentCharacteristics;
  termsAndConditions: BHNTermsAndCondition;
  productConfiguration: any;
  redemptionCharacteristics: any;
  blocked: boolean;
  enforceTC?: boolean;
}
export interface Product {
  productId: string;
  currency: string;
  minPrice: number;
  maxPrice: number;
  isDenomination: boolean;
}

export interface ProductLineImage {
  id: string;
  frontImage: string;
  imageSize: string;
  imageType: string;
}

export interface BHNFulfillmentCharacteristics {
  id: string;
  holdTimeSeconds: number;
  printingType: string;
  provisioningType: string;
  fulfillmentMethod: string;
}
export interface BHNTermsAndCondition {
  id?: string;
  termsAndConditions?: string;
  termsAndConditionsType?: string;
}
