import { HttpErrorResponse } from '@angular/common/http';
import { createAction, Action, props } from '@ngrx/store';
import { ILookUpAccountResponse, ISendMobilePhoneOtpRequest, IUpdateSweepayAccountRequest, IUpdateSweepayAccountResponse, IUpdateUserRequest, IValidateSweepayOtpRequest, IValidateSweepayOtpResponse } from '../services/dgoods.user.service';

export const onUpdateUser = createAction(
  '[Dgoods update user] update user',
  props<{ payload: IUpdateUserRequest }>()
);

export const onUpdateUserSuccess = createAction(
  '[Dgoods update user successfully] update user successfully',
  props<{ payload: IUpdateUserRequest }>()
);

export const onUpdateUserFailed = createAction(
  '[Dgoods update user failed] update user failed',
  props<{ payload: IUpdateUserRequest }>()
);

export const onGetSweepayPhoneNumber = createAction(
  '[Dgoods get user phonenumber] get user phonenumber',
);

export const onGetSweepayPhoneNumberSuccess = createAction(
  '[Dgoods get user phonenumber successfully] get user phonenumber successfully',
  props<{ payload: string }>()
);

export const onGetSweepayPhoneNumberFailed = createAction(
  '[Dgoods get user phonenumber failed] get user phonenumber failed',
  props<{ payload: HttpErrorResponse }>()
);

export const onLookUpAccount= createAction(
  '[Dgoods lookup account] get lookup account',
);

export const onLookupAccountSuccess = createAction(
  '[Dgoods lookup account successfully] get lookup account successfully',
  props<{ payload: ILookUpAccountResponse }>()
);

export const onLookupAccountFailed = createAction(
  '[Dgoods lookup account failed] get lookup account failed',
  props<{ payload: HttpErrorResponse }>()
);

export const onUpdateSweepayAccountWithPhoneNo= createAction(
  '[Dgoods update phonenumber] update phone number',
  props<{ payload: IUpdateSweepayAccountRequest }>()
);

export const onUpdateSweepayAccountWithPhoneNoSuccess = createAction(
  '[Dgoods update phone number successfully] update phone number successfully',
  props<{ payload: IUpdateSweepayAccountResponse }>()
);

export const onUpdateSweepayAccountWithPhoneNoFailed = createAction(
  '[Dgoods update phone number failed] update phone number failed',
  props<{ payload: HttpErrorResponse }>()
);


export const onResendOtpSweepayAccount= createAction(
  '[Dgoods resend otp] resend otp',
  props<{ payload: ISendMobilePhoneOtpRequest }>()
);

export const onResendOtpSweepayAccountSuccess = createAction(
  '[Dgoods resend otp successfully] resend otp successfully',
  props<{ payload: IUpdateSweepayAccountResponse }>()
);

export const onResendOtpSweepayAccountFailed = createAction(
  '[Dgoods resend otp failed] resend otp failed',
  props<{ payload: HttpErrorResponse }>()
);

export const onValidateSweepayOtp= createAction(
  '[Dgoods validate otp] validate otp',
  props<{ payload: IValidateSweepayOtpRequest }>()
);

export const onValidateSweepayOtpSuccess = createAction(
  '[Dgoods validate otp successfully] validate otp successfully',
  props<{ payload: IValidateSweepayOtpResponse }>()
);

export const onValidateSweepayOtpFailed = createAction(
  '[Dgoods validate otp failed] validate otp failed',
  props<{ payload: HttpErrorResponse }>()
);

export interface IActionWithPayload<T> extends Action {
  payload: T;
}
