<div class="product-buy-container {{hideFlyout? 'remove-lower-space': ''}}">

  <app-hamburger-menu-component [backBtnRoute]="backBtnRoute" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>

  <div *ngIf="provider">

    <div class="image-container">
      <img class="image" src={{displayImage}}>
    </div>

    <app-price-list-component
        [product]="provider"
        [isPageRefreshed]="isPageRefreshed"
        [previousSelectedPrice]="amount"
        [unviersalProductCode]="unviersalProductCode"
        (selectedPrice)="selectedProductPrice($event)">
    </app-price-list-component>


    <div class="contentbox-container">
        <app-contentbox-component [contentBox]="contentBox" >
        </app-contentbox-component>
    </div>

    <app-user-email-display-component *ngIf="userEmail" [userEmail]="userEmail" [productId]="productLineId"></app-user-email-display-component>

    <div class="code-container" *ngIf="couponFeatureState && provider.couponsEnabled" #couponForm>
        <app-coupon-component
          [product]="productLineName"
          [amount]="amount"
          [couponState]="couponFeatureState"
          (couponCode)="couponCode($event)"
          (exceedInvalidError)="checkUserInvalidCodeLimitation($event)"
          (focused)="handleElementInView()"
          >
        </app-coupon-component>
    </div>

    <app-expandable-text-component  [expandableObject]="expandableDescription"></app-expandable-text-component>

    <div [class]="hideFlyout ? 'fixed-padding-small' : 'fixed-padding-big'">

    </div>

    <div #flyout>
      <app-checkout-flyout-component
             *ngIf="!hideFlyout"
              [twintTC]="twintTC"
              [product]="provider"
              [price]="amount"
              [disablePurchaseBtn]="purchaseBtnDisabled"
              [couponState]="couponFeatureState"
              [currency]="currency"
              [redirectFrom]="redirectFrom"
              (generalTermsAndConditions)="setGTCs($event)"
              (productTerms)="checkProductTermsAndConditionAcceptance($event)"
              (userPurchasedProduct)="onButtonClicked()"
              >
      </app-checkout-flyout-component>

    </div>

  </div>
</div>


<app-product-checkout-component #productCheckout></app-product-checkout-component>
