import { Component, Input } from '@angular/core';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsOverlayComponent');
export interface IDGoodsOverlayDelegate {
  onOverlayButtonClicked(): void;
}

@Component({
  selector: 'dgoods-overlay-new',
  templateUrl: './dgoods-overlay.component.html',
  styleUrls: ['./dgoods-overlay.component.scss']
})
export class DGoodsOverlayComponentNew implements IDGoodsButtonDelegate{

  @Input() hidden?: boolean;
  @Input() delegate?: IDGoodsOverlayDelegate;
  @Input() message: string;
  @Input() error?: boolean;
  @Input() progress?: boolean;
  @Input() html?: boolean;
  @Input() redemtion?: boolean;
  @Input() htmlScroll? = false;
  @Input() copyOverlay? = false;
  @Input() scannableCode? = false;
  @Input() backgroundColor? = undefined;

  onClick() {
    if (this.delegate) {
      debug(this.message);
      this.delegate.onOverlayButtonClicked();
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  onButtonClicked(sender: DGoodsButtonComponent): void {
    if (this.delegate) {
      debug(this.message);
      this.delegate.onOverlayButtonClicked();
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }
}
