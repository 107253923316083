/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition,} from '@angular/animations';

export interface  MenuItem {
  title: string;
  routeLink: string;
  icon:string;
}

export enum  MenuItemStates {
  COLLAPSED = 'Collapsed',
  CHILD = 'child',
  EXPANDED = 'expanded'
}

@Component({
  selector: 'app-hamburger-menu-component',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['./hamburger-menu.component.scss'],
  animations: [
    trigger('hamburguerX', [
      /*
        state hamburguer => is the regular 3 lines style.
        states topX, hide, and bottomX => used to style the X element
      */
      state('hamburguer', style({})),
      // style top bar to create the X
      state(
        'topX',
        style({
          transform: 'rotate(45deg)',
          transformOrigin: 'left',
          // margin: '6px',
          // width: '16px',
          // height: '2px'
        })
      ),
      // hides element when create the X (used in the middle bar)
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      // style bottom bar to create the X
      state(
        'bottomX',
        style({
          transform: 'rotate(-45deg)',
          transformOrigin: 'left',
          // margin: '6px',
          // width: '16px',
          // height: '2px'


        })
      ),
      transition('* => *', [
        animate('0.2s'), // controls animation speed
      ]),
    ]),
  ],
})
export class HamburgerMenuComponent  implements OnInit {


  @Input() menuItems:MenuItem[];
  @Input() menuItemsContentPages:MenuItem[];
  @Input() backBtnRoute: string;
  @Input() menuState: MenuItemStates;
  @Input() itemTitle?;
  @Input() itemConstTitle?;

  constructor(private router: Router, private renderer: Renderer2) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  public menuOpened() {
    this.menuState = MenuItemStates.EXPANDED;
    document.documentElement.style.overflow = 'hidden';  // For older iOS versions
    document.body.style.overflow = 'hidden';            // For newer iOS versions
    document.body.style.position = 'fixed';
   }

  public menuClosed() {
    this.menuState = MenuItemStates.COLLAPSED;
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    document.body.style.position = '';
  }

  onBackClick() {

    this.router.navigate([this.backBtnRoute]);

  }

  public get menuItemStates(): typeof MenuItemStates {
    return MenuItemStates;
  }
}
