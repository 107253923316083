import { Injectable } from '@angular/core';
import * as fromActions from './../actions/dgoods.analytics.actions';
import * as fromFeature from '../reducers/dgoods.analytics.reducer';
import { Store, select } from '@ngrx/store';
import { ISavePageViewRequest } from '../models/interfaces/savePage.interface';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }

  public savePageView$(body: ISavePageViewRequest) {
    this.store.dispatch(fromActions.onSavePageView({ payload: body }));
  }

}
