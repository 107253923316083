import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { ILookUpAccountResponse, IUpdateSweepayAccountResponse, IValidateSweepayOtpResponse } from '../services/dgoods.user.service';
import * as fromActions from './../actions/dgoods.user.actions';


export const userFeatureKey = 'modesoUserMicroservice';

// State Declarations - START

export interface FeatureState {
  email: string;
  phoneNumber: string
  validateOtp: IValidateSweepayOtpResponse;
  updateSweepayAccount: IUpdateSweepayAccountResponse;
  resendOtpSweepayAccount: IUpdateSweepayAccountResponse;
  getSweepayAccount: ILookUpAccountResponse;
  error: any;

}

export interface AppState {
  modesoUserMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoUserMicroservice;
export const featureUser = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const featureSweepayPhoneNumber = createSelector(
  selectFeature,
  (state: FeatureState) => state.phoneNumber
);


export const featurelookupSweepayAccount = createSelector(
  selectFeature,
  (state: FeatureState) => state.getSweepayAccount
);

export const featureUpdateSweepayAccount = createSelector(
  selectFeature,
  (state: FeatureState) => state.updateSweepayAccount
);

export const featureResendOtpSweepayAccount = createSelector(
  selectFeature,
  (state: FeatureState) => state.resendOtpSweepayAccount
);

export const featureValidateSweepayOtp = createSelector(
  selectFeature,
  (state: FeatureState) => state.validateOtp
);

export const featureError = createSelector(
  selectFeature,
  (state: FeatureState) => state.error
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  email: null,
  validateOtp: null,
  updateSweepayAccount:  undefined,
  getSweepayAccount: null,
  error: undefined,
  resendOtpSweepayAccount:undefined,
  phoneNumber:undefined

};

const userReducer = createReducer(
  initialState,
  on(fromActions.onUpdateUser, (state) => ({ ...state })),
  on(fromActions.onUpdateUserSuccess, (state, action) => ({ ...state, email: action.payload.email, status: undefined })),
  on(fromActions.onUpdateUserFailed, (state, action) => ({ ...state, status: action.payload.status })),

  on(fromActions.onGetSweepayPhoneNumber, (state) => ({ ...state })),
  on(fromActions.onGetSweepayPhoneNumberSuccess, (state, action) => ({ ...state, phoneNumber: action.payload, status: undefined })),
  on(fromActions.onGetSweepayPhoneNumberFailed, (state, action) => ({ ...state, status: action.payload.status })),


  on(fromActions.onLookUpAccount, (state) => ({ ...state })),
  on(fromActions.onLookupAccountSuccess, (state, action) => ({ ...state, getSweepayAccount: action.payload, status: undefined })),
  on(fromActions.onLookupAccountFailed, (state, action) => ({ ...state, status:undefined })),


  on(fromActions.onUpdateSweepayAccountWithPhoneNo, (state) => ({ ...state, updateSweepayAccount:undefined})),
  on(fromActions.onUpdateSweepayAccountWithPhoneNoSuccess, (state, action) => ({ ...state, updateSweepayAccount: action.payload, status: undefined })),
  on(fromActions.onUpdateSweepayAccountWithPhoneNoFailed, (state, action) => ({ ...state, status:undefined })),


  on(fromActions.onResendOtpSweepayAccount, (state) => ({ ...state })),
  on(fromActions.onResendOtpSweepayAccountSuccess, (state, action) => ({ ...state, resendOtpSweepayAccount: action.payload, status: undefined })),
  on(fromActions.onResendOtpSweepayAccountFailed, (state, action) => ({ ...state, status:undefined })),


  on(fromActions.onValidateSweepayOtp, (state) => ({ ...state , validateOtp: undefined})),
  on(fromActions.onValidateSweepayOtpSuccess, (state, action) => ({ ...state, validateOtp: action.payload, status: undefined })),
  on(fromActions.onValidateSweepayOtpFailed, (state, action) => ({ ...state, status:undefined })),


);

export function reducer(state: FeatureState | undefined, action: Action) {
  return userReducer(state, action);
}
