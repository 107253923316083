import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Store, select } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';


const debug = Debug('dgoods:shop:PaymentCanceldPage');
@Component({
  selector: 'app-page-paymentcanceld',
  templateUrl: './paymentcanceld.page.view.html',
  styleUrls: ['./paymentcanceld.page.view.scss']
})
export class PaymentCanceldPage extends BasePageComponent implements OnInit {

  constructor(
    private store: Store<fromApp.AppState>,
    injector: Injector
  ) {
    super(injector);
    debug('paymentcanceld Page');
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      this.switchToAppAndCloseWindow();
    }, 1000);
  }

  switchToAppAndCloseWindow() {
    const protocol = localStorage.getItem('protocol');
    const device = localStorage.getItem('device');
    debug(protocol);
    debug(device);
    if (!protocol || !device) {
      debug('state is not defined');
      debug('can not switch to app');
      return;
    }
    if (device === 'ios') {
      const redirectURL = `${protocol}://ch.twint.wallet`;
      debug(redirectURL);
      window.location.href = redirectURL;
    } else if (device === 'android') {
      // tslint:disable-next-line:max-line-length
      // intent://ch.twint.wallet#Intent;package=ch.twint.payment.configurable;scheme=twint;end
      const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
      debug(`android browser-app-switch-redirect ${redirectURL}`);
      debug(redirectURL);
      window.location.href = redirectURL;
    }
  }
}

