import { Pipe, PipeTransform } from '@angular/core';
import * as fromReducers from './../reducers/localization.reducer';
import { LocalizationModel } from './../models/localization.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizePipe');

@Pipe({ name: 'localize' })
export class LocalizePipe implements PipeTransform {
  private debugModeIsActive = false;
  constructor(private store: Store<fromReducers.AppState>) {
    debug('LocalizePipe constructed');
    this.store.pipe(select(fromReducers.selectIsDebugModeActivated)).subscribe((activ) => {
      this.debugModeIsActive = activ;
    });
  }

  transform(key: string): Observable<string> {
    return this.store.pipe(
      select(fromReducers.selectEntitiesByID, { id: key }),
      map((localization: LocalizationModel) => {

        // if (this.debugModeIsActive) {
        //   return key;
        // }

        if (localization) {
          return localization.text;
        } else {
          if(this.debugModeIsActive)
          {
            return  key;
          }else{

          return "";
          }
        }
      }),
      filter((text: string) => text !== undefined)
    );
  }
}
