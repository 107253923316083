import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TWINT';


  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    let browserLanguage = navigator.language.substring(0, 2);
    const availableLanguages = environment.availableLanguages.map( (lang) => lang.substring(0, 2));
    if(!availableLanguages.includes(browserLanguage)) {
      browserLanguage = 'en';
    }
    this.document.documentElement.lang = browserLanguage;
    this.updateIndexHtmlFile();

  }

  updateIndexHtmlFile(){
    try {
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://plausible.io/js/script.js';
      script['data-domain'] = environment.dataDomain;
      this.document.head.appendChild(script);

    } catch (ex) {
      console.error('Error appending plausible' + ex);
    }
  }

}
