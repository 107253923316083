import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService } from '../shared/services/localSrorage.service';
import * as fromActions from './app.state.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { IAppStateProtocolModel } from '../shared/models/appStateProtocol.model';
const debug = Debug('modeso:dgoods-lib-cart-fe:CartEffects');


@Injectable()
export class AppEffects {

    constructor(private actions$: Actions, private service: LocalStorageService) { }

    /**
     * Effect of post cart.
     */
    updateStoreProtocol$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.updateStoreProtocol.type),
            mergeMap(
                payload => {
                    return this.service.save('protocol', payload)
                        .pipe(
                            retry(1),
                            map(
                                (response: IAppStateProtocolModel) => (fromActions.updateStoreProtocolSuccess({ payload: response })),
                            ),
                            catchError((error) => of(fromActions.updateStoreProtocolFail()))
                        );
                }
            )
        )
    );

    getStoreProtocol$ = createEffect(
        () => this.actions$.pipe(
            ofType(fromActions.getProtocol.type),
            mergeMap(
                payload => {
                    return this.service.get('protocol')
                        .pipe(
                            retry(1),
                            map(
                                (response: any) => (fromActions.getProtocolSuccess({ payload: response.payload })),
                            ),
                            catchError((error) => of(fromActions.getProtocolFail()))
                        );
                }
            )
        )
    );


    handleOnLoadAllCartErrors(error) {
        debug(error);
        return error;
    }
}
