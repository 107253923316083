<div class="langing-buy-container">

  <app-hamburger-menu-component [backBtnRoute]="backBtnRoute" [menuItems]="menuItems" [menuItemsContentPages]="menuItemsContentPages" [menuState]="menuState"></app-hamburger-menu-component>

  <app-news-ticker #newsTicker></app-news-ticker>

  <app-spotlight-list-component *ngIf="spotlights.length !== 0" [spotlights]="spotlights" [products]="providers" [contentPages]="menuItemsContentPages" appPreventVerticalScroll></app-spotlight-list-component>

  <app-categorie-filter #filterElement
  class="category-filter" [allText]="'All'" [activeFilter]="activeFilter$"  [language]="language" [categories]="categories$ | async" (categoryClicked)="handleCategoryClick($event)"></app-categorie-filter>

  <modeso-digital-product-list [delegate]="this" [products]="providers"></modeso-digital-product-list>

</div>


<dgoods-dialogbox #dialogBoxShopOverlay [hidden]="true" [dialogBoxData]="currentDialogBox" [delegate]="this"
  [descriptionFromLocalization]="true">
</dgoods-dialogbox>

<dgoods-dialogbox #dialogBoxProductLineOverlay [hidden]="true" [dialogBoxData]="currentDialogBox" [delegate]="this"
  [descriptionFromLocalization]="true">
</dgoods-dialogbox>
