<mat-accordion class="example-headers-align" multi [togglePosition]="'before'"  [@.disabled]="true">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="mat-expandable-title">
        {{expandableObject?.title | localize | async }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p [innerHTML]="decodedExpandableText"></p>
  </mat-expansion-panel>
</mat-accordion>

