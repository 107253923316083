<div  class="phone-number-overlay">

  <app-hamburger-menu-component [backBtnRoute]="sweepayPhoneNavigation.redirectFrom" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>

  <form [formGroup]="phoneForm" >

    <div class="page-padding">

      <p class="header-style">{{"dgoods_phone_number_title" | localize | async}}</p>

      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label>{{"dgoods_phone_number" | localize | async}}</mat-label>
          <input matInput formControlName="phone" (blur)="checkPhoneNumber()" placeholder="+41 79 123 45 67" type="tel"/>
        </mat-form-field>

        <div class="validation-style" *ngIf="(phone.invalid && (phone.dirty || phone.touched))|| !isMsgDisappear">
            {{ "dgoods_shop_phone_validation" | localize | async }}
        </div>

      </div>


      <div class="text-style-1" style="margin-bottom:16px">
        {{"dgoods_shop_phone_info" | localize | async}}
      </div>

      <div class="text-style-2">
        {{"dgoods_shop_phone_description" | localize | async}}
      </div>

    </div>
  </form>

   <div class="fixed-padding"></div>

    <app-checkout-flyout-component *ngIf="navigationData"
          [twintTC]="navigationData.twintTC"
          [product]="navigationData.product"
          [price]="navigationData.amount"
          [disablePurchaseBtn]="disablePurchaseBtn"
          [couponState]="navigationData.couponState"
          [currency]="navigationData.currency"
          [redirectFrom]="redirectFrom"
          (userPurchasedProduct)="onButtonClicked()">
    </app-checkout-flyout-component>

</div>







