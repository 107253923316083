import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModesoLocalizationConfigService, ModesoLocalizationConfig } from './../localization.config';
import { LocalizationModel } from '../models/localization.model';

export interface LocalizationResponse {
  localizations: Array<LocalizationModel>;
}

export interface LocalizationDetailResponse {
  localization: LocalizationModel;
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private server = '';

  private microserviceName = 'localization';
  private readonly route = '/';
  private readonly detailRoute = ':id';

  constructor(
    private http: HttpClient,
    @Inject(ModesoLocalizationConfigService) private config: ModesoLocalizationConfig
  ) {
    this.server = config.apiGateway;
  }

  getLocalizations() {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.get<LocalizationResponse>(url);
  }

  getLocalizationByKey(key: string) {
    const keyId = this.detailRoute.replace(':id', key);
    const url = `${this.server}${this.microserviceName}${this.route}${keyId}`;
    return this.http.get<LocalizationDetailResponse>(url);
  }

  getLocalizationsForAllLanguages() {
    const url = `${this.server}${this.microserviceName}${this.route}all/languages`;
    return this.http.get<{ translations: LocalizationModel[] }>(url);
  }

  updateLocalization(key: string, updatedTranslations: any) {
    const url = `${this.server}${this.microserviceName}${this.route}${key}`;
    return this.http.put<any>(url, updatedTranslations);
  }
}
