import { Injector, ChangeDetectorRef, Renderer2, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Debug from 'debug';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { Subscription } from 'rxjs';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { take } from 'rxjs/operators';
import { HtmlEnitityDecoder } from '../../shared/classes/HtmlDecoder';


const debug = Debug('dgoods:project:TermsOfProviderPage');
@Component({
  selector: 'app-page-terms-of-provider',
  templateUrl: './terms-of-provider.page.view.html',
  styleUrls: ['./terms-of-provider.page.view.scss']
})

export class TermsOfProviderPage implements IDGoodsButtonDelegate , OnInit {

  enforceTC;
  subscription: Subscription;
  termAndConditions;
  redirectFrom;
  decodeTermsAndCondition;

  constructor(injector: Injector,
              private router: Router,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private renderer2: Renderer2,
              private productProvider: ProductStoreProvider,
              private appStateProvider: AppStateDispatcher
            ) {
              this.enforceTC = this.route.snapshot.paramMap.get('enforce');
              this.enforceTC= (this.enforceTC === 'false' || this.enforceTC === 'null' || this.enforceTC === 'undefined') ? false: true;
              this.redirectFrom = this.route.snapshot.paramMap.get('redirectFrom');

            }

  ngOnInit() {
    this.getTermsOfProvider();

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 200);

    this.appStateProvider.getNavigationData().pipe(take(1)).subscribe((navigationData)=>{
      if(navigationData.refreshed){
        this.router.navigate([this.redirectFrom])
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  getTermsOfProvider() {
    this.subscription = this.productProvider.getTermsAndConditions$(this.getProductId()).subscribe(termAndConditionProvider => {
      this.termAndConditions = termAndConditionProvider;
      this.decodeTermsAndCondition = new HtmlEnitityDecoder().decodeHTML(this.termAndConditions);
    });
  }

  /**
   * IDGoodsTextButtonDelegate
   */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    if (this.enforceTC) {
      if (sender.state === 'notAction') {
        this.appStateProvider.saveNavigationData({productTermsAndConditions:false , refreshed:false})
      } else {
        this.appStateProvider.saveNavigationData({productTermsAndConditions:true , refreshed:false})
      }
    } else {
      this.appStateProvider.saveNavigationData({productTermsAndConditions: true , refreshed:false})
    }
    this.router.navigate([this.redirectFrom]);

  }

  getProductId(): string {
    return this.route.snapshot.paramMap.get('productLineId');
  }


}
