/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';


@Component({
  selector: 'app-user-email-display-component',
  templateUrl: './user-email-display.component.html',
  styleUrls: ['./user-email-display.component.scss'],
})

export class UserEmailDisplayComponent  {

  @Input() userEmail:string;
  @Input() productId:string;

  constructor(private router:Router, private appStateProvider:AppStateDispatcher) {}

  redirectToMyAccount(){
    this.router.navigate([`de-ch/account/edit/`, { productLineId: this.productId}]);
    // to keep the data from vanishing on redirecting to my account then going back
    this.appStateProvider.saveNavigationData({refreshed: false})
  }
}
