<div class="dgoods-navigation-container">

  <div class="icons-container">

    <div *ngIf="menuState === menuItemStates.CHILD" class="back-container" (click)="onBackClick()" >
      <img class="back-icon" src="assets/icon-shape.svg" />
      <span *ngIf="itemTitle" class="item-title">{{itemTitle | localize |async }}</span>
      <span *ngIf="!itemTitle && itemConstTitle" class="item-title">{{itemConstTitle}}</span>
    </div>

    <div style="display: inline-block;">
      <span *ngIf="menuState === menuItemStates.COLLAPSED" class="shop-title">{{'tdv_shop_title_digital_vouchers' | localize | async }}</span>
    </div>


    <div *ngIf="menuState !== menuItemStates.CHILD" [matMenuTriggerFor]="menu" (menuClosed)="menuClosed()" (menuOpened)="menuOpened()" class="btn" >
      <div class="icon-wrapper" [@hamburguerX]="menuState === menuItemStates.COLLAPSED ? 'hamburguer' : 'topX'"></div>
      <div class="icon-wrapper" [@hamburguerX]="menuState === menuItemStates.COLLAPSED ? 'hamburguer' : 'hide'"></div>
      <div class="icon-wrapper" [@hamburguerX]="menuState === menuItemStates.COLLAPSED ? 'hamburguer' : 'bottomX'"></div>
    </div>
  </div>

</div>

<mat-menu #menu="matMenu"  class="menu-screen" >

  <div class="menu-container">
    <div style="padding-bottom: 16px;">
      <ng-container *ngFor="let menuItem of menuItems" >
        <div style="margin-bottom: 16px;">
          <button mat-menu-item>
            <a [routerLink]="[menuItem.routeLink]" target="{{menuItem.target}}"  class="overview-list row" style="color: black;">
              <img src="assets/{{menuItem.icons}}" class="img-dimension" />
              <span class="column2 text-style" >
                {{menuItem.title | localize | async }}
              </span>
            </a>
          </button>
      </div>
    </ng-container>

    <!-- We must use 2 for loops because the first one uses localized texts while the 2nd uses plain text -->
    <ng-container *ngFor="let menuItem of menuItemsContentPages" >
      <div style="margin-bottom: 16px;">
        <button mat-menu-item>
          <a [routerLink]="[menuItem.routeLink]" target="{{menuItem.target}}"  class="overview-list row" style="color: black;">
            <img src="assets/svgs/light/{{menuItem.icons}}" class="img-dimension" />
            <span class="column2 text-style" >
              {{menuItem.title}}
            </span>
          </a>
        </button>
    </div>
  </ng-container>

    </div>

    </div>

</mat-menu>


