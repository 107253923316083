import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IDGoodsTableDataSource, DGoodsTableSection, IDGoodsTableSection, MonthAndYear } from './dgoods-table.datasource';
import moment from 'moment';
import Debug from 'debug';
import { IDGoodsTableCellDelegate } from './dgoods-table-cell.component2';

const debug = Debug('dgoods:shared:DGoodsTableCellComponent');

export interface IDGoodsTableDelegate {
  onRowClick(data: IDGoodsTableDataSource);
}

@Component({
  selector: 'dgoods-table2',
  templateUrl: './dgoods-table.component2.html',
  styleUrls: ['./dgoods-table.component2.scss']
})
export class DGoodsTableComponent2 implements IDGoodsTableCellDelegate, OnInit {

  @Input() delegate?: IDGoodsTableDelegate;
  @Input() datasource: IDGoodsTableDataSource[];
  @Output() clickedDataTable = new EventEmitter<IDGoodsTableDataSource>();

  public sections = new Array<IDGoodsTableSection>();

  constructor() { }

  ngOnInit(): void {
    this.createSections();
  }

  private createSections() {
    this.sections.splice(0, this.sections.length);

    const date = new Set<MonthAndYear>();
    const sortedDataSource = this.datasource.sort((a, b) => a.compare(a, b));
    sortedDataSource.reverse();
    sortedDataSource.forEach((datasource: IDGoodsTableDataSource) =>
      date.add({
        month: moment(datasource.date).format('MMMM'),
        year: moment(datasource.date).format('YYYY')
      }));

    let index = 0;
    date.forEach((monthAndYear: MonthAndYear) => {
      const datasource = sortedDataSource.filter((ds) =>
        moment(ds.date).format('MMMM') === monthAndYear.month && moment(ds.date).format('YYYY') === monthAndYear.year);
      const section: DGoodsTableSection = new DGoodsTableSection(monthAndYear.month + ' ' + monthAndYear.year, index, datasource);
      this.sections.push(section);
      index += 1;
    });
    this.sections = this.sections.filter((v, i, a) => a.findIndex(t => (t.title === v.title)) === i);
  }

  onTableCellClick(data: IDGoodsTableDataSource): void {
    if (this.delegate) {
      this.delegate.onRowClick(data);
    } else {
      debug('onRowClick event not fired. No delegate defined for the component.');
    }
  }

  onClickedData(data: IDGoodsTableDataSource) {
    this.clickedDataTable.emit(data);
  }
}

