import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscriptions = new Array<Subscription>();

  constructor(private sessionProvider: DgoodsSessionStoreProvider) { }

  ngOnInit(): void {
  }

  logout() {
    this.sessionProvider.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

}
