import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from '../actions/dgoods.session.actions';
export const featureKey = 'dgoodsSessionMicroservice';
import Debug from 'debug';
import { IUserResponse } from '../services/dgoods.session.service';
const debug = Debug('dgoods:session:Reducer');
// State Declarations - START

export interface FeatureState {
  isValidUrl: boolean;
  url: string;
  user: IUserResponse;
  checkInDate: Date;
  isP2P: boolean;
  productLineId: string;
  terminalId: string;
}

export interface AppState {
  dgoodsSessionMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) =>
  state.dgoodsSessionMicroservice;
export const selectFeatureSession = createSelector(
  selectFeature,
  (state: FeatureState) => state,
);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = {
  isValidUrl: false,
  url: null,
  user: null,
  checkInDate: null,
  isP2P: false,
  productLineId: null,
  terminalId: null,
};

const sessionReducer = createReducer(
  initialState,
  on(fromActions.onCheckinSuccess, (state, action) => {
    const url = action.payload.checkInUrl;
    return { ...state, url };
  }),
  on(fromActions.onCheckinFail, (state) => {
    return { ...initialState };
  }),
  on(fromActions.onGetNewAccesToken, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewTokenSuccess, (state) => {
    return { ...state };
  }),
  on(fromActions.onGetNewTokenFailed, (state) => {
    return { ...state };
  }),
  on(fromActions.onLogoutSuccess, (state) => {
    return { ...initialState };
  }),
  on(fromActions.onLogoutFail, (state) => {
    return { ...state };
  }),
  on(fromActions.onStartSuccess, (state, action) => {
    const user = action.payload.user;
    const checkInDate = action.payload.checkInDate;
    const isP2P = action.payload.isP2P;
    const productLineId = action.payload.productLineId;
    const terminalId = action.payload.terminalId;
    return {
      ...state,
      isValidUrl: true,
      user,
      checkInDate,
      isP2P,
      productLineId,
      terminalId,
    };
  }),
  on(fromActions.onStartFail, (state) => {
    return { ...state, isValidUrl: false };
  }),
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return sessionReducer(state, action);
}
// Reducer Declarations - END
