import { Component, Input } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsOrderConfirmationOverlayComponent');

@Component({
  selector: 'order-confirmation-overlay2',
  templateUrl: './order-confirmation-overlay.component2.html',
  styleUrls: ['./order-confirmation-overlay.component2.scss']
})
export class DGoodsOrderConfirmationOverlayComponent2 {
    @Input() hidden?: boolean;
}
