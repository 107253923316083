import {
  IDGoodsButtonDelegate,DGoodsOverlayComponent,
  DGoodsDialogBoxComponent, IDGoodsDialogBoxDelegate, DGoodsButtonComponent
} from '@modeso/twint-lib-core-fe';
import { UserProvider } from "@modeso/dgoods-lib-user-fe";
import { Injector, OnInit, ViewChild, Component } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';

const debug = Debug('dgoods:project:AccountPageController');

@Component({
  selector: 'app-account-page',
  templateUrl: './account.page.view.html',
  styleUrls: ['./account.page.view.scss']
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AccountPage extends BasePageComponent implements
  OnInit,
  IDGoodsButtonDelegate, IDGoodsDialogBoxDelegate {

  state = 'normal';
  existingUserEmail: string = '';
  saveBtnDisabled = true;
  message: string;
  newEmail: string;
  menuItems = []; 
  backBtnRoute = '../../de-ch/landing-buy';
  menuState = MenuItemStates.CHILD;
  redirectToPageParam;

  public dialogBoxObj = {
    title: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_title_duplicate_email',
    description: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_message_duplicate_email',
  };
  
  @ViewChild('errorOverlay', { static: false }) errorOverlay: DGoodsOverlayComponent;
  @ViewChild('dialogBoxOverlay', { static: false }) dialogBoxOverlay: DGoodsDialogBoxComponent;


  constructor(
    private injector: Injector,
    private userProvider: UserProvider,
    private router: Router,
    private route: ActivatedRoute,
    private appStateProvider: AppStateDispatcher
    ) {
    super(injector);
    let productLineId = this.route.snapshot.paramMap.get('productLineId');

    if(productLineId){
      this.backBtnRoute = `de-ch/product-buy/${productLineId}`;
    }

  }

  ngOnInit() {
    super.ngOnInit();
    this.getEmailData();

    this.appStateProvider.getNavigationData().pipe(
      take(1)
    ).subscribe((navigationData)=>{

      // to go back to product details if page is refreshed
      if(navigationData.refreshed && this.redirectToPageParam){
        this.router.navigate([this.backBtnRoute])
      }
    })
  }

  onOverlayButtonClicked(): void {
    this.errorOverlay.hidden = true;
  }

  onDialogBoxButtonClicked() {
    this.dialogBoxOverlay.hidden = true;
  }


  onButtonClicked(sender: DGoodsButtonComponent) {
    debug('Button clicked');
 
    const body = {
      email: this.newEmail
    };

    let index = 0;
    debug("Emails");
    debug(this.newEmail);
    debug(this.existingUserEmail);
    
    if (this.newEmail !== this.existingUserEmail) {
      this.userProvider.updateUser$(body).pipe(take(2))
        .subscribe((userData) => {
          index++;
          if (index === 2) {
            if (userData.email){
              if (userData.status && userData.status.toString() === '405') {
                this.dialogBoxOverlay.hidden = false;
              }else{
                localStorage.setItem('email', userData.email);
                this.router.navigate([this.backBtnRoute]);
              }
            } else {
              if (userData.status.toString() === '405') {
                this.dialogBoxOverlay.hidden = false;
              } else {
                this.message = 'error_in_updating_email';
                this.errorOverlay.hidden = false;
              }
            }
          }
        });
    }else{
      this.router.navigate([this.backBtnRoute])
    }
  }

  getEmailData(){
    this.existingUserEmail = localStorage.getItem('email');
  }

  isFormValid(event){
    this.saveBtnDisabled = !event.formValidity;
    this.newEmail = event.newUserEmail;
  }

}
