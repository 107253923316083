<dgoods-order-confirmation-overlay class="terms-wrapper" [hidden]="!showOverlay">
  <div *ngIf="getRedemtionInfo()" class="terms">
    <h3>
      {{getRedemtionInfoText()}}
    </h3>
    <p>
      {{getRedemtionInfo()}}
    </p>
  </div>
  <div *ngIf="getAGB()" class="terms">
    <h3>
      {{getAGBText()}}
    </h3>
    <p [innerHTML]="getAGB()">

    </p>
  </div>
  <div class="button-container">
    <div class="sticky">
      <dgoods-button [delegate]="this">
        {{ "dgoods_shared_termsofservicecompontent_conclude" | injectedText | async }}
      </dgoods-button>
    </div>
  </div>
</dgoods-order-confirmation-overlay>
<div class="dgoods-order-confirmation-container" [hidden]="showOverlay">

  <div class="image-container">
    <div class="image-wrapper"><img src={{productImage}} /></div>
  </div>
  <div>

    <div class="code-container">
      <dgoods-order-confirmation-details2 injectedTitle="dgoods_shared_orderconfirmationcomponent_code"
        *ngIf="codeNumber" class="bordered">
        <ng-content></ng-content>
      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 injectedTitle="dgoods_shared_orderconfirmationcomponent_code"
        *ngIf="getInProgress()" class="bordered">
        <dgoods-dots-loading-indicator></dgoods-dots-loading-indicator>
        <div class="cell">
          <span class="cell-text-inprogress">
            <div>
              <div>
                {{ "dgoods_shared_orderconfirmationcomponent_inprogress" | injectedText | async }}
              </div>
            </div>
          </span>

        </div>

      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 title="DEBUG_STATUS" *ngIf="getDebugStatus()" class="bordered">
        <div class="cell">
          <span class="cell-text-inprogress">
            <div>
              <div>
                {{ getDebugStatus() }}
              </div>
            </div>
          </span>

        </div>

      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 [title]="getCardText()" *ngIf="!getInProgress() && getCard()" class="bordered">
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getCard())">
                {{getCard()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'CARD'">
            <span (click)="justFollowLink(getCard())" class="cell-text-info" *ngIf="getLink()">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getCard())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 [title]="getCodeText()" *ngIf="!getInProgress() && getCode()" class="bordered">
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getCode())">
                {{getCode()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'CODE'">
            <span (click)="justFollowLink(getCode())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getCode())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 [title]="getSerialText()" *ngIf="!getInProgress() && getSerial()"
        class="bordered">
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getSerial())">
                {{getSerial()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'SERIAL'">
            <span (click)="justFollowLink(getSerial())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getSerial())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 [title]="getPinText()" *ngIf="!getInProgress() && getPin()" class="bordered">
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getPin())">
                {{getPin()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'PIN'">
            <span (click)="justFollowLink(getPin())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getPin())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </dgoods-order-confirmation-details2>
      <dgoods-order-confirmation-details2 [title]="getBarcodeText()" *ngIf="!getInProgress() && getBarcode()"
        class="bordered">

        <div class="cell">

          <span #barcode class="barcode-image">
            <ngx-barcode
            [bc-width]="1"
            [bc-height]="50"
            [bc-value]="getBarcode()"
            [bc-display-value]="true"></ngx-barcode>
          </span>

          <span (click)="showScannableCode()" class="cell-text-info">
            {{ "dgoods_shared_orderconfirmationcomponent_scannablecode" | injectedText | async }}
          </span>

        </div>
      </dgoods-order-confirmation-details2>
    </div>
  </div>
  <div>
    <div class="dgoods-order-confrimation-details-container" *ngIf="price">
      <div class="title">
        {{"dgoods_shared_orderconfirmationcomponent_details" | injectedText | async}}
      </div>
      <div class="details">
        <div *ngIf="!discounts || couponRedeemStateEnum.confirmed !== discounts.redeemState" class="price-container ">
          <span class="price">{{currency}} {{price | number : '1.2-2' }}</span>
        </div>

        <div *ngIf="discounts && couponRedeemStateEnum.confirmed === discounts.redeemState" class="apply-coupon-container">

          <div class="col1">
            <div class="text1">
              <span>1x {{productName}}</span>
            </div>
            <div class="text2">
              <span class="extra-margin">{{'dgoods_shop_add_code_placeholder' | injectedText | async}}</span>
              <span *ngIf="discounts.discountPercentage">(-{{discounts.discountPercentage.toFixed(0)}}%)</span></div>            <div class="text3">
              <span>{{'dgoods_shop_code_total_amount' | injectedText | async}}</span>
            </div>
          </div>

          <div class="col2">
            <div class="text1">{{currency}} {{price | number : '1.2-2' }}</div>
            <div class="text2">-{{currency}} {{ discounts.discountAmount | number : '1.2-2' }}</div>
            <div class="text3">{{currency}} {{ discounts.totalAmount | number : '1.2-2' }}</div>
          </div>

        </div>
        <span class="title-date-time">{{productName}},
          <span class="date-time">{{date | date:' dd.MM.yyyy, HH:mm'}}</span>
        </span>
        <div (click)="openInstructions()" class="instraction">
          {{ "dgoods_shared_orderconfirmationcompontent_instructions" | injectedText | async }}
        </div>
        <div class="email" *ngIf="email">
          <span class="email-text">{{ "dgoods_shared_orderconfirmationcompontent_email" | injectedText | async }}</span>
          <span> {{email}}</span>
        </div>
      </div>

    </div>

  </div>


  <dgoods-order-confirmation-details2  *ngIf="!getInProgress() && getExtraText()" class="bordered">
    <div class="extra-text-margin">
        <div class="extra-text-fonts">
          {{getExtraText()}}
        </div>
    </div>
  </dgoods-order-confirmation-details2>



</div>
<dgoods-overlay-new [hidden]="!copyOverlay" [error]="false" [progress]="false" [html]="true" [copyOverlay]="true">
  {{ "dgoods_shared_orderconfirmationcompontent_copyconfirmationmsg" | injectedText | async }}
</dgoods-overlay-new>
<dgoods-overlay-new *ngIf="!getInProgress() && getBarcode()"
[hidden]="hideScannableCode"
[error]="false"
[progress]="false"
[html]="false"
[scannableCode]="true"
[delegate]="this"
backgroundColor="white"
class="scancodeOverlay">

  <span #barcode class="barcode-image show">
    <ngx-barcode
    [bc-width]="1"
    [bc-height]="50"
    [bc-value]="getBarcode()"
    [bc-display-value]="true"></ngx-barcode>
  </span>

</dgoods-overlay-new>
