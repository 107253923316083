/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input , Output , EventEmitter} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DenominatedPriceDialog } from '../../Dialogs/DenominatedPriceDialog/denominated-price.dialog';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'price-component',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent {

  displayedPrice;
  @Input() denominatedPrice;
  @Input() price;
  @Output() denominatedPriceSelected = new EventEmitter<any>();
  

  constructor(public dialog: MatDialog) { }

  ngOnInit(){

    if(!this.price.isDenomination){
      this.displayedPrice = Number.isInteger(this.price.minimumPrice) ? this.price.minimumPrice : this.price.minimumPrice.toFixed(2);
    }
  }

  openPopup(price){

    if(price.isDenomination){
      
    const dialogRef = this.dialog.open(DenominatedPriceDialog, {
      data: {
          price,
          denominatedPrice: this.denominatedPrice
      },
      width: '100%',
    });

    // After dialog is opened
    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.focusInput(); // Call the method from dialog component
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.denominatedPrice = result;
        this.price.selected = true;
        // till we confirm should we accept numbers ?
        // this.displayedPrice = Number.isInteger(this.denominatedPrice) ? this.denominatedPrice : this.denominatedPrice.toFixed(2);
        this.denominatedPriceSelected.emit({price,denominatedPrice:this.denominatedPrice});
      }
    });
    }
  }
}
