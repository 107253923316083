import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PurchasingHistoryPage } from './pages/PurchaseHistory/purchasing-history.page.view';
import { OneTimeUrlStartPage } from './pages/OneTimeUrlStart/onetimeurlStart.page';
import { OrderConfirmationPage } from './pages/OrderConfirmation/order-confirmation.page.view';
import { ErrorPage } from './pages/ErrorPage/error.page.view';
import { PaymentCanceldPage } from './pages/PaymentCanceldPage/paymentcanceld.page.view';
import { NavigationAuthGuard } from './shared/services/guards/NavigationAuthGuard.service';
import { LoginPage } from './pages/login/login.page';
import { TermsOfTwintPage } from './pages/TermsOfTwint/terms-of-twint.page.view';
import { TermsOfProviderPage } from './pages/TermsOfProvider/terms-of-provider.controller';
import { DataProtectionOfTwintPage } from './pages/DataProtectionOfTwint/data-protection-of-twint.page.view';
import { FAQPage } from './pages/FAQ/faq.page';
import { MaintenancePage } from './pages/Maintainance/maintenance.page';
import { MaintenenceGuard } from './shared/services/guards/MaintenenceGuard.service';
import { NoMaintenenceGuard } from './shared/services/guards/NoMaintenenceGuard.service';
import { PaymentRefundErrorPage } from './pages/paymentRefundErrorPage/payment-refund-error-page.view';
import { ProductDetailsPage } from './pages/ProductDetails/product-details.page.controller';
import { AccountPage } from './pages/Account/account.page.controller';
import { EmailRegisterationPage } from './pages/RegisterEmailInCheckoutFlow/email-registeration.page.controller';
import { SweepayPhoneNumberPage } from './pages/Sweepay/PhoneNumber/sweepay-phone-number.view';
import { SweepayOTPComponent } from './pages/Sweepay/OTP/sweepay-otp.view';
import { LandingBuyPageController } from './pages/Landing/landing-buy.page.controller';
import { ContentPagesPage } from './pages/contentpage/contentpage.page';

const routes: Routes = [
  // {
  //   path: ':language/mockedchecking',
  //   component: FakeCheckinPage,
  // },
  {
    path: ':language/login',
    component: LoginPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/landing-buy',
    component: LandingBuyPageController,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/maintenance',
    component: MaintenancePage,
    canActivate: [NoMaintenenceGuard]
  },
  {
    path: ':language/register-email/:productLineId',
    component:   EmailRegisterationPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/phone-registeration/:productLineId',
    component: SweepayPhoneNumberPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/otp-verification/:productLineId',
    component: SweepayOTPComponent,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/history',
    component: PurchasingHistoryPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/terms-and-conditions/:productLineId',
    component: TermsOfProviderPage,
    canActivate: [MaintenenceGuard],
    data: {
      scrollToTop: true
    }
  },
  {
    path: ':language/terms-of-twint/:productLineId',
    component: TermsOfTwintPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/data-protection-of-twint/:productLineId',
    component: DataProtectionOfTwintPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/error',
    component: ErrorPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/paymentcanceld',
    component: PaymentCanceldPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/paymentrefund',
    component: PaymentRefundErrorPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/:urltoken',
    component: OneTimeUrlStartPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/product-buy/:productLineId',
    component: ProductDetailsPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/order-confirmation/:receiptUrl',
    component: OrderConfirmationPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/account/edit',
    component: AccountPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/account/faq',
    component: FAQPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/contentpage/:id',
    component: ContentPagesPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: '',
    redirectTo: '/de-ch',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: LoginPage,
    canActivate: [MaintenenceGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
