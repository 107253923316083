<div class="faq-overlay">
    <app-hamburger-menu-component itemTitle="dgoods_faq_title" img [backBtnRoute]="backBtnRoute" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>
    <div class="page-padding">
        <div *ngFor="let key of questionsLocalizationKeys">
            <div *ngIf="(key.titleKey| localize | async ) && (key.answerKey| localize | async )">
                <input type="checkbox" id="{{ 'question' + key.index }}" class="toggle">
                <label for="{{ 'question' + key.index }}" class="question">
                    {{ key.titleKey | localize | async}}
                </label>
                <div class="answer">
                    <p>{{ key.answerKey| localize | async}}</p>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>
