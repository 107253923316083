import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as productActions from './../actions/products.actions';
import { CategoryDto, INewstickerResponse, ISpotLight, ProductLineDto } from '@modeso/types__dgoods-products';

export const productsFeatureKey = 'modesoProductsMicroservice';
import Debug from 'debug';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
import { combineLatest, map } from 'rxjs';
const debug = Debug('dgoods:project:modesoProductsMicroservice');

// State Declarations - START

export interface FeatureState {
  //TODO: Rename to productLines
  products: Array<ProductLineDto>;
  categories: Array<CategoryDto>;
  activeFilter:  CategoryDto | string;
  filteredProducts: Array<ProductLineDto>;
  newsticker: INewstickerResponse | undefined;
  menuItems: Array<any>;
  contentPagesList: IContentPageResponse[];
  spotlights: ISpotLight[];
  contentBox: IContentBoxResponse;
  userHasSpotlights: boolean;
}

export interface AppState {
  modesoProductsMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoProductsMicroservice;
export const selectFeatureProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.products
);

export const selectFilteredProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.filteredProducts
);

export const selectActiveFilter = createSelector(
  selectFeature,
  (state: FeatureState) => state.activeFilter
);

export const selectFeaturCategories = createSelector(
  selectFeature,
  (state: FeatureState) => state.categories
);
export const selectFeatureContentPagesList = createSelector(selectFeature, (state: FeatureState) => state.contentPagesList);
export const selectFeatureContentBox = createSelector(selectFeature, (state: FeatureState) => state.contentBox);


/**
 * selector to get product by id from products array of the store
 */
export const selectProductById = createSelector(
  selectFeature,
  (entities, props) => {
    return entities.products.find(product => product.productLineId === props.id);
  }
);

export const selectFeatureNewsTicker = createSelector(
  selectFeature,
  (state: FeatureState) => state.newsticker,
);

export const selectMenuItems = createSelector(
  selectFeature,
  (state: FeatureState) => state.menuItems,
);

export const selectSpotlights = createSelector(
  selectFeature,
  (state: FeatureState) => state.spotlights 
);

export const selectUserHasSpotlights = createSelector(
  selectFeature,
  (state: FeatureState) => state.userHasSpotlights
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  products: new Array<ProductLineDto>(),
  categories: new Array<CategoryDto>(),
  activeFilter: "ALL",
  filteredProducts: new Array<ProductLineDto>(),
  menuItems : [
    {
      title: 'tdv_menu_credits',
      icons: 'tdv_menu_credits.svg',
    },
    {
      title: 'tdv_menu_email',
      icons: 'tdv_menu_email.svg',
    },
    {
      title: 'tdv_menu_faq',
      icons: 'tdv_menu_faq.svg',
    },
    {
      title: 'tdv_menu_tAndC',
      icons: 'tdv_menu_tAndC.svg',
    }
  ],
  newsticker: undefined,
  contentPagesList: [],
  spotlights: [],
  userHasSpotlights: undefined,
  contentBox: undefined,
};


const productsReducer = createReducer(
  initialState,
  on(productActions.getAllProducts, state => ({ ...state })),
  on(productActions.onAllProductsLoadedSuccessfully, (state, action) => {

    const products = action.payload;
    const categories: CategoryDto[] = []

    products.forEach((element) => {
      element.categories.forEach((category) => {
        const existingCategory = categories.find((c) => c._id === category._id);
        if (!existingCategory) {
          categories.push(category);
        }
      });
    });

    // Sort the categories array by orderNumber
    categories.sort((a, b) => a.orderNumber - b.orderNumber);
    return {
      ...state,
      products,
      filteredProducts: filterProducts(state.activeFilter, products),
      categories,
      error: undefined,
  };
  }) ,
  on(productActions.onAllProductsLoadingFailed, (state) => ({ ...state })),

  on(productActions.getActiveFilter, (state) => ({ ...state })),
  on(productActions.setActiveFilter, (state, action) => ({ ...state , activeFilter: action.payload, filteredProducts: filterProducts(action.payload, state.products)})),

  on(productActions.getNewsTicker, (state) => ({ ...state , newsticker: undefined })),
  on(productActions.onGetNewsTickerSucceeded, (state, action) => ({ ...state , newsticker: action.payload})),
  on(productActions.onGetNewsTickerFailed, (state, action) => {
    return { ...state, newsticker: undefined,  error: action.payload };
  }),
  on(productActions.getAllContentPages, (state) => ({ ...state })),
  on(productActions.getAllContentPagesSuccess, (state, action) => ({
    ...state,
    contentPagesList: action.payload,
  })),
  on(productActions.getAllContentPagesFailure, (state, action) => ({
    ...state,
    contentPagesList: [],
    error: action.payload,
  })),

  on(productActions.getSpotlights, (state) => ({ ...state })),
  on(productActions.getSpotlightsSuccessfully, (state, action) => {
    return { ...state, spotlights: action.payload.spotlights , userHasSpotlights: action.payload.userHasSpotlights }
  }),
  on(productActions.getSpotlightsFailed, (state, action) => ({
    ...state,
    spotlights: [],
    error: action.payload,
  })),

  on(productActions.markSpotlightAsRead, (state) => ({ ...state })),
  on(productActions.markSpotlightAsReadSuccessfully, (state, action) => {
    const updatedSpotlights = [...state.spotlights];
    const updatedIndex = updatedSpotlights.findIndex((spotlight) => spotlight.id === action.payload.spotLightId);
    updatedSpotlights.splice(updatedIndex, 1);
    return { ...state, spotlights: [...updatedSpotlights], error: undefined };
  }),
  on(productActions.markSpotlightAsReadSuccessfully, (state, action) => ({ ...state, error: action.payload })),

  on(productActions.getPublishedContentBox, (state) => ({ ...state })),
  on(productActions.getPublishedContentBoxSuccess, (state, action) => ({
    ...state,
    contentBox: action.payload,
  })),
  on(productActions.getPublishedContentBoxFailure, (state, action) => ({
    ...state,
    contentBox: undefined,
    error: action.payload,
  })),


);

export function reducer(state: FeatureState | undefined, action: Action) {
  return productsReducer(state, action);
}


function filterProducts(payload: string | CategoryDto, products: ProductLineDto[]): ProductLineDto[] {
  debug("filter...", payload, products)
  if(payload === "ALL") {
    return products;
  }
  const filter: CategoryDto = payload as CategoryDto;
  return products.filter((product: ProductLineDto) => {
    return product.categories.some((category: CategoryDto) => category._id === filter._id);
  });
}
// Reducer Declarations - END
