/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input , Output , EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { SpotlightTypes } from '@modeso/types__dgoods-products';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'spotlight-component',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss'],
})
export class SpotlightComponent {


  @Input() spotlight;
  @Input() products;
  @Input() contentPages;
  @Output() carouselRemoved: EventEmitter<any> = new EventEmitter();
  @Output() imageLoaded: EventEmitter<any> = new EventEmitter();

  loaded: boolean;
  constructor(private router: Router, private productsProvider: ProductStoreProvider) {}

  ngOnInit(){
    this.loaded = false;
  }
  redirectTo(spotlight){

    if(spotlight.navigationType === SpotlightTypes.PRODUCT){

      if(this.products){
        const product = this.products.find((product)=> product.productLineName === spotlight.productLineId)
        this.router.navigate([`de-ch/product-buy/${product.productLineId}`]);
      }

    }else if(spotlight.navigationType === SpotlightTypes.CONTENT){

      if(this.contentPages){
        const contentPage = this.contentPages.find((cp)=> cp.contentPageId === spotlight.contentPageId);
        this.router.navigate([contentPage.routeLink]);
      }

    }else{
      window.location.href = spotlight.navigationUrl;
    }
  }

  closeCarousel(){
    this.productsProvider.markSpotlightAsRead$(this.spotlight.id);
    this.carouselRemoved.emit();
  }

  getBrowserLang(){
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
        agbLanguage = 'de-ch';
    } else if (browserLanguage.includes('fr')) {
        agbLanguage = 'fr-ch';
    } else if (browserLanguage.includes('it')) {
        agbLanguage = 'it-ch';
    } else {
        agbLanguage = 'en-us';
    }
    return agbLanguage;
  }
  onImageError(event){
    event.target.src = '';
    this.imageLoaded.emit();
  }

  onImageLoad(event){
    this.loaded = true;
    this.imageLoaded.emit();

  }
}
