/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input, ElementRef, ViewChild , HostListener} from '@angular/core';
import { ISpotLight } from '@modeso/types__dgoods-products';
import Flickity from 'flickity';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-spotlight-list-component',
  templateUrl:'./spotlight-list.component.html',
  styleUrls: ['./spotlight-list.component.scss'],
})
export class SpotlightListComponent {

  flickity: Flickity | undefined;
  activeIndex: number = 0;

  @Input() spotlights: ISpotLight[] = [];
  @Input() products;
  @Input() contentPages;
  @ViewChild('carouselElement', { static: false }) carouselElement: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.flickity.resize(); // Trigger Flickity to update its layout
  }
  imagesLoadedCount = 0;

  constructor(private loadingService: LoadingService) {
    this.loadingService.setSpotlightsLoading(true);
    setTimeout(() => {
      this.loadingService.setSpotlightsLoading(false);
    } , 6000);
    // Start a timeout after the first image is loaded to show the page after 6secs even if some images are not loaded yet.

  }

  ngAfterViewInit(): void {
    this.initCarousel();
  }

  initCarousel(): void {

    if (this.flickity) {
      this.flickity.destroy();
    }

    if (this.carouselElement) {
      this.flickity = new Flickity(this.carouselElement.nativeElement, {
        // Flickity options here
        freeScroll: false,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        touchVerticalScroll: false,
        percentPosition: false
      });

      this.flickity.on('change', (index: number) => {
        this.activeIndex = index;
      });
    }
  }


  updateSpotlightsTemplate(index){
    let slideElements = this.carouselElement.nativeElement.querySelectorAll('.carousel-cell');
    if (slideElements.length > index) {      
      this.spotlights = this.spotlights.filter((_, i) => i !== index);
      this.flickity.remove(slideElements[index]);
      // re-initalize the correct number of carousels
      slideElements = this.carouselElement.nativeElement.querySelectorAll('.carousel-cell');
      this.activeIndex = this.flickity.selectedIndex;
      if (this.spotlights.length === 1) {
        slideElements[0].style.marginLeft = '10px';
      }
    }
  }

  handleHTMLngClasses(){

    if(this.activeIndex === 0 && this.spotlights.length !== 1){
      return 'add-left-margin'
    }

    return ''
  }

  handleImageLoaded(){
    this.imagesLoadedCount++;
    if(this.imagesLoadedCount === this.spotlights.length){
      this.loadingService.setSpotlightsLoading(false);
    }
  }
}
