import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import Debug from 'debug';
import * as fromFeature from '../reducers/dgoods.session.reducer';
import * as fromActions from '../actions/dgoods.session.actions';
import { ICheckInRequest, IStartRequest, ICheckInRequestAuth, IValidateUrlRequest } from '../services/dgoods.session.service';
import { pluck, map } from 'rxjs/operators';

const debug = Debug('dgoods:session:DgoodsSessionStoreProvider');

@Injectable({
  providedIn: 'root'
})
export class DgoodsSessionStoreProvider {

  constructor(public store: Store<fromFeature.AppState>) {
  }

  public checkin(body: ICheckInRequest, credentials: ICheckInRequestAuth): void {
    this.store.dispatch(fromActions.onCheckin({ payload: body, auth: credentials }));
  }

  public getNewAccsessToken(): void {
    this.store.dispatch(fromActions.onGetNewAccesToken());
  }

  public logout(): void {
    this.store.dispatch(fromActions.onLogout());
  }

  public start(body: IStartRequest): Observable<fromFeature.FeatureState> {
    this.store.dispatch(fromActions.onStart({ payload: body }));
    return this.getSessionState$();
  }

  public validateUrl(body: IValidateUrlRequest): void {
    this.store.dispatch(fromActions.onValidateUrl({ payload: body }));
  }

  public getSessionState$(): Observable<fromFeature.FeatureState> {
    return this.store.pipe(select(fromFeature.selectFeatureSession));
  }

  public isAllowedToCheckout$(): Observable<boolean> {
    return this.store.pipe(select(fromFeature.selectFeatureSession), pluck('checkInDate'),
      map((checkInDate: Date) => {
        const savedCheckinDate = localStorage.getItem('checkInDate');
        // if store is empty load checkin date from local storage
        checkInDate = checkInDate ? checkInDate : (savedCheckinDate ?
          new Date(savedCheckinDate) : null);
        if (checkInDate === null || checkInDate === undefined) {
          return false;
        }
        const offset15min = 15 * 60 * 1000;
        try {
          if (new Date(checkInDate).getTime() + offset15min > new Date().getTime()) {
            return true;
          } else {
            return false;
          }
        } catch (error) {
          debug(error);
          return false;
        }

      }));
  }

}
