import {IDGoodsButtonDelegate, DGoodsOverlayComponent, IDGoodsDialogBoxDelegate, DGoodsDialogBoxComponent } from '@modeso/twint-lib-core-fe';
import { takeUntil, take } from 'rxjs/operators';
import { UserProvider } from '@modeso/dgoods-lib-user-fe';
import { Injector, OnInit, ViewChild, Component } from '@angular/core';
import debug from 'debug';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ProductCheckoutComponent } from '../../components/ProductCheckout/product-checkout.component';
import { Cart } from '@modeso/dgoods-lib-cart-fe';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-email-registeration-page',
  templateUrl: './email-registeration.page.view.html',
  styleUrls: ['./email-registeration.page.view.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EmailRegisterationPage  implements  OnInit,  IDGoodsDialogBoxDelegate, IDGoodsButtonDelegate {

  @ViewChild('errorOverlay', { static: false }) errorOverlay: DGoodsOverlayComponent;
  @ViewChild('dialogBoxOverlay', { static: false }) dialogBoxOverlay: DGoodsDialogBoxComponent;

  subscriptions = [];
  newEmail: string
  message: string;
  menuItems = []; 
  productId;
  menuState = MenuItemStates.CHILD;
  existingUserEmail;
  redirectFrom;
  disablePurchaseBtn = true;
  isFormValid = false;
  navigationData:any;
  emailNavigation;

  private destroy$ = new Subject();

  public dialogBoxObj = {
    title: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_title_duplicate_email',
    description: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_message_duplicate_email',
  };

  @ViewChild('productCheckout', { static: false }) productCheckout: ProductCheckoutComponent;


  constructor(private injector: Injector,
              private userProvider: UserProvider,
              private route: ActivatedRoute,
              private router:Router,
              private appStateProvider: AppStateDispatcher
    ) {


      this.productId = this.route.snapshot.paramMap.get('productLineId');
    
      this.emailNavigation = {
        redirectTo : ``,
        redirectFrom: `de-ch/product-buy/${this.productId}`
      }
      this.redirectFrom = `de-ch/register-email/${this.productId}`;

    // to override browser back btn history
    router.events
    .pipe(takeUntil(this.destroy$))
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.router.navigate([this.emailNavigation.redirectFrom])
    }});
  }

  ngOnInit() {
    this.getEmailData();
    this.appStateProvider.getNavigationData()
    .pipe(takeUntil(this.destroy$))
    .subscribe((navigationData)=>{
      if(navigationData.refreshed){
        this.router.navigate([this.emailNavigation.redirectFrom])
      }
      if(navigationData){
        this.navigationData = navigationData;
        this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions && this.isFormValid)
        this.existingUserEmail = localStorage.getItem('email') ? localStorage.getItem('email') : this.navigationData.email;
      }
    })
    
  }

  
  onOverlayButtonClicked(): void {
    this.errorOverlay.hidden = true;
  }

  onDialogBoxButtonClicked() {
    this.dialogBoxOverlay.hidden = true;
  }


  onButtonClicked(): void {
    debug('Button clicked');

    this.disablePurchaseBtn = true;
        const body = {
          email: this.newEmail ,
        };
        // TODO remove to select afetr the status change
        let index = 0;
        this.userProvider.updateUser$(body).pipe(take(2))
          .subscribe((userData) => {
            index++;
            if (index === 2) {
              if (userData.email) {
                this.message = 'dgoods_shared_overlaycomponent_messageemailsuccess';
                localStorage.setItem('email', userData.email);

                let checkoutRequest: Cart = {
                  productId: this.navigationData.unviersalProductCode,
                  amount: this.navigationData.price,
                  receiverEmail: userData.email,
                  twintTC: localStorage.getItem('twintTC') ? 'true' : null,
                  terminalId: sessionStorage.getItem('terminalId'),
                };
                
                if(this.navigationData.couponCode){
                  checkoutRequest.couponCode = this.navigationData.couponCode
                }
                
                this.productCheckout.checkoutProduct(checkoutRequest);
              } else {
                if (userData.status.toString() === '405') {
                  this.dialogBoxOverlay.hidden = false;
                } else {
                  this.message = 'dgoods_shared_overlaycomponent_messageemailfailed';
                  this.errorOverlay.hidden = false;
                }
              }
              this.disablePurchaseBtn = false;
            }

          });
  }


  checkFormValidity(event){
    this.isFormValid = event.formValidity;
    this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions && this.isFormValid)
    this.newEmail = event.newUserEmail;
    // save email in store in case the user proceeded forward or backward
    if(this.isFormValid){
      this.appStateProvider.saveNavigationData({email:event.newUserEmail})
    }else{
      this.appStateProvider.saveNavigationData({email:null})
    }
  }

  getEmailData(){
    this.existingUserEmail = localStorage.getItem('email');
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
