import { Component, OnInit } from '@angular/core';
import { DataProtectionOfTwintPageControllerDirective } from './data-protection-of-twint.controller';
import Debug from 'debug';

const debug = Debug('dgoods:shop:DataProtectionOfTwintPage');
@Component({
  selector: 'app-page-data-protection-of-twint',
  templateUrl: './data-protection-of-twint.page.view.html',
  styleUrls: ['./data-protection-of-twint.page.view.scss']
})
export class DataProtectionOfTwintPage extends DataProtectionOfTwintPageControllerDirective {

}

