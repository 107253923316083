import { Component, Input } from '@angular/core';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsOrderConfirmationOverlayComponent');

@Component({
  selector: 'dgoods-order-confirmation-overlay',
  templateUrl: './dgoods-order-confirmation-overlay.component.html',
  styleUrls: ['./dgoods-order-confirmation-overlay.component.scss']
})
export class DGoodsOrderConfirmationOverlayComponent {
    @Input() hidden?: boolean;
}
