<div class="overlay" [style.backgroundColor]="backgroundColor" [hidden]="hidden" [ngClass]="{'html-scroll' : htmlScroll, 'copyOverlay' : copyOverlay}">
  <div class=" text" *ngIf="error">
    <span>{{message| injectedText | async}}</span>
    <br>
    <button type="button" (click)="onClick()"
      class="overlay-button">{{ "dgoods_shared_overlaycompontent_ok" | injectedText | async }}</button>
  </div>

  <div *ngIf="progress" class="text">
    <img src="assets/loading_v2_w-looped.gif" class="loader" />
  </div>

  <div *ngIf="html || redemtion || scannableCode">
    <ng-content></ng-content>
    <div *ngIf="scannableCode" class="sticky">
      <dgoods-button [delegate]="this">
        {{ "dgoods_shared_termsofservicecompontent_conclude" | injectedText | async }}
      </dgoods-button>
    </div>
  </div>
</div>
