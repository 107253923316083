import {AfterViewInit, Directive,ElementRef} from '@angular/core'
  
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector:'autofocus'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class AutoFocus implements AfterViewInit{
  
    constructor(
        private elementRef: ElementRef
    ){}
  
    ngAfterViewInit(){
        this.elementRef.nativeElement.focus();
    }
}