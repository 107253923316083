/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { ViewportScroller } from '@angular/common';
import {Component,  Input , Output , EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CouponEffectTypeEnum } from '@modeso/types__twint-lib-coupons';
import { Subject } from 'rxjs';
import { takeUntil , filter} from 'rxjs/operators';
import { HtmlEnitityDecoder } from '../../shared/classes/HtmlDecoder';
import { SafeHtmlAllowUrlPipe } from '../../shared/pipes/safeHtmlAllowUrl.pipe';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';


@Component({
  selector: 'app-checkout-flyout-component',
  templateUrl: './checkout-flyout.component.html',
  styleUrls: ['./checkout-flyout.component.scss'],
})
export class CheckoutFlyoutComponent  implements OnDestroy  {


  @Input() twintTC;
  @Input() product;
  @Input() price;
  @Input() disablePurchaseBtn;
  @Input() couponState;
  @Input() currency;
  // this input is used when the user goes from the main component to T&C so it helps to be redirected back
  @Input() redirectFrom;
  @Output() productTerms = new EventEmitter<any>();
  @Output() userPurchasedProduct = new EventEmitter<boolean>();
  @Output() generalTermsAndConditions = new EventEmitter<boolean>();

  @ViewChild('enforceCheckbox', {static: false}) enforceCheckbox;
  @ViewChild('twintTermsCheckbox', {static: false}) twintTermsCheckbox;


  coupon;
  productEnforcementCheckbox=false;
  destroy$: Subject<any> = new Subject<any>();


  constructor(private changeDetector: ChangeDetectorRef,
              private router: Router,
              private pipe: SafeHtmlAllowUrlPipe,
              private appStateProvider: AppStateDispatcher,
              private route: ActivatedRoute,
              private viewportScroller: ViewportScroller
              ) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit() {

    if(this.product && !this.product.enforceTC){
      this.appStateProvider.saveNavigationData({productTermsAndConditions: true})
    }

    this.appStateProvider.getNavigationData().pipe(
      takeUntil(this.destroy$),
    ).subscribe((navigationData)=>{

      this.productEnforcementCheckbox = navigationData.productTermsAndConditions;


      if(this.twintTermsCheckbox){
        this.twintTermsCheckbox.checked = navigationData.twintTermsAndConditions;
        this.generalTermsAndConditions.emit(this.twintTermsCheckbox.checked);
      }

      if(this.enforceCheckbox){
        this.enforceCheckbox.checked = this.productEnforcementCheckbox;
      }

      if(navigationData.couponCode){
        this.coupon = {
          discountAmount:navigationData.discountAmount,
          effectValue: navigationData.effectValue,
          discountPrice: navigationData.discountPrice,
          effectType: navigationData.effectType
        }
      }else{
        this.coupon = null;
      }

      this.productTerms.emit(this.productEnforcementCheckbox);

    })

    this.changeDetector.detectChanges();

  }


  public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
        agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
        agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
        agbLanguage = 'it';
    } else {
        agbLanguage = 'en';
    }
    return 'assets/pdf/' + agbLanguage + '/shopterms.pdf';
  }

  clickOnTerms(event){
    this.router.navigate([`de-ch/terms-and-conditions/${this.product.productLineId}`,{enforce: this.product.enforceTC,redirectFrom: this.redirectFrom}],{skipLocationChange: true})
    // to keep the data from vanishing on going back and forth in the app
    this.appStateProvider.saveNavigationData({refreshed: false})
    event.preventDefault();
  }

  purchaseProduct(){
    this.userPurchasedProduct.emit(true);
  }

  setGTC(event){

    this.appStateProvider.saveNavigationData({twintTermsAndConditions:event.checked})

  }

  transformText(text){
    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(text),'string')
  }

  public get effectType(): typeof CouponEffectTypeEnum {
    return CouponEffectTypeEnum;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
