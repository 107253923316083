/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component, Injector, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CartProvider, CheckoutData } from '@modeso/dgoods-lib-cart-fe';
import { DGoodsDialogBoxComponent, DGoodsOverlayComponent, IDGoodsDialogBoxDelegate } from '@modeso/twint-lib-core-fe';
import { take } from 'rxjs';
import { BasePageComponent } from '../../pages/base.page';
import { AmountLimitExceededService } from '../../shared/services/amountLimitExceededService.service';
import Debug from 'debug';

const debug = Debug('dgoods:project:ProductCheckoutComponent');



@Component({
  selector: 'app-product-checkout-component',
  templateUrl: './product-checkout.component.html',
  styleUrls: ['./product-checkout.component.scss'],
})
export class ProductCheckoutComponent extends BasePageComponent implements
  IDGoodsDialogBoxDelegate  {

  redirectURL;
  receiptUrl: string;
  fromLocalization = true;
  currentDialogBox = { title: '', description: '' };
  userLimitExceeded = false;


  productLineDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_productLine_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_productLine_exceed_limit',
  };
  shopDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_shop_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_shop_exceed_limit',
  };
  userDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_user_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_user_exceed_limit',
  };

  userInvalidCodeLimitDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_user_exceed_limit',
    description:'dgoods_shop_productbuyview_dialogbox_message_user_exceed_invalidcodelimit'
  };

  @ViewChild('progressOverlay', { static: false }) progressOverlay: DGoodsOverlayComponent;
  @ViewChild('dialogBoxOverlay', { static: false }) dialogBoxOverlay: DGoodsDialogBoxComponent;
  @ViewChild('appSwitch', { static: false }) appSwitch: DGoodsOverlayComponent;

  constructor(
    injector: Injector,
    public router: Router,
    private cartProvider: CartProvider,
    private limitExceededService: AmountLimitExceededService,

  ) { 
    super(injector);

  }

  ngOnInit(){

    this.subscriptions.push(
      this.cartProvider.getError$().pipe(take(1)).subscribe((error) => {
        if (error) {
          this.progressOverlay.hidden = true;
          if (error.status.toString() === '405') {
            this.userLimitExceeded = true;
            this.fromLocalization = false;
            this.currentDialogBox.title = this.userDialogBox.title;
            this.currentDialogBox.description = error.error.message;
            this.dialogBoxOverlay.hidden = false;
          } else if (error.status.toString() === '406') {
            this.fromLocalization = true;
            this.userLimitExceeded = true;
            this.currentDialogBox = this.shopDialogBox;
            this.dialogBoxOverlay.hidden = false;
          } else if (error.status.toString() === '410') {
            this.fromLocalization = true;
            this.userLimitExceeded = true;
            this.currentDialogBox = this.productLineDialogBox;
            this.dialogBoxOverlay.hidden = false;
          } else if (error.error.registerationUrl){
            sessionStorage.setItem('registerationUrl', error.error.registerationUrl);
            this.limitExceededService.setAmount(error.error.amount)
            this.limitExceededService.setAnnualAmount(error.error.annualAmount)
            this.router.navigate(['../../de-ch/error'], { queryParams : {canUpgradeLimit: true }});
          } else if (error.error.sweepayerror){
            this.limitExceededService.setAmount(error.error.amount)
            this.router.navigate(['../../de-ch/error'], { queryParams :
              {canUpgradeLimit: false, type: error.error.type}});
          } else {
  
            this.router.navigate(['../../de-ch/error']);
          }
  
        }
      }));
  }
  ngAfterViewInit(): void {

    this.subscriptions.push(
      this.cartProvider.getCheckoutError$().subscribe((error) => {
       if (error) {
        this.progressOverlay.hidden = true;
        if (error.status.toString() === '405') {
          this.userLimitExceeded = true;
          this.fromLocalization = false;
          this.currentDialogBox.title = this.userDialogBox.title;
          this.currentDialogBox.description = error.error.message;
          this.dialogBoxOverlay.hidden = false;
        } else if (error.status.toString() === '406') {
          this.fromLocalization = true;
          this.userLimitExceeded = true;
          this.currentDialogBox = this.shopDialogBox;
          this.dialogBoxOverlay.hidden = false;
        } else if (error.status.toString() === '409') {
          this.checkUserInvalidCodeLimitation(true)
        }  else if (error.status.toString() === '410') {
          this.fromLocalization = true;
          this.userLimitExceeded = true;
          this.currentDialogBox = this.productLineDialogBox;
          this.dialogBoxOverlay.hidden = false;
        } else if (error.error.registerationUrl){
          sessionStorage.setItem('registerationUrl', error.error.registerationUrl);
          this.limitExceededService.setAmount(error.error.amount)
          this.limitExceededService.setAnnualAmount(error.error.annualAmount)
          this.router.navigate(['../../de-ch/error'], { queryParams : {canUpgradeLimit: true }});
        } else if (error.error.sweepayerror){
          this.limitExceededService.setAmount(error.error.amount)
          this.router.navigate(['../../de-ch/error'], { queryParams :
            {canUpgradeLimit: false, type: error.error.type}});
        } else {
          this.router.navigate(['../../de-ch/error']);
        }
      }
    }),
    );
  }

  checkoutProduct(checkoutRequest){
    
      this.progressOverlay.hidden = false;
      let checkoutSubscribtionCount = 0;
      this.subscriptions.push(
        this.cartProvider.checkout(checkoutRequest).pipe(take(2)).subscribe((response: CheckoutData) => {
        checkoutSubscribtionCount++;
        if (response && response.order) {
          this.subscriptions.push(this.cartProvider.getCartToken$().pipe(take(2)).subscribe((orderToken: string) => {
            this.switchToApp(orderToken);
          }));
          this.subscriptions.push(this.cartProvider.getReceiptUrlOfORder$().pipe(take(2)).subscribe((receiptURL: string) => {
            if (receiptURL) {
              const arr = receiptURL.split('/');
              this.receiptUrl = arr[arr.length - 1];
            }
          }));
        }
      }));
  }


  switchToApp(orderToken: string) {
    const device = localStorage.getItem('device');
    const protocol = localStorage.getItem('protocol');
    this.progressOverlay.hidden = true;
    if (device === 'ios') {
      const appSwitchIOS = {
        app_action_type: 'TWINT_PAYMENT',
        extras: {
          code: orderToken
        },
        referer_app_link: {
          app_name: 'TWINT_MORE_DIGITAL_GOODS',
          target_url: '',
          url: ''
        },
        version: '6.0'
      };
      const jsonEncodedObj = JSON.stringify(appSwitchIOS);
      this.redirectURL = `${protocol}://applinks/?al_applink_data=${jsonEncodedObj}`;
      debug(`ios browser-app-switch-redirect ${this.redirectURL}`);
      debug(this.redirectURL);
      window.location.href = this.redirectURL;
      this.appSwitch.hidden = false;
    } else if (device === 'android') {
      // tslint:disable-next-line:max-line-length
      let startingOrigin = "TWINT_MORE_DIGITAL_GOODS";
      this.redirectURL = `intent://payment#Intent;package=${protocol};action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${orderToken};S.startingOrigin=${startingOrigin};end`;
      debug(`android browser-app-switch-redirect ${this.redirectURL}`);
      this.appSwitch.hidden = false;
      debug(this.redirectURL);
      window.location.href = this.redirectURL;
    }

  }

  onOverlayButtonClicked(): void {
    if (this.appSwitch.hidden === false) {
      this.appSwitch.hidden = true;
      this.router.navigate(['../../de-ch/order-confirmation/', this.receiptUrl], {});
    } else {
      this.router.navigate(['../../de-ch/error']);
    }
  }

  checkUserInvalidCodeLimitation(isUserBlocked){
    if(isUserBlocked){
      this.userLimitExceeded = true;
      this.fromLocalization = true;
      this.currentDialogBox.title = this.userInvalidCodeLimitDialogBox.title;
      this.currentDialogBox.description = this.userInvalidCodeLimitDialogBox.description;
      this.dialogBoxOverlay.hidden = false;
    }
  }

  onDialogBoxButtonClicked(): void {
    this.dialogBoxOverlay.hidden = true;
  }
}
