import { Injectable } from '@angular/core'
import * as fromActions from './../actions/cart.actions'
import * as fromFeature from './../reducers/cart.reducer'
import { Store, select } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import Debug from 'debug'
import {CheckoutResponse} from '@modeso/types__dgoods-cart'
import { ICodeValidityCheckRequest} from '@modeso/types__twint-lib-coupons'
import { Cart, CheckoutData, PendingOrder, Order, OrderMetadata } from '../models/domain.models'
import { ICartProvider } from './cart.provider.interface'
const debug = Debug('modeso:twint-lib-cart-fe')

@Injectable({
  providedIn: 'root'
})
export class CartProvider implements ICartProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }

  /**
   * function checkout to dispatch action of post cart.
   * @param cart
   */
  public checkout(cart: Cart): Observable<CheckoutData> {
    this.store.dispatch(fromActions.postCart(cart));
    debug("checkout request sent")
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutResponse) => {
      if (Object.keys(cartResponse).length) {
        return {
          messageId: cartResponse.messageId,
          order: {
            token: cartResponse.order.token,
            receiptURL: cartResponse.order.receiptURL,
            orderId: cartResponse.order.orderId,
          } as PendingOrder
        } as CheckoutData
      } else {
        debug('cart store is empty');
        return null;
      }
    }));
  }

  public getCartToken$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutResponse) => {
      if (cartResponse) {
        return cartResponse.order.token;
      } else {
        debug('cart store is empty');
        return null;
      }
    }));
  }

  public getPurchasedProducts$(): Observable<OrderMetadata[]> {
    this.store.dispatch(fromActions.getOrdersByUserId());
    return this.store.pipe(select(fromFeature.featurePurchasedProducts),
      map((purchasedProductResponse: OrderMetadata[]) => {
        if (purchasedProductResponse) {
          return purchasedProductResponse;
        } else {
          return null;
        }

      })
    );
  }

  public getReceiptUrlOfORder$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutResponse) => {
      if (cartResponse) {
        return cartResponse.order.receiptURL;
      } else {
        debug('cart store is empty');
        return null;
      }
    }));
  }

  public getOrderByReceiptUrl$(receipturl: string): Observable<Order> {
    this.store.dispatch(fromActions.getOrderByReceiptUrl({ receiptURL: receipturl }));
    return this.store.pipe(select(fromFeature.featureOrder),
      map((order: Order) => {
        return order;
      })
    );
  }
  public getError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.featureError),
      map((error: any) => {
        return error;
      })
    );
  }

  public getCheckoutError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.featureCheckoutError));
  }
  public flushError(){
     this.store.dispatch(fromActions.flushCartError())
  }

  public getCouponState$(){
   return this.store.dispatch(fromActions.getCouponState())
  }

  public selectCouponState$(){
    return this.store.pipe(select(fromFeature.getCouponState))
  }

  public checkCodeValidity$(body:ICodeValidityCheckRequest) : Observable<any>{
     this.store.dispatch(fromActions.checkCodeValidity({ payload: body }));
     return this.store.pipe(select(fromFeature.getCodeValidity),
     map((response: any) => {
       return response;
     })
   );
  }

}
