import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDGoodsTableDataSource } from './dgoods-table.datasource';
import Debug from 'debug';
import { ProductLineImage } from '@modeso/types__dgoods-products';
import { HtmlEnitityDecoder } from '../../../shared/classes/HtmlDecoder';
import { SafeHtmlAllowUrlPipe } from '../../../shared/pipes/safeHtmlAllowUrl.pipe';
const debug = Debug('dgoods:shared:DGoodsTableCellComponent');

export interface IDGoodsTableCellDelegate {
  onTableCellClick(data: IDGoodsTableDataSource): void;
}

@Component({
  selector: 'dgoods-table-cell2',
  templateUrl: './dgoods-table-cell.component2.html',
  styleUrls: ['./dgoods-table-cell.component2.scss']
})
export class DGoodsTableCellComponent2 {

  @Input() delegate?: IDGoodsTableCellDelegate;
  @Input() datasource: IDGoodsTableDataSource;
  @Output() clickedData = new EventEmitter<IDGoodsTableDataSource>();

  constructor(private pipe: SafeHtmlAllowUrlPipe){}

  public getImage(): ProductLineImage {
    if(this.datasource.productLineImages === undefined) return {} as ProductLineImage;

    let img = this.datasource.productLineImages.find(
      (x) => x.imageSize === 'MASTER_IMAGE',
    );
    if (img === undefined) {
      img = this.datasource.productLineImages.find(
        (x) => x.imageSize === 'LARGE',
      );
    }
    if (img === undefined) {
      img = {} as ProductLineImage;
      img.frontImage = 'assets/placeholder.png';
    }
    return img;
  }
  getCssClasses() {
    return 'image-tile';
  }

  getCssClassDiscount(){
    if(this.datasource.discounts){
      return '-discount';
    }else{
      return '';
    }
    // It results in either first-text or first-text-discount
    // Which are css classes defined in dgoods-table-cell.component.scss
  }

  onClick() {
    if (this.delegate) {
      this.clickedData.emit(this.datasource);
      this.delegate.onTableCellClick(this.datasource);
    } else {
      debug('onTableCellClick event not fired. No delegate defined for the component.');
    }
  }

  transformText(text){
    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(text),'string')
  }
}
