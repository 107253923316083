<header class="header-top col-lg-12 col-xs-12">
    <div class="row">
        <div class="col-lg-9 col-xs-9">
            <span>HEAD1</span>
        </div>
        <div class="col-lg-3 col-xs-3" style="text-align: right;">
        </div>
        <button class="btn btn-danger" (click) = "logout()">logout</button>
    </div>
</header>
<header class="header-middle col-lg-12 col-xs-12">
    <div class="row">

        <div class="col-lg-10 col-xs-10">
            <div class="row">
                    <ng-content></ng-content>
            </div>
        </div>

    </div>
</header>
<header class="header-bottom col-lg-12 col-xs-12">
</header>