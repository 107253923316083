import { Component,Input, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-redirct-paymentscreen',
  templateUrl: './redirctPaymentscreen.component.view.html',
  styleUrls: ['./redirctPaymentscreen.component.view.scss']
})

export class RedirctPaymentscreenComponent implements OnInit {

  constructor( private sanitizer: DomSanitizer ) { }

  @Input() redirectURL: string ;

  ngOnInit(): void { }

  urlfn() {
    return this.sanitizer.bypassSecurityTrustUrl(this.redirectURL);
  }
}
