import { Component, Renderer2, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

// TODO move to modeso-localization
@Component({
  selector: 'modeso-language-switcher',
  templateUrl: './languageSwitcher.component.html',
  styleUrls: ['./languageSwitcher.component.scss'],
})
export class LanguageSwitcherComponent implements AfterViewInit, OnInit, OnDestroy {
  private languages: Map<string, any> = new Map<string, any>();
  private subscription: Subscription | undefined;

  @ViewChild('languageSwitcher', { static: false })
  private el!: ElementRef;

  @Input()
  public availableLanguages: Array<string> | undefined;

  private navigationEnd$: Observable<NavigationEnd>;
  constructor(private router: Router, private renderer: Renderer2) {
    this.navigationEnd$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    this.constructNavigationItems();
    this.subscription = this.navigationEnd$.subscribe((evt) => {
      const activeLanguage = evt.url.split('/')[1];
      this.setRoutes(activeLanguage, evt.url);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.renderLangueageSwitcherLinks();
  }

  private constructNavigationItems() {
    if (this.availableLanguages) {
      this.languages = new Map<string, any>();
      this.availableLanguages.forEach((language) => {
        const displayText = language.split('-')[0].toUpperCase();
        const text = this.renderer.createText(displayText);
        const a = this.renderer.createElement('a');
        this.renderer.appendChild(a, text);
        this.languages.set(language, a);
      });
    }
  }

  private renderLangueageSwitcherLinks() {
    if (this.availableLanguages) {
      this.availableLanguages.forEach((language) => {
        const a = this.languages.get(language);
        const li = this.renderer.createElement('li');
        this.renderer.appendChild(li, a);
        this.renderer.appendChild(this.el.nativeElement, li);
      });
    }
  }

  private setRoutes(activeLanguage: string, route: string) {
    if (this.availableLanguages) {
      this.availableLanguages.forEach((language) => {
        const a = this.languages.get(language);
        this.renderer.setAttribute(a, 'href', route.replace(activeLanguage, language));
        if (activeLanguage === language) {
          this.renderer.addClass(a, 'active');
        } else {
          this.renderer.removeClass(a, 'active');
        }
      });
    }
  }
}
