import { Injectable, Inject, ComponentFactoryResolver } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DgoodsUserConfigService } from './../dgoods.user.config';
import { PhoneNumber } from 'libphonenumber-js';

export interface IUpdateUserRequest {
  email: string;
  status?: string;
}

export interface IUpdateUserResponse {
  message: string;
  email: string;
}


export interface IUpdateSweepayAccountRequest {
  mobilePhoneNumber: string;
  languageTag?: string,
  sweepayId?: string
}
export interface ISendMobilePhoneOtpRequest {
  otpIdentifier: string,
  sweepayId?: string

  // accountIdentifier: string
}


export interface IUpdateSweepayAccountResponse {
  isRedirected: boolean;
  otpLength?: number;
  otpIdentifier?: string;
  responseError?:responseError
}

export interface ILookUpAccountResponse {
  isEmptyData: boolean,
  sweepayId?: string
}
export interface IValidateSweepayOtpRequest {
  otpValue: string;
}


export interface responseError{
  code:number,
  message: string
}

export interface IValidateSweepayOtpResponse {
  isAccepted?: boolean,
  remainingAttempts?: number,
  responseError?: responseError;
  mockIntegration?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DgoodsUserService {
  private server = '';
  private microserviceName = 'users';
  private account = 'account';
  private validateOtp ="sweepayOtp";
  private resend ="resend"
  // private phoneNumber = "phone"
  private readonly route = '/';



  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(DgoodsUserConfigService) private config) {
    this.server = config.apiGateway;
  }

  updateUserEmail(body: IUpdateUserRequest) {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.post<IUpdateUserResponse>(url, body);
  }

  getUserPhoneNumber() {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.get<string>(url);
  }

  lookupAccount() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.account}`;
    return this.http.get<ILookUpAccountResponse>(url);
  }

  updateSweepayAccountWithPhoneNo(body: IUpdateSweepayAccountRequest) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.account}`;
    return this.http.put<IUpdateSweepayAccountResponse>(url, body);
  }

  validateSweepayOtp(body: IValidateSweepayOtpRequest) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.validateOtp}`;
    return this.http.post<IValidateSweepayOtpResponse>(url, body);
  }

  resendSweepayOTP(body:ISendMobilePhoneOtpRequest){
    const url = `${this.server}${this.microserviceName}${this.route}${this.account}${this.route}${this.resend}`;
    return this.http.post<IUpdateSweepayAccountResponse>(url, body);
  }


}
