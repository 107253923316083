 <div class="checkout-confirmation-container">
  <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
      <div class="checkout-confirmation-content">
      <div class="confirmation-img-msg">
        <img class="confirmation-img" src="assets/icon-shopping-cart.png" />
        <div class="confirmation-msg">
          {{'dgoods_shop_redirect_to_payment_with_url' | localize | async}}
          <a [href]="urlfn()"> {{'dgoods_shop_here' | localize | async}} </a>
        </div>
      </div>
    </div>
</div>
