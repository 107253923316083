import {marked} from 'marked';
export class HtmlEnitityDecoder {

    constructor(){}


    renderContent(content: string): string {
      if (this.isHTMLEntityContent(content)) {
        return this.decodeHTML(content);
      } else {
        return marked(content);
      }
    }

    decodeHTML(html: string): any {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText;
    }

    isHTMLEntityContent(content: string): boolean {
        const specificRegex = /&[A-Za-z0-9]+;/;
        return specificRegex.test(content);
    }
}