/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CartProvider } from '@modeso/dgoods-lib-cart-fe';
import { ICodeValidityCheckRequest , CalculateDiscountAmount } from '@modeso/types__twint-lib-coupons';
import { BasePageComponent } from 'projects/dgoods-project-admin/src/app/pages/base.page';
import { Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';


@Component({
  selector: 'app-coupon-component',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent extends BasePageComponent implements OnInit {

  codeForm: FormGroup;
  hasAppliedCode = false;
  codeValid;
  idle = false;
  discountPrice = 0;
  discountAmount = 0;
  formattedDiscountPrice = "0.00";
  formattedDiscountAmount = "0.00";
  formattedAmount = "0.00";
  minLengthValidationIsOk = false;
  private destroy$ = new Subject();
  private priceCalculation: CalculateDiscountAmount;

  @ViewChild('couponInput') couponInput: ElementRef;
  @ViewChild('couponInput2') couponForm: ElementRef;

  @Input() product;
  @Input() amount;
  @Input() couponState;
  @Output() couponCode = new EventEmitter<number>();
  @Output() exceedInvalidError = new EventEmitter<boolean>();
  @Output() focused = new EventEmitter<any>();

  constructor(injector:Injector, private fb: FormBuilder,
              private cartProvider: CartProvider,
              public router: Router,
              private appStateProvider: AppStateDispatcher,
              ) {
    super(injector);
    this.priceCalculation = CalculateDiscountAmount.getInstanceOfDiscountAmount();

  }

  ngOnInit(): void {
    this.createForm();
    this.appStateProvider.getNavigationData().pipe(
      takeUntil(this.destroy$),
      take(1)
    ).subscribe((navigationData)=>{
      // to handle that coupon is not dismissed if the user clicked on ex edit btn then clicked back
      if(!navigationData.refreshed && navigationData.couponCode){
        this.discountCode.setValue(navigationData.couponCode);
        this.submit();
      }
    })


    this.codeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(()=> {
       if (this.codeForm.dirty) {
        this.onChangeCode();
        }
    });


  }

  ngAfterViewInit(){
  }


  createForm() {
    this.codeForm = this.fb.group({
        discountCode: this.fb.control('', [ Validators.maxLength(20),Validators.pattern('^[a-zA-Z0-9]*$')]),
    });
  }

  handleFocused() {
    this.focused.emit();
   }

  submit(){

    this.hasAppliedCode = true;
    this.codeValid = false;

    const body: ICodeValidityCheckRequest = {
      code: this.discountCode.value.toLowerCase(),
      amount: this.amount,
      product:this.product
    }
    this.idle=true;
    this.cartProvider.checkCodeValidity$(body).pipe(take(2)).subscribe((res)=>{
      this.discountCode.setErrors(null);

      if(res.coupon || res.error ){
        this.idle = false;
        if(res.coupon?.valid){
          this.codeValid = true;
          const coupon = res.coupon;
          const {discountAmount ,discountPrice} = this.priceCalculation.calculateDiscountAmount(coupon.effectType,this.amount,coupon.effectValue);
          this.discountAmount = discountAmount;
          this.discountPrice = discountPrice;
          this.formattedDiscountAmount = discountAmount.toFixed(2);
          this.formattedDiscountPrice = discountPrice.toFixed(2);
          this.formattedAmount = this.amount.toFixed(2);
          this.couponCode.emit(this.discountCode.value.toLowerCase());
          this.appStateProvider.saveNavigationData({couponCode: this.discountCode?.value?.toLowerCase(),
            effectValue: coupon.effectValue , effectType:coupon.effectType, discountAmount , discountPrice})


        }else{
          this.codeValid = false;
          this.couponCode.emit(null);
          this.appStateProvider.saveNavigationData({couponCode: null, effectType:null, effectValue:null , discountAmount:null , discountPrice : null})
          if(res.error.error.exceedInvalidThreshold){
            this.exceedInvalidError.emit(true);
            this.discountCode.setErrors({ err: true });
          }else if(res.error.error.error.includes('[Price below minimum]')){
            this.discountCode.setErrors({ belowMinPrice: true });
          }else{
            this.discountCode.setErrors({ err: true });
          }
        }
      }
    })
  }

  onChangeCode(){
    this.hasAppliedCode = false;
    this.resetCodeSettings();
  }

  resetCodeSettings() {
    this.codeValid = false;
    this.discountAmount = 0;
    this.discountPrice = 0;
    this.formattedDiscountAmount = "0.00"
    this.formattedDiscountPrice = "0.00"
    this.formattedAmount = "0.00"
    this.minLengthValidationIsOk = this.discountCode.value?.length >= 6
    this.couponCode.emit(null);
    this.appStateProvider.saveNavigationData({couponCode: null, effectType:null, effectValue:null , discountAmount:null , discountPrice : null})
  }

  // detect when the input (price) changes
  ngOnChanges(change) {
    this.codeValid =false;
    this.discountCode?.setErrors(null);

    if (change.amount) {

      if (this.codeForm && this.discountCode.value && change.amount) {
        this.resetCodeSettings();
        if (this.hasAppliedCode) {
          this.submit();
        }
      }
    }

  }

  getErrorMessage() {
    if (this.discountCode.hasError('belowMinPrice')) {
      return 'dgoods_shop_belowminprice_err_msg';
    }else {
      return 'dgoods_shop_invalid_code_text'
    }
  }

  get discountCode() {
    return this.codeForm?.get('discountCode');
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
