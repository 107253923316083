<div class="purchase-container">
    <div *ngIf="couponState && coupon" class="discount-amount-container">
      <span class="title">
        <span class="extra-margin">{{"dgoods_shop_discount" | localize | async}}</span>
        <span *ngIf="coupon.effectType === effectType.Percentage">{{coupon.effectValue.toFixed(0)}}%</span>
      </span>
      <span class="price">- {{currency}} {{coupon.discountAmount.toFixed(2)}}</span>
    </div>

    <div class="total-amount-container">
      <span class="title">{{"dgoods_shop_code_total_amount" | localize | async}}</span>
      <span class="price" *ngIf="!coupon">{{currency}} {{price?.toFixed(2)}}</span>
      <span class="price" *ngIf="coupon">{{currency}} {{coupon.discountPrice.toFixed(2)}}</span>
    </div>

    <div class="terms-conditions" *ngIf="product">

      <mat-checkbox *ngIf="product.enforceTC" name="enforceCheckbox" #enforceCheckbox  (click)="clickOnTerms($event)" >
        <span>{{"dgoods_shop_productbuyview_acceptTerms1" | localize | async}}</span>
        <span  class="link">{{"dgoods_shop_gtcs" | localize | async}} </span>
        <span style="display: inline-block;">{{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}} </span>
        <span class="bold margin-bottom" [innerHtml]="transformText(product.productLineCaption ? product.productLineCaption : product.productLineName)"></span>
        <span>.</span>
      </mat-checkbox>

      <span *ngIf="!product.enforceTC" class="product-notenforced-container">
        <span class="extra-margin">{{"dgoods_shop_accept_statement" | localize | async}}</span>
        <span (click)="clickOnTerms($event)" class="extra-margin link">{{"dgoods_shop_gtcs" | localize | async}}</span>
        <span class="extra-margin">{{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}}</span>
        <span class="margin-bottom" style="display: inline-block; " [innerHtml]="transformText(product.productLineCaption ? product.productLineCaption : product.productLineName)"></span>
        <span>.</span>
      </span>


      <mat-checkbox *ngIf="!twintTC" name="twintTermsCheckbox" #twintTermsCheckbox (change)="setGTC($event)">
        <span>{{"dgoods_shop_productbuyview_acceptTerms1" | localize | async}}</span>
        <a href="{{getAgbLink()}}" target="_blank">
          {{"dgoods_shop_gtc_and_privacy_policy" | localize | async}}
        </a>
        <span class="bold">{{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}}  {{"twint" | localize | async}}.</span>
      </mat-checkbox>
    </div>


    <div>
      <dgoods-button (click)="purchaseProduct()" [disabled]="disablePurchaseBtn">
        {{"dgoods_shop_productbuyview_buybutton" | localize | async }}
      </dgoods-button>
    </div>

</div>


