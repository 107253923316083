import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CategoryDto } from '@modeso/types__dgoods-products';

import Debug from 'debug';
import { Observable, Subscription } from 'rxjs';

const debug = Debug('dgoods:dgoods-products:CategorieFilterComponent');

@Component({
  selector: 'app-categorie-filter',
  templateUrl: './categorie-filter.component.html',
  styleUrls: ['./categorie-filter.component.scss']
})
export class CategorieFilterComponent implements OnInit, OnDestroy {

  @Input() allText = 'ALL';
  @Input() title: string = "Stores";
  @Input() language: string = "en-us";
  @Input() categories: CategoryDto[] = [];
  @Input() activeFilter: Observable<CategoryDto | string>;
  @Output() categoryClicked: EventEmitter<any> = new EventEmitter();

  activeCategory: any = null;
  activeFilterSubscription: Subscription;

  ngOnInit() {
    this.activeCategory = this.allText;

    this.activeFilterSubscription = this.activeFilter.subscribe(filter => {
      this.activeCategory = filter;
    });
  }

  ngOnDestroy() {
    if (this.activeFilterSubscription) {
      this.activeFilterSubscription.unsubscribe();
    }
  }

  onCategoryClick(category: CategoryDto | string) {
    debug("onCategoryClick", category)
    this.categoryClicked.emit(category);
  }

}
