import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductsService } from '../services/products.service';
import * as productsActions from './../actions/products.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:modeso-products:ProductsEffects');


@Injectable()
export class ProductsEffects {

  constructor(private actions$: Actions, private service: ProductsService) { }

  loadAllProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.getAllProducts.type),
      mergeMap(
        () => {
          return this.service.getAllProducts()
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                productsResponse => (productsActions.onAllProductsLoadedSuccessfully({ payload: productsResponse.products }))
              ),
              // tslint:disable-next-line:object-literal-shorthand
              catchError((error) => of(productsActions.onAllProductsLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnLoadAllProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.onAllProductsLoadingFailed.type),
      tap(
        (action: productsActions.ActionWithPayload<any>) => this.handleOnLoadAllProductsErrors(action.payload)
      )
    )
    , { dispatch: false });

    loadNewsTicker$ = createEffect(
      () => this.actions$.pipe(
        ofType(productsActions.getNewsTicker.type),
        mergeMap(
          (payload: { payload: string }) => {
            return this.service.getNewsTicker(payload.payload)
              .pipe(
                map(
                  (response) => {
                    return productsActions.onGetNewsTickerSucceeded({ payload: response });
                  },
                ),
                catchError((error) => {
                  return of(productsActions.onGetNewsTickerFailed({ payload: error }));
                }),
              );
          },
        ),
      ),
    );
    getAllContentPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.getAllContentPages),
      mergeMap(() =>
        this.service.getAllContentPages().pipe(
          map((response) =>
          productsActions.getAllContentPagesSuccess({ payload: response })
          ),
          catchError((error) =>
            of(productsActions.getAllContentPagesFailure({ payload: error }))
          )
        )
      )
    )
  );

  getSpotlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.getSpotlights.type),
      mergeMap(() =>
        this.service.getSpotlights().pipe(
          map((response) =>
           productsActions.getSpotlightsSuccessfully({ payload: response })
          ),
          catchError((error) =>
            of(productsActions.getSpotlightsFailed({ payload: error }))
            )
            )
          )
        )
    );

    getPublishedContentBox$ = createEffect(() =>
      this.actions$.pipe(
        ofType(productsActions.getPublishedContentBox),
        mergeMap((action) =>
          this.service.getPublishedContentBox(action.payload).pipe(
            map((response) =>
              productsActions.getPublishedContentBoxSuccess({ payload: response })
            ),
            catchError((error) =>
              of(productsActions.getPublishedContentBoxFailure({ payload: error }))
            )
          )
        )
      )
    );

  // handle success and update state
  getPublishedContentBoxSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(productsActions.getPublishedContentBoxSuccess),
        tap((action) => {
          debug(action);
        })
      ),
      { dispatch: false }
  );

  markSpotlightAsRead$ = createEffect(
    () => this.actions$.pipe(
      ofType(productsActions.markSpotlightAsRead.type),
      mergeMap(
        (payload: { payload: string }) => {
          return this.service.markSpotlightAsRead(payload.payload)
            .pipe(
              map(
                (response) => {
                  return productsActions.markSpotlightAsReadSuccessfully({ payload: response });
                },
              ),
              catchError((error) => {
                return of(productsActions.markSpotlightAsReadFailed({ payload: error }));
              }),
            );
        },
      ),
    ),
  );


  handleOnLoadAllProductsErrors(error) {
    debug(error);
    return error;
  }
}
