<order-confirmation-overlay2 class="terms-wrapper" [hidden]="!showOverlay" [ngStyle]="{'z-index': showOverlay ? '10000' : '0'}">
  <div *ngIf="getRedemtionInfo()" class="terms">

    <h3 [innerHtml]="getRedemtionInfoText()"></h3>

    <p [innerHtml]="getRedemtionInfo()"></p>
  </div>
  <div *ngIf="getAGB()" class="terms">
    <h3 [innerHtml]="getAGBText()">

    </h3>
    <p [innerHTML]="getAGB()">

    </p>
  </div>
  <div class="button-container">
    <div class="sticky">
      <dgoods-button [delegate]="this">
        {{ "dgoods_shared_termsofservicecompontent_conclude" | localize | async }}
      </dgoods-button>
    </div>
  </div>
</order-confirmation-overlay2>
<div class="dgoods-order-confirmation-container" [hidden]="showOverlay">

  <div class="image-container">
    <div class="image-wrapper"><img src={{productImage}} /></div>
  </div>
  <div>

    <div class="code-container">
      <order-confirmation-details2 injectedTitle="dgoods_shared_orderconfirmationcomponent_code"
        *ngIf="codeNumber" >
        <ng-content></ng-content>
      </order-confirmation-details2>
      <order-confirmation-details2 injectedTitle="dgoods_shared_orderconfirmationcomponent_code"
        *ngIf="getInProgress()" >
        <dgoods-dots-loading-indicator></dgoods-dots-loading-indicator>
        <div class="cell">
          <span class="cell-text-inprogress">
            <div>
              <div>
                {{ "dgoods_shared_orderconfirmationcomponent_inprogress" | localize | async }}
              </div>
            </div>
          </span>

        </div>

      </order-confirmation-details2>
      <order-confirmation-details2 title="DEBUG_STATUS" *ngIf="getDebugStatus()" >
        <div class="cell">
          <span class="cell-text-inprogress">
            <div>
              <div>
                {{ getDebugStatus() }}
              </div>
            </div>
          </span>

        </div>

      </order-confirmation-details2>
      <order-confirmation-details2 [title]="getCardText()" *ngIf="!getInProgress() && getCard()" >
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getCard())">
                {{getCard()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'CARD'">
            <span (click)="justFollowLink(getCard())" class="cell-text-info" *ngIf="getLink()">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getCard())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </order-confirmation-details2>
      <order-confirmation-details2 [title]="getCodeText()" *ngIf="!getInProgress() && getCode()" >
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getCode())">
                {{getCode()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'CODE'">
            <span (click)="justFollowLink(getCode())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getCode())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </order-confirmation-details2>
      <order-confirmation-details2 [title]="getSerialText()" *ngIf="!getInProgress() && getSerial()"
        >
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getSerial())">
                {{getSerial()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'SERIAL'">
            <span (click)="justFollowLink(getSerial())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getSerial())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </order-confirmation-details2>
      <order-confirmation-details2 [title]="getPinText()" *ngIf="!getInProgress() && getPin()" >
        <div class="cell">
          <span class="cell-text-code">
            <div>
              <div [class]="getTextSizeClass(getPin())">
                {{getPin()}}
              </div>
            </div>
          </span>
          <ng-container *ngIf="getDisplayLink() === 'PIN'">
            <span (click)="justFollowLink(getPin())" *ngIf="getLink()" class="cell-text-info">
              {{ getLinkText() }}
            </span>
            <span (click)="justCopy(getPin())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
              {{ getCopyOnlyText() }}
            </span>
          </ng-container>
        </div>
      </order-confirmation-details2>
      <order-confirmation-details2 [title]="getBarcodeText()" *ngIf="!getInProgress() && getBarcode()" >

        <div class="show-barcode">
          <canvas #barcodeCanvas style="max-width: 90%"></canvas>
         </div>

        <div class="cell">
          <span (click)="showScannableCode()" class="cell-text-info">
            {{ "dgoods_shared_orderconfirmationcomponent_scannablecode" | localize | async }}
          </span>
        </div>
      </order-confirmation-details2>
    </div>
  </div>
</div>


<div class="bordered" *ngIf="!getInProgress() && getPdf()">
  <order-confirmation-details2 [title]="''" *ngIf="!getInProgress() && getPdf()" >
    <div class="cell">
      <span class="cell-text-code">
        <div>
          <div class="pdf-container" (click)="followLink(getPdf())">
            <img class="pdf-icon" src="assets/svgs/light/pdf.svg" alt="" srcset="">
            <span class="cell-text-info no-margin-bottom">{{ getPdfText() }}</span>
          </div>
        </div>
      </span>
      <ng-container *ngIf="getDisplayLink() === 'PDF'">
        <span (click)="justFollowLink(getPdf())" *ngIf="getLink()" class="cell-text-info">
          {{ getLinkText() }}
        </span>
        <span (click)="justCopy(getPdf())" *ngIf="getCopy() && !getLink()" class="cell-text-info">
          {{ getCopyOnlyText() }}
        </span>
      </ng-container>
    </div>
  </order-confirmation-details2>
</div>


<div class="lower-section dgoods-order-confirmation-container">
  <div class="dgoods-order-confrimation-details-container " *ngIf="price">
    <div class="details">


      <span class="text4">Value:</span>
      <p class="text5">{{currency}} {{price | number : '1.2-2' }}</p>


      <div style="margin-top: 22px;">
        <span class="text4">{{"dgoods_shared_orderconfirmationcomponent_details" | localize | async}}</span>
        <div class="text5">
          <span *ngIf="discounts">{{currency}} {{discounts.totalAmount | number : '1.2-2' }}</span>
          <span *ngIf="!discounts">{{currency}} {{price | number : '1.2-2' }}</span>
        </div>

        <div style="margin-top: 16px;" *ngIf="discounts && couponRedeemStateEnum.confirmed === discounts.redeemState" class="apply-coupon-container">

          <div class="col1">
            <div class="text1">
              <span>1x {{caption? caption :productName}}</span>
            </div>
            <div class="text2">
              <span class="extra-margin">{{'dgoods_shop_add_code_placeholder' | localize | async}}</span>
              <span *ngIf="discounts.discountPercentage">(-{{discounts.discountPercentage.toFixed(0)}}%)</span></div>            <div class="text3">
              <span>{{'dgoods_shop_code_total_amount' | localize | async}}</span>
            </div>
          </div>

          <div class="col2">
            <div class="text1">{{currency}} {{price | number : '1.2-2' }}</div>
            <div class="text2">-{{currency}} {{ discounts.discountAmount | number : '1.2-2' }}</div>
            <div class="text3">{{currency}} {{ discounts.totalAmount | number : '1.2-2' }}</div>
          </div>

        </div>

        <span>
          <span class="text6" [innerHtml]="transformText(caption ? caption :productName)" style="display: inline-block;"></span>
          <span>,</span>
          <span class="text6">{{date | date:' dd.MM.yyyy, HH:mm'}}</span>
        </span>


        <div (click)="openInstructions()" class="instruction">
          {{ "dgoods_shared_orderconfirmationcompontent_instructions" | localize | async }}
        </div>
        <div class="email" *ngIf="email">
          <span class="email-text">{{ "dgoods_shared_orderconfirmationcompontent_email" | localize | async }}</span>
          <span> {{email}}</span>
        </div>

     </div>
    </div>

  </div>

</div>
<div class="extra-text">

  <order-confirmation-details2  *ngIf="!getInProgress() && getExtraText()" >
    <div class="extra-text-margin">
        <div class="extra-text-fonts" [innerHtml]="getExtraText()">
        </div>
    </div>
  </order-confirmation-details2>
</div>



<dgoods-overlay-new [hidden]="!copyOverlay" [error]="false" [progress]="false" [html]="true" [copyOverlay]="true">
  {{ "dgoods_shared_orderconfirmationcompontent_copyconfirmationmsg" | localize | async }}
</dgoods-overlay-new>

      <dgoods-overlay-new *ngIf="!getInProgress() && getBarcode()"
      [hidden]="hideScannableCode"
      [error]="false"
      [progress]="false"
      [html]="false"
      [scannableCode]="true"
      [delegate]="this"
      backgroundColor="white"
      class="scancodeOverlay">

           <div class="barcode">
            <canvas #showBarcodeCanvas style="max-width: 95%"></canvas>
           </div>

      </dgoods-overlay-new>
