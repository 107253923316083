import { createAction, props, Action } from '@ngrx/store';
import { LocalizationModel } from '../models/localization.model';

export const getLocalizations = createAction('[Localization] getLocalizations');
export const onLocalizationsLoadedSuccessfully = createAction(
  '[Localization] all localizations loaded successfully',
  props<{ payload: Array<LocalizationModel> }>()
);
export const onLocalizationsLoadingFailed = createAction(
  '[Localization] all localizations loading failed',
  props<{ payload: any }>()
);
export const getLocalizationByKey = createAction('[Localization] getLocalizationByKey', props<{ key: string }>());
export const onLocalizationByKeyLoadedSuccessfully = createAction(
  '[Localization] LocalizationByKey loaded successfully',
  props<{ payload: LocalizationModel }>()
);
export const onLocalizationByKeyLoadingFailed = createAction(
  '[Localization] LocalizationByKey loading failed',
  props<{ payload: any }>()
);

export const onActivateDebugMode = createAction('[Localization] activate debug mode', props<{ payload: boolean }>());

export const getLocalizationsForAllLanguages = createAction('[Localization] getLocalizationsForAllLanguages');
export const onLocalizationsForAllLanguagesLoadedSuccessfully = createAction(
  '[Localization] onLocalizationsForAllLanguagesLoadedSuccessfully',
  props<{ payload: Array<any> }>()
);
export const onLocalizationsForAllLanguagesLoadingFailed = createAction(
  '[Localization] onLocalizationsForAllLanguagesLoadingFailed',
  props<{ payload: any }>()
);
export const updateLocalization = createAction(
  '[Localization] updateLocalization',
  props<{ payload: { key: string; updatedTranslations: { [key: string]: string } } }>()
);
export const onUpdateLocalizationSuccessfully = createAction(
  '[Localization] onUpdateLocalizationSuccessfully',
  props<{ payload: any }>()
);
export const onUpdateLocalizationFailed = createAction(
  '[Localization] onUpdateLocalizationFailed',
  props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export interface ActionWithContentId extends Action {
  key: string;
}
