import { DiscountDTO } from '@modeso/types__twint-lib-coupons';
import { ProductLineImage } from '@modeso/types__dgoods-products';

export interface IDGoodsTableDataSource {
  title: string;
  reference: string;
  date: Date;
  link: string;
  amount: number;
  productLineImages: ProductLineImage[];
  //optional
  discounts?: DiscountDTO;
  caption?: string;


  compare(a: IDGoodsTableDataSource, b: IDGoodsTableDataSource): number;
}

export class DGoodsTableDataSource implements IDGoodsTableDataSource {

  constructor(public title: string, public reference: string, public date: Date,
              public link: string, public amount: number, public productLineImages: ProductLineImage[], public discounts?: DiscountDTO,
              public caption?: string) { }


  public compare(a: IDGoodsTableDataSource, b: IDGoodsTableDataSource) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }
}

export interface IDGoodsTableSection {
  title: string;
  index: number;
  datasource: IDGoodsTableDataSource[];
}

export class DGoodsTableSection implements IDGoodsTableSection {
  constructor(public title: string, public index: number, public datasource: IDGoodsTableDataSource[]) { }
}


export abstract class MonthAndYear {
  month: string;
  year: string;
}
