import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private landingPageLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public landingPageLoading$: Observable<boolean> = this.landingPageLoadingSubject.asObservable();

  setlandingPageLoading(loading: boolean) {
    this.landingPageLoadingSubject.next(loading);
  }

  private spotlightsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public spotlightsLoading$: Observable<boolean> = this.spotlightsLoadingSubject.asObservable();

  setSpotlightsLoading(loading: boolean) {
    this.spotlightsLoadingSubject.next(loading);
  }

  private ordersHistoryLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ordersHistoryLoading$: Observable<boolean> = this.ordersHistoryLoadingSubject.asObservable();

  setOrdersHistoryLoading(loading: boolean) {
    this.ordersHistoryLoadingSubject.next(loading);
  }



}
