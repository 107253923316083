<div [hidden]="hiddenDataProtection" class="overlay">
  <dgoods-terms-of-service [delegate]="this">
    <div class="content">
      <span>{{"data_protection_of_twint" | localize | async}}</span>
    </div>
    <!-- <div class="redeem-container">
      <div class="redeem-title">{{"redeem" | localize | async}}</div>
      <div class="redeem-content">
        <span>1){{"redeem_content1" | localize | async}}</span>
        <span>2){{"redeem_content2" | localize | async}}</span>
      </div>
    </div> -->
  </dgoods-terms-of-service>
</div>
