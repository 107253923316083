import { Injectable } from '@angular/core';
​
@Injectable()
export class AmountLimitExceededService {

    amount: number;
    annualAmount: number;

    constructor() {}

    setAmount(data){
        this.amount = data
    }
    setAnnualAmount(data){
        this.annualAmount = data
    }
    getAmount(){
        return this.amount;
    }
    getAnnualAmount(){
        return this.annualAmount;
    }
}
