
<form [formGroup]="emailForm">

    <div class="input_inactive">
      <mat-form-field class="mat-form-field-display" appearance="outline">

        <mat-label>{{"dgoods_email_title" | localize | async}}
          <span class="required-text"> * </span>
        </mat-label>

        <input matInput formControlName="email" type="email" required #emailInput
        placeholder="{{ 'email' | localize | async }}" (click)="enableCloseBtn(true,'email')" (blur)="enableCloseBtn(false,'email')" />

        <a (mousedown)="$event.preventDefault()" class="close-btn" *ngIf="isEmailCancelBtnEnabled"  matSuffix mat-icon-button aria-label="Clear" (click)="email.reset()">
          <mat-icon class="mat-icon-cancel">cancel</mat-icon>
        </a>

      </mat-form-field>

      <mat-error *ngIf="(email.invalid && email.touched)">{{ emailErrorMessage | localize | async }}</mat-error>

    </div>

    <div class="input_inactive">

      <mat-form-field class="mat-form-field-display" appearance="outline">

        <mat-label>{{"confirm_email_address" | localize | async}}
          <span class="required-text"> * </span>
        </mat-label>

        <input matInput  formControlName="confirmEmail" type="email" required
        placeholder="{{ 'dgoods_email_confirmation_placeholder' | localize | async }}"
        (click)="enableCloseBtn(true,'confirmEmail')" (blur)="enableCloseBtn(false,'confirmEmail')" />

        <a (mousedown)="$event.preventDefault()" class="close-btn" *ngIf="isConfirmEmailCancelBtnEnabled"  matSuffix mat-icon-button aria-label="Clear" (click)="confirmEmail.reset()">
          <mat-icon class="mat-icon-cancel">cancel</mat-icon>
        </a>

      </mat-form-field>

      <mat-error *ngIf="(confirmEmail.invalid && confirmEmail.touched)">{{ confirmEmailErrorMessage | localize | async }}</mat-error>

    </div>

  </form>
