<div class="dgoods-order-confrimation-details-container">
  <div class="title" *ngIf="injectedTitle">
    {{injectedTitle | localize | async}}
  </div>
  <div class="title" *ngIf="title">
    {{title}}
  </div>
  <ng-content></ng-content>

</div>
