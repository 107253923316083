import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgoods-order-confirmation-details',
  templateUrl: './dgoods-order-confirmation-details.component.html',
  styleUrls: ['./dgoods-order-confirmation-details.component.scss']
})
export class DgoodsOrderConfirmationDetailsComponent {
  @Input() title?: string;
  @Input() injectedTitle?: string;

  constructor() { }



}
