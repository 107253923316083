import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CartConfigService } from './../cart.config';
import { share } from 'rxjs/operators';
import { CheckoutResponse, GetPurchasedOrdersResponse, GetOrderResponse } from '@modeso/types__dgoods-cart';
import {CouponValidationDTO, ICodeValidityCheckRequest} from '@modeso/types__twint-lib-coupons';
import { Observable } from 'rxjs';
import { Cart } from '../models/domain.models';
import { ICartService } from './cart.service.interface';

@Injectable({
  providedIn: 'root',
})
export class CartService implements ICartService{
  private server = '';
  private microserviceName = 'cart';
  private checkout = 'checkout';
  private couponState = 'coupons/state';
  private readonly route = '/';
  private readonly codeValidity = 'coupons/validateCode';

  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(CartConfigService) private config) {
    this.server = config.apiGateway;
  }
  /**
   * service to post cart.
   * @param cart
   */
  postCart(cart: Cart): Observable<CheckoutResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.checkout}`;
    return this.http.post<CheckoutResponse>(url, cart);
  }

  getOrderByUserId() {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.get<GetPurchasedOrdersResponse>(url);
  }

  getOrderByReceiptUrl(receiptURL: string) {
    const url = `${this.server}${this.microserviceName}${this.route}${receiptURL}`;
    return this.http.get<GetOrderResponse>(url).pipe(share());
  }

  getCouponState() {
    const url = `${this.server}${this.microserviceName}${this.route}${this.couponState}`;
    return this.http.get<any>(url);
  }

  checkCodeValidity(body:ICodeValidityCheckRequest): Observable<CouponValidationDTO>{
    const url = `${this.server}${this.microserviceName}${this.route}${this.codeValidity}`;
    return this.http.post<CouponValidationDTO>(url,body);
  }

}
