import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DgoodsAnalyticsConfigService } from '../dgoods.analytics.config';
import { ISavePageViewRequest, ISavePageViewResponse } from '../models/interfaces/savePage.interface';

@Injectable({
  providedIn: 'root',
})
export class DgoodsAnalyticsService {
  private server = '';
  private microserviceName = 'analytics';
  private readonly route = 'pageview';

  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(DgoodsAnalyticsConfigService) private config) {
    this.server = config.apiGateway;
  }

  savePageView(body: ISavePageViewRequest) {
    const url = `${this.server}${this.microserviceName}/${this.route}`;
    return this.http.post<ISavePageViewResponse>(url, body);
  }


}
