import { Component, Input, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { IDGoodsButtonDelegate, IDGoodsOverlayDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { ClipboardService } from 'ngx-clipboard';
import { ProductConfiguration } from '../../models/domain.models';
import { CouponRedeemStateEnum ,DiscountDTO } from '@modeso/types__twint-lib-coupons';

const debug = Debug('dgoods:shared:DgoodsOrderConfirmationComponent');
@Component({
  selector: 'dgoods-order-confirmation',
  templateUrl: './dgoods-order-confirmation.component.html',
  styleUrls: ['./dgoods-order-confirmation.component.scss']
})
export class DgoodsOrderConfirmationComponent implements IDGoodsButtonDelegate, IDGoodsOverlayDelegate {

  orderStatuses = ['ORDER_SUCCESS', 'PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED', 'PAYMENT_SUCCESS'];
  failedStatuses = ['PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED'];

  @Input() productImage: string;
  @Input() codeNumber?: string;
  @Input() productConfiguration?: ProductConfiguration;
  @Input() currency: string;
  @Input() price: number;
  @Input() productName: string;
  @Input() date: string;
  @Input() email: string;
  @Input() status: string;
  @Input() isInProductionMode: string;
  @Input() discounts?: DiscountDTO;

  @ViewChild('barcode', { static: false }) barcode: ElementRef;

  showOverlay = false;
  copyOverlay = false;
  constructor(private clipboardService: ClipboardService, private renderer: Renderer2) {
  }

  public hideScannableCode = true;

  showScannableCode() {
    debug('showScannableCode');
    this.hideScannableCode = !this.hideScannableCode;
  }

  followLinkAndCopy(value) {
    debug('followLinkAndCopy ' + value);
    this.clipboardService.copyFromContent(value);

    setTimeout(() => {
      window.open(this.getCopy(), '_blank');
    }, 100);
  }
  justFollowLink(value) {
    debug('followLink ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);
    setTimeout(() => {
      window.open(this.getLink(), '_blank');
    }, 600);

  }
  justCopy(value) {
    debug('Copy ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);

  }
  openInstructions() {
    this.showOverlay = true;
  }
  getTextSizeClass(text: string) {
    if (text.length <= 13) {
      return 'bigText';
    } else if (text.length <= 16) {
      return 'mediumText';
    } else if (text.length <= 19) {
      return 'smallText';
    } else {
      return 'tinyText';
    }
  }
  getInProgress() {
    return !this.status || !this.orderStatuses.includes(this.status);
  }

  getDebugStatus() {
    if (!this.isInProductionMode && this.failedStatuses.includes(this.status)) {
      return this.status;
    }
    return undefined;
  }
  addVariableToLink(link: string){
    const variable = this.productConfiguration.config['VARIABLE'];
    return link.replace('{{code}}', variable);
  }
  getLink() {
    if (!this.productConfiguration) { return undefined; }
    const link = this.productConfiguration.config['LINK'];
    return this.productConfiguration.config['VARIABLE']? this.addVariableToLink(link): link;
  }
  getDisplayLink() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['DISPLAYLINK'];
  }
  getLinkText() {
    if (!this.productConfiguration) { return undefined; }

    return this.productConfiguration.text['LINK'];
  }
  getCopy() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['COPY'];
  }
  getCopyText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['COPY'];
  }
  getCopyOnlyText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['COPYONLY'];
  }
  getCode() {
    if (!this.productConfiguration) { return undefined; }

    return this.productConfiguration.config['CODE'];
  }
  getCodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['CODE'];
  }
  getSerial() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['SERIAL'];
  }
  getSerialText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['SERIAL'];
  }
  getCard() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['CARD'];
  }
  getCardText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['CARD'];
  }
  getBarcode() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['EAN'];
  }
  getBarcodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EAN'];
  }
  getPin() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['PIN'];
  }
  getPinText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['PIN'];
  }
  getAGB() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['AGB'];
  }
  getAGBText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['AGB'];
  }
  getRedemtionInfo() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['REDEMPTION_INFORMATION'];
  }
  getRedemtionInfoText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['REDEMPTION_INFORMATION'];
  }
  getExpiresInfo() {
    debug("getExpiresInfo");
    debug(this.productConfiguration.config['EXPIRES']);
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['EXPIRES'];
  }
  getExpiresInfoText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EXPIRES'];
  }
  getExpiresInfoDate() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EXPIRES'];
  }
  getExtraText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EXTRATEXT'];
  }
  onButtonClicked(sender: DGoodsButtonComponent): void {
    this.showOverlay = false;
  }

  onOverlayButtonClicked(): void {
    this.hideScannableCode = true;
  }

  public get couponRedeemStateEnum(): typeof CouponRedeemStateEnum {
    return CouponRedeemStateEnum;
  }

}

