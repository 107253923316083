import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/dgoods.user.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { DgoodsUserService } from '../services/dgoods.user.service';
import { Router } from '@angular/router';
const debug = Debug('modeso:twint-lib-user-fe:UserEffects');


@Injectable()
export class UserEffects {

  constructor(private actions$: Actions, private service: DgoodsUserService, private router: Router) { }

  onUpdateUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateUser.type),
      mergeMap(
        (payload: any) => {
          return this.service.updateUserEmail(payload.payload)
            .pipe(
              retry(1),
              tap(response => debug(response.email)
              ),
              map(
                response => (fromActions.onUpdateUserSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onUpdateUserFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetSweepayPhoneNumber.type),
      mergeMap(
        () => {
          return this.service.getUserPhoneNumber()
            .pipe(
              retry(1),
              tap(response => debug(response)
              ),
              map(
                response => (fromActions.onGetSweepayPhoneNumberSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onGetSweepayPhoneNumberFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetLookUpAccount$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLookUpAccount.type),
      mergeMap(
        (payload: any) => {
          return this.service.lookupAccount()
            .pipe(
              // retry(1),
              tap(response => debug(response),
              ),
              map(
                response => (fromActions.onLookupAccountSuccess({ payload: response})),
              ),
              catchError((error) => of(fromActions.onLookupAccountFailed({ payload: error })))
            );
        }
      )
    )
  );

  onUpdateSweepayAccountWithPhoneNo$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateSweepayAccountWithPhoneNo.type),
      mergeMap(
        (payload: any) => {
          return this.service.updateSweepayAccountWithPhoneNo(payload.payload)
            .pipe(
              // retry(1),
              tap(response => debug(response)
              ),
              map(
                response => (fromActions.onUpdateSweepayAccountWithPhoneNoSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onUpdateSweepayAccountWithPhoneNoFailed({ payload: error })))
            );
        }
      )
    )
  );

  onResendOtpSweepayAccount$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onResendOtpSweepayAccount.type),
      mergeMap(
        (payload: any) => {
          return this.service.resendSweepayOTP(payload.payload)
            .pipe(
              // retry(1),
              tap(response => debug(response)
              ),
              map(
                response => (fromActions.onResendOtpSweepayAccountSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onResendOtpSweepayAccountFailed({ payload: error })))
            );
        }
      )
    )
  );

  onValidateSweepayOtp$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onValidateSweepayOtp.type),
      mergeMap(
        (payload: any) => {
          return this.service.validateSweepayOtp(payload.payload)
            .pipe(
              // retry(1),
              tap(response =>{
                debug(response)
                if(response.mockIntegration) {
                  localStorage.removeItem('email');
                }
              }
              ),
              map(
                response => (fromActions.onValidateSweepayOtpSuccess({ payload: response})),
              ),
              catchError((error) => of(fromActions.onValidateSweepayOtpFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnUpdateUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateUserFailed.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });
    errorOnSweePayLookupAccount$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.onLookupAccountFailed.type),
        tap(
          (action: fromActions.IActionWithPayload<any>) => {
            this.router.navigate(['de-ch/error'], { queryParams : {canUpgradeLimit: false }});
          }
        )
      )
      , { dispatch: false });
  
  handleOnLoadAllCartErrors(error) {
    debug(error);
    return error;
  }
}
