<div class="dgoods-table-cell">


  <div class="dgoods-table-cell-inner-col1">
    <img class="image" [src]="getImage().frontImage" alt={{datasource.title}}/>
    <div class="dgoods-table-cell-inner-col2">
      <span [class]="'dgoods-table-cell-title first-text' + getCssClassDiscount()" [innerHtml]="transformText(datasource.caption ? datasource.caption : datasource.title)"></span>

      <div [class]="'dgoods-table-cell-date-reference second-text' + getCssClassDiscount()">
        <span *ngIf="datasource.discounts"  style="margin-right: 2px;">{{'dgoods_shop_add_code_placeholder' | localize | async}}</span>
        <span *ngIf="datasource.discounts?.discountPercentage">(-{{datasource.discounts.discountPercentage.toFixed(0)}}%)</span>
        <span class="reference">{{ "dgoods_shared_tablecellcompontent_reference" | localize | async }}
          {{datasource.reference | slice:0:8}}</span>
        <span>{{datasource.date | date:'dd.MM.yyyy, HH:mm' }}</span>
      </div>

    </div>
  </div>

    <div class="dgoods-table-cell-inner-col3">

      <span [class]="'dgoods-table-cell-amount first-text' + getCssClassDiscount()"> CHF {{datasource.amount | number:'2.2-2'}}</span>

      <div class="dgoods-table-cell-date-reference">
        <div [class]="'dgoods-table-cell-discount second-text' +getCssClassDiscount()" *ngIf="datasource.discounts">-CHF {{ datasource.discounts.discountAmount | number : '1.2-2' }}</div>
        <div [class]="'dgoods-table-cell-link second-text' +getCssClassDiscount()" (click)="onClick()">{{ "dgoods_shared_tablecellcompontent_code" | localize | async }}</div>
      </div>

    </div>



</div>
