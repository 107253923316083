import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from './replace.pipe';
import { AmountLimitExceededService } from './services/amountLimitExceededService.service';
import { SafeHtmlPipeImages } from './pipes/safeHtml.pipe';
import { MarkdownImagesPipe } from './pipes/markdown.pipe';
import { SafeHtmlAllowUrlPipe } from './pipes/safeHtmlAllowUrl.pipe';

@NgModule({
    declarations: [
      ReplacePipe,
      SafeHtmlPipeImages,
      MarkdownImagesPipe,
      SafeHtmlAllowUrlPipe
    ],
    providers: [
      AmountLimitExceededService,
      SafeHtmlPipeImages,
      MarkdownImagesPipe,
      SafeHtmlAllowUrlPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [ReplacePipe, MarkdownImagesPipe, SafeHtmlPipeImages]
})
export class SharedModule { }
