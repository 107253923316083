<div class="email-container">
  <div class="extra-margin">
    <span class="title">{{'dgoods_shop_email_title' | localize | async }}</span>
    <span class="link" (click)="redirectToMyAccount()">{{'dgoods_shop_edit_btn' | localize | async }}</span>
  </div>

  <div>
    <span class="email-address">{{userEmail}}</span>
  </div>
</div>
