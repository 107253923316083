import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { FooterComponent } from './skeleton/footer/footer.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BasePageComponent } from './pages/base.page';
import { SharedModule } from './shared/shared.module';
import * as fromAppState from './state/app.state.reducer';
import { AppEffects } from './state/app.state.effects';
import { FakeCheckinPage } from './pages/FakeCheckin/fakeCheckin.page';
import { DgoodsSessionModule, DgoodsSessionConfig, AuthHandler, AUTH_INTERCEPTOR } from '@modeso/twint-lib-session-fe';
import { ModesoLocalizationConfig, ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { CartModule } from '@modeso/dgoods-lib-cart-fe';
import { DgoodsSharedModule , MarkdownPipe, SafeHtmlPipe , ModesoCoreModule} from '@modeso/twint-lib-core-fe';
import { PurchasingHistoryPage } from './pages/PurchaseHistory/purchasing-history.page.view';
import { OneTimeUrlStartPage } from './pages/OneTimeUrlStart/onetimeurlStart.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './shared/services/guards/AuthGuard.service';
import { LocalStorageService } from './shared/services/localSrorage.service';
import { LanguageHandler } from '@modeso/modeso-lib-core-fe';
import { SessionOrchestrator } from './orchestrator/session.orchestrator';
import { OrderConfirmationPage } from './pages/OrderConfirmation/order-confirmation.page.view';
import { ErrorPage } from './pages/ErrorPage/error.page.view';
import { NavigationAuthGuard } from './shared/services/guards/NavigationAuthGuard.service';
import { LoginPage } from './pages/login/login.page';
import { PortraitModePage } from './pages/PortraitMode/portrait-mode.page.view';
import { DgoodsAnalyticsConfig, DgoodsAnalyticsModule } from '@modeso/twint-lib-analytics-fe';
import { CookieService } from 'ngx-cookie-service';
import { TermsOfProviderPage } from './pages/TermsOfProvider/terms-of-provider.controller';
import { TermsOfTwintPageControllerDirective } from './pages/TermsOfTwint/terms-of-twint.controller';
import { TermsOfTwintPage } from './pages/TermsOfTwint/terms-of-twint.page.view';
import { DataProtectionOfTwintPage } from './pages/DataProtectionOfTwint/data-protection-of-twint.page.view';
import { DataProtectionOfTwintPageControllerDirective } from './pages/DataProtectionOfTwint/data-protection-of-twint.controller';
import { PurchasingHistoryPageControllerDirective } from './pages/PurchaseHistory/purchasing-history.page.controller';
import { LandingBuyPageController } from './pages/Landing/landing-buy.page.controller';
import { OrderConfirmationControllerDirective } from './pages/OrderConfirmation/order-confirmation.page.controller';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { PaymentCanceldPage } from './pages/PaymentCanceldPage/paymentcanceld.page.view';
import { FAQPage } from './pages/FAQ/faq.page';
import { MaintenancePage } from './pages/Maintainance/maintenance.page';
import { MaintenenceGuard } from './shared/services/guards/MaintenenceGuard.service';
import { NoMaintenenceGuard } from './shared/services/guards/NoMaintenenceGuard.service';
import { ProductsModule, ProductsConfig } from '@modeso/dgoods-lib-products-fe';
import { DgoodsUserConfig, DgoodsUserModule } from '@modeso/dgoods-lib-user-fe';
import { SweepayPhoneNumberPage } from './pages/Sweepay/PhoneNumber/sweepay-phone-number.view';
import { SweepayOTPComponent } from './pages/Sweepay/OTP/sweepay-otp.view';
import { RedirctPaymentscreenComponent } from './components/redirct-paymentscreen/redirctPaymentscreen.component.view';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentRefundErrorPage } from './pages/paymentRefundErrorPage/payment-refund-error-page.view';
import { NewsTickerComponent } from './pages/NewsTicker/news-ticker.component';
import { CouponComponent } from './components/Coupon/coupon.component';
import { HamburgerMenuComponent } from './components/HamburgerMenu/hamburger-menu.component';
import { VoucherListComponent } from './components/VoucherList/voucher-list.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CheckoutFlyoutComponent } from './components/CheckoutFlyout/checkout-flyout.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PriceComponent } from './components/PriceList/Price/price.component';
import { PriceListComponent } from './components/PriceList/price-list.component';
import { DenominatedPriceDialog } from './components/Dialogs/DenominatedPriceDialog/denominated-price.dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductDetailsPage } from './pages/ProductDetails/product-details.page.controller';
import { ExpandableTextComponent } from './components/ExpandableText/expandable-text.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserEmailDisplayComponent } from './components/UserEmailDisplay/user-email-display.component';
import { CreateOrUpdateEmailAddressComponent } from './components/CreateOrUpdateEmailAddress/create-update-email-address.component';
import { MatIconModule } from '@angular/material/icon';
import { AccountPage } from './pages/Account/account.page.controller';
import { EmailRegisterationPage } from './pages/RegisterEmailInCheckoutFlow/email-registeration.page.controller';
import { ProductCheckoutComponent } from './components/ProductCheckout/product-checkout.component';

import {DGoodsOrderConfirmationOverlayComponent2} from './components/OrderConfirmationDetails/\
order-confirmation-overlay2/order-confirmation-overlay.component2';
import {DgoodsOrderConfirmationDetailsComponent2} from './components/OrderConfirmationDetails/\
order-confirmation-details2/order-confirmation-details.component2';
import {DgoodsOrderConfirmationComponent2} from './components/OrderConfirmationDetails/\
order-confirmation.component2';

import {DGoodsTableComponent2} from './components/VoucherList/dgoods-table/dgoods-table.component2';
import {DGoodsTableCellComponent2} from './components/VoucherList/dgoods-table/dgoods-table-cell.component2';
import {DgoodsNewstickerComponent2} from './components/dgoods-newsticker2/dgoods-newsticker.component2';
import { ContentPagesPage } from './pages/contentpage/contentpage.page';
import { AutoFocus } from './shared/directive/autofocus.directive';
import { SpotlightListComponent } from './components/SpotlightList/spotlight-list.component';
import { SpotlightComponent } from './components/SpotlightList/Spotlight/spotlight.component';

import { ContentBoxComponent } from './components/ContentBox/contentbox.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PreventVerticalScrollDirective } from './shared/directive/scroll.directive';
import { LoadingSpinnerComponent } from './components/LoadingSpinner/loading-spinner.component';

const config: DgoodsSessionConfig = {
  apiGateway: environment.modeso.apiGateway,
};

const productsConfig: ProductsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'products'
};

const cartConfig: ProductsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'cart'
};

const localizationServiceConfig: ModesoLocalizationConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'localization'
};

const userConfig: DgoodsUserConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'users'
};

const analyticsConfig: DgoodsAnalyticsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'analytics'
};

@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    HeaderComponent,
    FooterComponent,
    ContentAreaComponent,
    PurchasingHistoryPage,
    LoginPage,
    EmailRegisterationPage,
    OrderConfirmationPage,
    FakeCheckinPage,
    OneTimeUrlStartPage,
    ErrorPage,
    PaymentCanceldPage,
    PortraitModePage,
    FAQPage,
    PurchasingHistoryPageControllerDirective,
    AccountPage,
    OrderConfirmationControllerDirective,
    TermsOfProviderPage,
    TermsOfTwintPageControllerDirective,
    TermsOfTwintPage,
    LandingBuyPageController,
    DataProtectionOfTwintPage,
    DataProtectionOfTwintPageControllerDirective,
    MaintenancePage,
    SweepayPhoneNumberPage,
    SweepayOTPComponent,
    RedirctPaymentscreenComponent,
    PaymentRefundErrorPage,
    NewsTickerComponent,
    CouponComponent,
    HamburgerMenuComponent,
    VoucherListComponent,
    CheckoutFlyoutComponent,
    DgoodsOrderConfirmationDetailsComponent2,
    DGoodsOrderConfirmationOverlayComponent2,
    DgoodsOrderConfirmationComponent2,
    DGoodsTableComponent2,
    DGoodsTableCellComponent2,
    CheckoutFlyoutComponent,
    PriceComponent,
    PriceListComponent,
    DenominatedPriceDialog,
    ProductDetailsPage,
    ExpandableTextComponent,
    UserEmailDisplayComponent,
    CreateOrUpdateEmailAddressComponent,
    ProductCheckoutComponent,
    DgoodsNewstickerComponent2,
    AutoFocus,
    ContentPagesPage,
    SpotlightListComponent,
    SpotlightComponent,
    ContentBoxComponent,
    PreventVerticalScrollDirective,
    LoadingSpinnerComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ClipboardModule,
    ModesoLocalizationModule.forRoot(localizationServiceConfig),
    ModesoLocalizationModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot([]),
    StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([AppEffects]),
    DgoodsSessionModule.forRoot(config),
    ProductsModule.forRoot(productsConfig),
    CartModule.forRoot(cartConfig),
    DgoodsUserModule.forRoot(userConfig),
    DgoodsAnalyticsModule.forRoot(analyticsConfig),
    DgoodsSharedModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    ModesoCoreModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    NgxBarcodeModule

  ],
  providers: [
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageHandler, multi: true },
    MaintenenceGuard,
    NoMaintenenceGuard,
    AuthGuard,
    NavigationAuthGuard,
    LocalStorageService,
    CookieService,
    ClipboardService,
    SafeHtmlPipe,
    MarkdownPipe,

  ],
  bootstrap: [AppComponent],
  entryComponents: [DenominatedPriceDialog]
})
export class AppModule { }
