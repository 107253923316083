import { HttpErrorResponse } from '@angular/common/http';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { CategoryDto, INewstickerResponse, ISpotLight, ProductLineDto } from '@modeso/types__dgoods-products';
import { createAction, props, Action } from '@ngrx/store';

export const getAllProducts = createAction('[Product List] getAllProducts');

export const onAllProductsLoadedSuccessfully = createAction(
  '[Product List] all products loaded successfully',
  props<{ payload: Array<ProductLineDto> }>()
);

/**
 * create an Action of get product by id
 * check https://ngrx.io/guide/store/actions
 * @param1 handle of the action with ngrx convention
 * @param2 props method is used to define any additional metadata
 *         needed for the handling of the action
 */
export const getProductById = createAction(
  '[Product List] getProductById',
  props<{ productId: string }>()
);

export const onAllProductsLoadingFailed = createAction(
  '[Product List] all products loading failed',
  props<{ payload: any }>()
);

export const getNewsTicker = createAction(
  '[TDV get News Ticker] getNewsTicker request',
  props<{ payload: string }>()
);

export const onGetNewsTickerSucceeded = createAction(
  '[TDV get News Ticker] getNewsTicker success',
  props<{ payload: INewstickerResponse }>()
);

export const onGetNewsTickerFailed = createAction(
  '[TDV get News Ticker] getNewsTicker fail',
  props<{ payload: any }>()
);

export const getActiveFilter = createAction(
  '[TDV Categories] getActiveFilter',
  props<{ payload: string }>()
);

export const setActiveFilter = createAction(
  '[TDV Categories] setActiveFilter',
  props<{ payload:  CategoryDto | string }>()
);

export const getAllContentPages = createAction('[Admin Content Pages] Get All Content Pages');

export const getAllContentPagesSuccess = createAction(
  '[Admin Content Pages] Get All Content Pages Success',
  props<{ payload: IContentPageResponse[] }>()
);

export const getAllContentPagesFailure = createAction(
  '[Admin Content Pages] Get All Content Pages Failure',
  props<{ payload: HttpErrorResponse }>()
);

export const getSpotlights = createAction('[Get Spotlights] Get Spotlights');

export const getSpotlightsSuccessfully = createAction(
  '[Get Spotlights] Get Spotlights Successfully',
  props<{ payload: {spotlights: ISpotLight[], userHasSpotlights: boolean} }>()
);

export const getSpotlightsFailed = createAction(
  '[Get Spotlights] Get Spotlights Failed',
  props<{ payload: HttpErrorResponse }>()
);

export const markSpotlightAsRead = createAction(
  '[Mark Spotlights] Mark Spotlights As Read',
  props<{ payload: string }>()
);

export const markSpotlightAsReadSuccessfully = createAction(
  '[Mark Spotlights] Mark Spotlights As Read Succeeded',
  props<{ payload: any }>()
);

export const markSpotlightAsReadFailed = createAction(
  '[Mark Spotlights] Mark Spotlights As Read Failed',
  props<{ payload: HttpErrorResponse }>()
);


export const getPublishedContentBox = createAction('[Admin Content Box] Get Published Content Boxes', props<{ payload: string }>());

export const getPublishedContentBoxSuccess = createAction(
  '[Admin Content Box] Get Published Content Boxes Success',
  props<{ payload: IContentBoxResponse }>()
);

export const getPublishedContentBoxFailure = createAction(
  '[Admin Content Box] Get Published Content Boxes Failure',
  props<{ payload: HttpErrorResponse }>()
);


export interface ActionWithPayload<T> extends Action {
  payload: T;
}
