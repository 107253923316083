import { ChangeDetectorRef, Component, Injector, OnInit, Output } from '@angular/core';
import { UserProvider } from '@modeso/dgoods-lib-user-fe';
import { take , takeUntil } from 'rxjs/operators';
import debug from 'debug';
import { BasePageComponent } from '../../base.page';
import { parsePhoneNumber, isValidPhoneNumber,ParseError} from 'libphonenumber-js';
import mobile from "libphonenumber-js/mobile"
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItemStates } from '../../../components/HamburgerMenu/hamburger-menu.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AppStateDispatcher } from '../../../state/app.state.dispatcher';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-sweepay-phone-number',
  templateUrl: './sweepay-phone-number.view.html',
  styleUrls: ['./sweepay-phone-number.view.scss']
})
export class SweepayPhoneNumberPage  extends BasePageComponent implements OnInit  {

  phoneForm:FormGroup;
  value=''
  parsePhone;
  formatPhoneNumber;
  isMsgDisappear = true
  errorMsg :string
  type;
  isValidPhone = false;
  disablePurchaseBtn = true;


  menuItems = []; 
  productId;
  menuState = MenuItemStates.CHILD;
  redirectFrom;
  navigationData;
  destroy$ = new Subject();
  sweepayPhoneNavigation;


  constructor(private injector: Injector,
              private userProvider: UserProvider, 
              private fb: FormBuilder, 
              private changeDetector: ChangeDetectorRef,
              private route: ActivatedRoute,
              private appStateProvider: AppStateDispatcher,
              private router:Router) {

    super(injector);
   
    this.productId = this.route.snapshot.paramMap.get('productLineId');
    
    this.sweepayPhoneNavigation = {
      redirectTo : `de-ch/otp-verification/${this.productId}`,
      redirectFrom: `de-ch/product-buy/${this.productId}`
    }

    this.redirectFrom = `de-ch/phone-registeration/${this.productId}`;

    this.appStateProvider.saveNavigationData({ otpLength: null });

    // to override browser back btn history
    router.events
    .pipe(takeUntil(this.destroy$))
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.router.navigate([this.sweepayPhoneNavigation.redirectFrom])
    }});

  }


   // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    this.phoneForm = this.fb.group({
      phone: this.fb.control('', { updateOn: 'blur' }),
    })

    this.appStateProvider.getNavigationData()
    .pipe(takeUntil(this.destroy$))
    .subscribe((navigationData)=>{
      if(navigationData.refreshed){
        this.router.navigate([this.sweepayPhoneNavigation.redirectFrom])
      }
      if(navigationData){
        this.navigationData = navigationData;
      }

      if(this.navigationData.sweepayPhoneNumber){
        this.phone.setValue(this.navigationData.sweepayPhoneNumber);
        this.formatPhoneNumber = this.navigationData.sweepayPhoneNumber;
      }

      this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions && this.navigationData.sweepayPhoneNumberValidation);

    })
  }

  ngAfterViewChecked(): void {
    this.setValidators()
    this.changeDetector.detectChanges();
  }

  setValidators() {
      this.phoneForm.get('phone').setValidators([Validators.required, Validators.minLength(16), Validators.maxLength(16)]);
      this.phoneForm.get('phone').updateValueAndValidity();
  }

  checkPhoneNumber() {

    this.value = this.phone.value;

    if(this.value === ''){
      this.phoneForm.reset();
    }
    this.isMsgDisappear = true

    if(!this.value) return; 

    // We should catch error here because there are error thrown in the console
    try{

        const metaData = mobile(this.value,'CH');
        this.parsePhone = parsePhoneNumber(this.value, 'CH');

        this.formatPhoneNumber = this.parsePhone.formatInternational();
        this.phone.setValue(this.formatPhoneNumber);

        this.type = metaData.getType();
        this.isValidPhone = metaData.isValid();

      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country.
          debug(error.message);
        } else {
          throw error
        }
    }
    if (this.checkPhoneValidity()) {
      
        this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions);
        
        this.appStateProvider.saveNavigationData({sweepayPhoneNumber: this.formatPhoneNumber , sweepayPhoneNumberValidation: true })
      
      } else {
        
        this.appStateProvider.saveNavigationData({sweepayPhoneNumber: null , sweepayPhoneNumberValidation: false })
        
        this.disablePurchaseBtn = true;

    }
    
  }

  onButtonClicked() {

    const browserLanguage = navigator.language;
    debug('Button clicked');

    const body = {
      mobilePhoneNumber: this.formatPhoneNumber,
      languageTag: browserLanguage,
      sweepayId: localStorage.getItem('sweepayId')
    };
    this.disablePurchaseBtn = true;
    this.userProvider.updateSweepayAccount$(body).pipe(take(2))
      .subscribe((userData) => {
        if(!userData) return;
        if(userData.responseError){
          if(userData.responseError.code === 7) {
            this.isMsgDisappear = false;
          }
        } else if (userData.otpLength){
          this.isMsgDisappear = true;
          localStorage.setItem('otpIdentifier',userData.otpIdentifier);
          this.appStateProvider.saveNavigationData({otpLength: userData.otpLength })
          this.router.navigate([this.sweepayPhoneNavigation.redirectTo]);
        }
      });
  }

  checkPhoneValidity(){
    return ( isValidPhoneNumber(this.value, 'CH') === true) && (this.parsePhone.country === 'CH') && (this.isValidPhone === true)  && (this.type === 'MOBILE');
  }

  get phone() {
    return this.phoneForm.get('phone');
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
