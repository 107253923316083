import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDGoodsTableDelegate } from './dgoods-table/dgoods-table.component2';
import { IDGoodsTableDataSource } from './dgoods-table/dgoods-table.datasource';


@Component({
  selector: 'voucher-list-component',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.css']
})


export class VoucherListComponent {

  @Input() dataSource :IDGoodsTableDataSource [];
  @Input() loading = true;
  @Input() delegate: IDGoodsTableDelegate;

  @Output() clickedDataTable: EventEmitter<any> = new EventEmitter<any>();

  onClickedDataTable(data: IDGoodsTableDataSource) {
    this.clickedDataTable.emit(data);
  }
}
