import { Component, OnInit } from '@angular/core';
import { TermsOfTwintPageControllerDirective } from './terms-of-twint.controller';
import Debug from 'debug';

const debug = Debug('dgoods:shop:TermsOfTwintPage');
@Component({
  selector: 'app-page-terms-of-twint',
  templateUrl: './terms-of-twint.page.view.html',
  styleUrls: ['./terms-of-twint.page.view.scss']
})
export class TermsOfTwintPage extends TermsOfTwintPageControllerDirective {
}

