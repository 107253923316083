<div class="modeso-digital-product-list">

  <ng-container *ngFor="let product of products">
    <modeso-digital-product-tile [delegate]="this" class="modeso-digital-product-list-items" [product]="product" (imageLoaded)="handleImageLoaded($event)">
      <ng-container *ngTemplateOutlet="templateRef; context: {$implicit: product}"></ng-container>
    </modeso-digital-product-tile>

  </ng-container>
  <div *ngIf="!hasEvenProducts()" class="modeso-digital-product-list-items empty" ></div>
</div>
