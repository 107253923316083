import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-dgoods-newsticker2',
  templateUrl: './dgoods-newsticker.component2.html',
  styleUrls: ['./dgoods-newsticker.component2.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class DgoodsNewstickerComponent2 implements OnInit {

  @Input() item ;
  isRemoved = false;
  newstickerId = sessionStorage.getItem("newstickerId")

  constructor() { }

  ngOnInit(): void {}

  removeNewsticker(){

    this.isRemoved = true
    sessionStorage.setItem("newstickerId","removed")
  }

}
