import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductsConfigService } from './../products.config';
import Debug from 'debug';
import { INewstickerResponse, ISpotLight, ProductLineDto } from '@modeso/types__dgoods-products';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';
const debug = Debug('modeso:modeso-products:ProductsService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


//TODO: Move ProductResponse to Backend Types @modeso/types_dgoods-products and rename to GetProductsResponse
export interface ProductsResponse {
  products: Array<ProductLineDto>;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  private server = '';
  private microserviceName = 'products';
  private readonly productsRoute = '/';
  private newstickerRoute = 'newsticker/';
  private contentPagesRoute = 'contentpages/';
  private spotlightsRoute = 'spotlights';
  private readSpotlightRoute = '/read';
  private contentBoxesRoute = 'contentboxes/';


  constructor(private http: HttpClient, @Inject(ProductsConfigService) private config) {
    this.server = config.apiGateway;
    debug('ProductAPIGATEWAY: ' + config.apiGateway);
    debug('ProductServiceId: ' + config.serviceId);
  }

  getAllProducts() {
    debug('get all products called');
    const url = `${this.server}${this.microserviceName}${this.productsRoute}`;
    return this.http.get<ProductsResponse>(url, httpOptions);
  }

  getNewsTicker(status: any) {
    const url = `${this.server}${this.microserviceName}/${this.newstickerRoute}${status}`;
    return this.http.get<INewstickerResponse>(url, httpOptions);
  }
  public getAllContentPages() {
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${this.contentPagesRoute}`;
    return this.http.get<IContentPageResponse[]>(url);
  }

  public getSpotlights() {
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${this.spotlightsRoute}`;
    return this.http.get<any>(url);
  }

  public markSpotlightAsRead(spotlightId: string) {
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${this.spotlightsRoute}${this.readSpotlightRoute}${this.productsRoute}${spotlightId}`;
    return this.http.post(url,{});
  }
  
  public getPublishedContentBox(productlineId: string) {
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${this.contentBoxesRoute}productLine/${productlineId}`;
    return this.http.get<IContentBoxResponse>(url);
  }

}

