import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartService } from '../services/cart.service';
import * as fromActions from '../actions/cart.actions';
import { mergeMap, map, catchError, retry, tap, throttleTime } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { DynamicKeyValueDTO} from '@modeso/types__dgoods-cart';
import { ICodeValidityCheckRequest } from '@modeso/types__twint-lib-coupons';
const debug = Debug('modeso:twint-lib-cart-fe:CartEffects');


@Injectable()
export class CartEffects {
  constructor(private actions$: Actions, private service: CartService) { }

  /**
   * Effect of post cart.
   */
  postCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.postCart.type),
      tap( () => debug("cart posting preparing")),
      throttleTime(3000),
      tap( () => debug("cart posting execute")),
      mergeMap(
        payload => {
          return this.service.postCart(payload)
            .pipe(
              // retry(1),
              tap( () => debug("cart posting response received")),
              map(
                response => (fromActions.onCartPostedSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onCartPostingFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
   * Effect of post cart error.
   */
  errorOnPostCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onCartPostingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });


  getOrdersByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getOrdersByUserId.type),
      mergeMap(
        () => {
          return this.service.getOrderByUserId()
            .pipe(
              retry(1),
              map(
                response => {

                  return fromActions.onGetOrdersByUserIdSuccessfully({ payload: response.purchasedOrders });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetOrdersByUserIdFailed({ payload: error }));
              })
            );
        }
      )
    )
  );

  errorOnGetORdersByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetOrdersByUserIdFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    ), { dispatch: false }
  );

  getOrderByReceiptUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getOrderByReceiptUrl.type),
      mergeMap(
        (payload: any) => {
          return this.service.getOrderByReceiptUrl(payload.receiptURL)
            .pipe(
              map(
                response => {
                  debug("response from cart service");
                  const orderModel = response.orderModel;
                  if (orderModel.productConfiguration) {
                    orderModel.productConfiguration = {
                      config: objToMap(orderModel.productConfiguration.config),
                      text: objToMap(orderModel.productConfiguration.text),
                    };
                  }
                  return fromActions.onGetOrderByReceiptUrlSuccessfully({ payload: response.orderModel });
                }
              ),
              catchError((error) => {
                debug("response from cart service");
                return of(fromActions.onGetOrderByReceiptUrlFailed({ payload: error }));
              }),
            );
        }
      )
    )
  );

  errorOnGetOrderByReceiptUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetOrderByReceiptUrlFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });

  getCouponState$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getCouponState.type),
      mergeMap(
        () => {
          return this.service.getCouponState()
            .pipe(
              map(
                response => (fromActions.getCouponStateSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.getCouponStateFailed({ payload: error })))
            );
        }
      )
    )
  );
 
  errorOnGetCouponState$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getCouponStateFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    ),
    { dispatch: false }
  );

  checkCodeValidity$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.checkCodeValidity.type),
      mergeMap(
        (action: fromActions.ActionWithPayload<ICodeValidityCheckRequest>) => {
          return this.service.checkCodeValidity(action.payload)
            .pipe(
              map(
                response => (fromActions.onCheckCodeValiditySuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onCheckCodeValidityFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnCheckCodeValidity$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onCheckCodeValidityFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    ),
    { dispatch: false }
  );


  handleOnLoadAllCartErrors(error) {
    debug(error);
    return error;
  }
}


function objToMap(inputObject) {
  const result: DynamicKeyValueDTO = {};

  Array.from(Object.getOwnPropertyNames(inputObject)).forEach(element => {
    result[element] = inputObject[element]
  });

  return result;
}
