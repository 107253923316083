import { Component, Injector, OnInit } from '@angular/core';
import Debug from 'debug';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { ProductsService, ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';
import { switchMap, take, tap } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { SafeHtmlPipeImages } from '../../shared/pipes/safeHtml.pipe';
import { marked } from 'marked';

const debug = Debug('dgoods:project:ContentPagesPage');

@Component({
  selector: 'app-content-pages',
  templateUrl: './contentpage.page.html',
  styleUrls: ['./contentpage.page.scss']
})
export class ContentPagesPage implements OnInit {

  contentPage: IContentPageResponse = {
    _id: '',
    title: { 'en_us': '', 'de_ch': '', 'fr_ch': '', 'it_ch': '' },
    content: { 'en_us': '', 'de_ch': '', 'fr_ch': '', 'it_ch': '' },
    sortId: 0,
    published: false,
    images: [],
    icon:'',
  };
  activatedRoute: ActivatedRoute;
  menuItems = [];
  backBtnRoute = '../../de-ch/landing-buy';
  menuState = MenuItemStates.CHILD;
  language: string;

  constructor(private router: Router, private productProvider: ProductStoreProvider,private productService: ProductsService, injector: Injector, private myPipe: SafeHtmlPipeImages,
    ) {
    this.activatedRoute = injector.get(ActivatedRoute);  }

  ngOnInit() {
    // Initialization code here if needed
    this.loadContentPage();
  }
  loadContentPage() {
    this.productProvider.selectAllContentPages$().pipe(
      take(1), // Limit to only one emission
      tap((response) => {
        if (response.length === 0) {
          // State is not loaded, dispatch action to get all content pages
          this.productProvider.getAllContentPages$().subscribe();
        }
      }),
      switchMap(() => this.productProvider.selectAllContentPages$()), // Retrieve content pages after ensuring state is loaded
      take(2)
    ).subscribe((response) => {
      this.getBrowserLang();
      for (const contentPage of response) {
        if (contentPage._id === this.activatedRoute.snapshot.params.id) {
          this.contentPage = JSON.parse(JSON.stringify(contentPage));
        }
      }
    });
  }
  getBrowserLang(){
    const browserLanguage = navigator.language;
      if (browserLanguage.includes('de')) {
        this.language  = 'de_ch';
      } else if (browserLanguage.includes('fr')) {
        this.language  = 'fr_ch';
      } else if (browserLanguage.includes('it')) {
        this.language  = 'it_ch';
      } else {
        this.language  = 'en_us';
      }
  }
}
