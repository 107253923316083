<dgoods-overlay-new #progressOverlay [hidden]="true" [delegate]="this" [progress]="true">
</dgoods-overlay-new>

<dgoods-overlay-new #appSwitch [hidden]="true" [delegate]="this" [html]="true">
    <app-redirct-paymentscreen [redirectURL]="redirectURL"></app-redirct-paymentscreen>
</dgoods-overlay-new>


<dgoods-dialogbox #dialogBoxOverlay [hidden]="true" [dialogBoxData]="currentDialogBox" [delegate]="this"
  [descriptionFromLocalization]="fromLocalization">
</dgoods-dialogbox>
