<div class="account-overlay">

  <app-hamburger-menu-component [backBtnRoute]="backBtnRoute" [menuItems]="menuItems" [menuState]="menuState" [itemTitle]="'dgoods_shop_email_menu_item_title'"></app-hamburger-menu-component>

  <div class="page-padding">
   
    <p class="text">{{'dgoods_shop_my_account_title' | localize | async}}</p>

    <create-or-update-email-address-component (isFormValid)="isFormValid($event)" [existingUserEmail]="existingUserEmail"></create-or-update-email-address-component>

  </div>

  <div class="sticky">
    <div class="button-padding-style">
      <dgoods-button [delegate]="this" [state]="state" class="button-style" [disabled]="saveBtnDisabled">
        {{"dgoods_shop_account_saveemail" | localize | async}}
      </dgoods-button>
    </div>
  </div>


  <dgoods-overlay-new #errorOverlay [hidden]="true" [message]="message" [delegate]="this" [error]="true">
  </dgoods-overlay-new>

  <dgoods-dialogbox #dialogBoxOverlay [hidden]="true" [dialogBoxData]="dialogBoxObj" [delegate]="this"
    [descriptionFromLocalization]="true">
  </dgoods-dialogbox>
