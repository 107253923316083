import { Component, Input, ViewChild, Renderer2, ElementRef , AfterViewChecked } from '@angular/core';
import { IDGoodsButtonDelegate, IDGoodsOverlayDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { ClipboardService } from 'ngx-clipboard';
import { ProductConfiguration } from './domain.models';
import { CouponRedeemStateEnum ,DiscountDTO } from '@modeso/types__twint-lib-coupons';
import { HtmlEnitityDecoder } from '../../shared/classes/HtmlDecoder';
import bwipjs from 'bwip-js';
import bardcodeSizes from '../../shared/jsonValues/barcodesizes.json'
import { SafeHtmlAllowUrlPipe } from '../../shared/pipes/safeHtmlAllowUrl.pipe';

const debug = Debug('dgoods:shared:DgoodsOrderConfirmationComponent');
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'order-confirmation2',
  templateUrl: './order-confirmation.component2.html',
  styleUrls: ['./order-confirmation.component2.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DgoodsOrderConfirmationComponent2 implements IDGoodsButtonDelegate, IDGoodsOverlayDelegate , AfterViewChecked {

  orderStatuses = ['ORDER_SUCCESS', 'PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED', 'PAYMENT_SUCCESS'];
  failedStatuses = ['PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED'];

  @Input() productImage: string;
  @Input() codeNumber?: string;
  @Input() productConfiguration?: ProductConfiguration;
  @Input() currency: string;
  @Input() price: number;
  @Input() productName: string;
  @Input() date: string;
  @Input() email: string;
  @Input() status: string;
  @Input() isInProductionMode: string;
  @Input() discounts?: DiscountDTO;
  @Input() caption?: string;

  @ViewChild('barcodeCanvas') barcodeCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('showBarcodeCanvas') showBarcodeCanvas: ElementRef<HTMLCanvasElement>;

  showOverlay = false;
  copyOverlay = false;
  public hideScannableCode = true;

  constructor(private clipboardService: ClipboardService, private renderer: Renderer2, private pipe: SafeHtmlAllowUrlPipe) {}

  ngAfterViewChecked(): void {

    if(this.productConfiguration?.config['EAN']){
      this.setBarcodeCriteria(this.productConfiguration.config['EANTYPE'],this.productConfiguration.config['EAN'])
    }
  }


  showScannableCode() {
    debug('showScannableCode');
    this.hideScannableCode = !this.hideScannableCode;
  }

  followLinkAndCopy(value) {
    debug('followLinkAndCopy ' + value);
    this.clipboardService.copyFromContent(value);

    setTimeout(() => {
      window.open(this.getCopy(), '_blank');
    }, 100);
  }
  followLink() {
    setTimeout(() => {
      window.open(this.getPdf(), '_blank');
    }, 600);

  }
  justFollowLink(value) {
    debug('followLink ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);
    setTimeout(() => {
      window.open(this.getLink(), '_blank');
    }, 600);

  }
  justCopy(value) {
    debug('Copy ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);

  }
  openInstructions() {
    this.showOverlay = true;
  }
  getTextSizeClass(text: string) {
    if (text.length <= 13) {
      return 'bigText';
    } else if (text.length <= 16) {
      return 'mediumText';
    } else if (text.length <= 19) {
      return 'smallText';
    } else {
      return 'tinyText';
    }
  }
  getInProgress() {
    return !this.status || !this.orderStatuses.includes(this.status);
  }

  getDebugStatus() {
    if (!this.isInProductionMode && this.failedStatuses.includes(this.status)) {
      return this.status;
    }
    return undefined;
  }
  addVariablesToLink(link: string){
    const variable = this.productConfiguration.config['VARIABLE'];
    const variable2 = this.productConfiguration.config['VARIABLE2'];
    const variable3 = this.productConfiguration.config['VARIABLE3'];

    return link.replace(/{{code1}}/g, variable).replace(/{{code2}}/g, variable2).replace(/{{code3}}/g, variable3);
  }
  getLink() {
    if (!this.productConfiguration) { return undefined; }
    const link = this.productConfiguration.config['LINK'];
    if (this.productConfiguration.config['VARIABLE']
    || this.productConfiguration.config['VARIABLE2']
    || this.productConfiguration.config['VARIABLE3']) {
      return  this.addVariablesToLink(link);

    }else {
      return link;
    }
  }
  getDisplayLink() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['DISPLAYLINK'];
  }
  getLinkText() {
    if (!this.productConfiguration) { return undefined; }

    return this.productConfiguration.text['LINK'];
  }
  getCopy() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['COPY'];
  }
  getCopyText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['COPY'];
  }
  getCopyOnlyText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['COPYONLY'];
  }
  getCode() {
    if (!this.productConfiguration) { return undefined; }

    return this.productConfiguration.config['CODE'];
  }
  getCodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['CODE'];
  }
  getSerial() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['SERIAL'];
  }
  getSerialText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['SERIAL'];
  }
  getCard() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['CARD'];
  }
  getCardText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['CARD'];
  }
  getBarcode() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['EAN'];
  }
  getBarcodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EAN'];
  }
  getPin() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['PIN'];
  }
  getPinText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['PIN'];
  }
  getPdf() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['PDF'];
  }
  getPdfText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['PDF'];
  }
  getAGB() {
    if (!this.productConfiguration) { return undefined; }

    if (!this.productConfiguration.config['AGB']) { return undefined; }

    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(this.productConfiguration.config['AGB']),'string');
  }
  getAGBText() {
    if (!this.productConfiguration) { return undefined; }

    if (!this.productConfiguration.text['AGB']) { return undefined; }

    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(this.productConfiguration.text['AGB']),'string');
  }
  getRedemtionInfo() {
    if (!this.productConfiguration) { return undefined; }

    if (!this.productConfiguration.config['REDEMPTION_INFORMATION']) { return undefined; }
    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(this.productConfiguration.config['REDEMPTION_INFORMATION']),'string');

  }
  getRedemtionInfoText() {
    if (!this.productConfiguration) { return undefined; }

    if (!this.productConfiguration.text['REDEMPTION_INFORMATION']) { return undefined; }

    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(this.productConfiguration.text['REDEMPTION_INFORMATION']),'string');
  }

  getExpiresInfo() {
    debug("getExpiresInfo");
    debug(this.productConfiguration.config['EXPIRES']);
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config['EXPIRES'];
  }
  getExpiresInfoText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EXPIRES'];
  }
  getExpiresInfoDate() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text['EXPIRES'];
  }
  getExtraText() {
    if (!this.productConfiguration) { return undefined; }

    if (!this.productConfiguration.text['EXTRATEXT']) { return undefined; }

    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(this.productConfiguration.text['EXTRATEXT']),'string');
  }
  onButtonClicked(sender: DGoodsButtonComponent): void {
    this.showOverlay = false;
  }

  onOverlayButtonClicked(): void {
    this.hideScannableCode = true;
  }

  transformText(text){
    return this.pipe.transform(new HtmlEnitityDecoder().renderContent(text),'string')
  }

  public get couponRedeemStateEnum(): typeof CouponRedeemStateEnum {
    return CouponRedeemStateEnum;
  }

  public setBarcodeCriteria(eanType,eanValue): void {

      let canvas;
      let type;

      if(this.hideScannableCode){
        canvas = this.barcodeCanvas?.nativeElement;
        type = bardcodeSizes['hide'][eanType];

      }else{
        canvas = this.showBarcodeCanvas?.nativeElement;
        type = bardcodeSizes['show'][eanType];
      }

      this.generateBarcode(canvas,eanType,eanValue,type.width,type.height,type.textsize);

  }

  generateBarcode(canvas,eanType,eanValue,width,height,textsize){
    bwipjs.toCanvas(canvas, {
      bcid: eanType,
      text: eanValue,
      width,
      height,
      textxalign: 'center',
      includetext: true,
      textsize
    }, function (err, canvas) {
      if (err) {
        // console.error(err);
      } else {
        // Barcode generation succeeded
        // `canvas` contains the generated barcode
      }
    });
  }

}

