import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductLineDto, ProductLineImage } from '@modeso/types__dgoods-products';

export interface IDigitalProductTileDelegate {
  onTileClick(productLine: ProductLineDto): void;
}

@Component({
  selector: 'modeso-digital-product-tile',
  templateUrl: './digital-product-tile.component.html',
  styleUrls: ['./digital-product-tile.component.scss']
})
export class DigitalProductTileComponent {

  @Input() product: ProductLineDto;
  @Input() delegate?: IDigitalProductTileDelegate;
  @Input() isTransparent?= false;

  @Output() imageLoaded: EventEmitter<any> = new EventEmitter<any>();

  public getImage(): ProductLineImage {
    let img = this.product.productLineImages.find(
      (x) => x.imageSize === 'MASTER_IMAGE',
    );
    if (img === undefined) {
      img = this.product.productLineImages.find(
        (x) => x.imageSize === 'LARGE',
      );
    }
    if (img === undefined) {
      img = {} as ProductLineImage;
      img.frontImage = 'assets/placeholder.png';
    }
    return img;
  }
  constructor() { }

  /**
   * emit the card product to the parent "product-list" to navigate with it
   */
  onClick() {
    if (this.delegate) {
      this.delegate.onTileClick(this.product);
    }
  }

  getCssClasses() {
    if (this.isTransparent) {
      return 'modeso-digital-product-tile transparent';
    } else {
      if (!this.product.blocked) {
        return 'modeso-digital-product-tile';
      } else {
        return 'modeso-digital-product-tile blocked-product-line';
      }

    }
  }

  onImageLoad(){
    this.imageLoaded.emit(true);
  }
  onImageError () {
    this.imageLoaded.emit(false);
  }
}
