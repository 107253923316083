import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import Debug from 'debug';
const debug = Debug('dgoods:project-shop:AuthGuard');
@Injectable()
export class AuthGuard implements CanActivate {
    protected subscriptions = [];

    constructor(private router: Router, private sessionProvider: DgoodsSessionStoreProvider, private route: ActivatedRoute) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const segments = state.url.split('/');
        const urltoken = segments[segments.length - 1].split('?')[0];
        const issuer = segments[segments.length - 1].split('?')[1];


        /*this.subscriptions.push(this.sessionProvider.getSessionState$().subscribe(sessionState => {
          debug(state);

          if (sessionState.isValidUrl) {
            debug("Fakestart say's the url is valid");
            this.router.navigate(['../../de-ch/landing-buy']);
          } else {
            debug("Fakestart navigates to mocked checkin");
            this.router.navigateByUrl('/de-ch/mockedchecking');
          }
        }));*/

        const authorized = this.sessionProvider.start({ checkInUrl: urltoken ,issuer}).pipe(
            map(urlState => {
                if (urlState.isValidUrl) {
                    debug("Auth Guard say's the url is valid");
                    return true;
                } else {
                    debug("Auth Guard navigates to mocked checkin");
                    //this.router.navigateByUrl('/de-ch/mockedchecking');
                    return false;
                }
            })
        );
        return authorized;
    }
}
