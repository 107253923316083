import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import Debug from 'debug';
const debug = Debug('dgoods:shop:MaintenenceGuard');
@Injectable()
export class MaintenenceGuard implements CanActivate {
  protected subscriptions = [];

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    debug("check if maintenance mode is active");
    if (environment.maintenance) {
      debug("maintenance mode is active");
      this.router.navigate(['/de-ch/maintenance']);
      return false;
    }
    debug("maintenance mode is NOT active");
    return true;
  }

}
