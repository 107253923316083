import {
  IDGoodsTableDelegate,
  IDGoodsTableDataSource, IDGoodsNavigationDelegate, DGoodsNavigationComponent
  } from '@modeso/twint-lib-core-fe';
import { Location } from '@angular/common';
import { OnInit, Directive, Component } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
const debug = Debug('dgoods:project:PurchasingHistoryPageController');

@Component({
  template: `<ng-content></ng-content>`,
// tslint:disable-next-line: directive-selector
selector: 'app-purchasing-history-page-controller'
})
export class PurchasingHistoryPageControllerDirective extends BasePageComponent implements
  OnInit,
  IDGoodsNavigationDelegate,
  IDGoodsTableDelegate {
  terminalId: string;
  constructor(private analyticsProvider: AnalyticsProvider, private location: Location, injector: Injector, protected router: Router) {
        super(injector);
        this.terminalId = sessionStorage.getItem('terminalId');
    }

  ngOnInit() {
    super.ngOnInit();
    this.savePageView();
  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Purchasing History', terminalId: this.terminalId, });
  }

  onRowClick(data: IDGoodsTableDataSource) {
    debug(' a row is clicked');
  }
  onBackClicked(sender: DGoodsNavigationComponent): void {
    this.router.navigate(['../../de-ch/landing-buy']);
  }

}
