import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-denominate-prices',
  templateUrl: './denominated-price.dialog.html',
  styleUrls: ['./denominated-price.dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DenominatedPriceDialog implements AfterViewInit {

  priceValue:number;
  priceData;
  matcher = new MyErrorStateMatcher();
  @ViewChild('price') priceSelector: ElementRef;

  constructor(public dialogRef: MatDialogRef<DenominatedPriceDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.priceData = data.price;
    this.priceValue = data.denominatedPrice ? data.denominatedPrice : null;
  }

  ngAfterViewInit() {
    this.focusInput();
  }

  // Separate method to focus on input
  focusInput(): void {
    setTimeout(() => {
      this.priceSelector.nativeElement.focus();
    }, 0);
  }

  confirm(): void {
    this.dialogRef.close(this.priceValue);
  }
}
