import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { CartConfig, CartConfigService } from './cart.config';
import * as fromReducer from './reducers/cart.reducer';
import { CartEffects } from './effects/cart.effects';
import { CartService } from './services/cart.service';
import { DgoodsSharedModule, InjectedTextFeautureModule } from '@modeso/twint-lib-core-fe';
import { DgoodsOrderConfirmationDetailsComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation-details/dgoods-order-confirmation-details.component';
import { DGoodsOrderConfirmationOverlayComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation-overlay/dgoods-order-confirmation-overlay.component';
import { DgoodsOrderConfirmationComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { DGoodsOverlayComponentNew } from './components/dgoods-overlay/dgoods-overlay.component';


@NgModule({
  declarations: [DGoodsOrderConfirmationOverlayComponent, DgoodsOrderConfirmationComponent,
     DgoodsOrderConfirmationDetailsComponent, DGoodsOverlayComponentNew],
  providers: [],
  imports: [
    CommonModule,
    NgxBarcodeModule,
    DgoodsSharedModule,
    InjectedTextFeautureModule,
    StoreModule.forFeature(fromReducer.cartFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([CartEffects]),
  ],
  exports: [DGoodsOrderConfirmationOverlayComponent, DgoodsOrderConfirmationComponent,
     DgoodsOrderConfirmationDetailsComponent, DGoodsOverlayComponentNew],
})
export class CartModule {
  static forRoot(config: CartConfig): ModuleWithProviders<CartModule> {
    return {
      ngModule: CartModule,
      providers: [
        CartService,
        {
          provide: CartConfigService,
          useValue: config
        }
      ]
    };
  }
}
