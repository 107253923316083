import { Injector, Directive, Input, Output, EventEmitter, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import Debug from 'debug';
import { BasePageComponent } from '../base.page';
import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
const debug = Debug('dgoods:project:DataProtectionOfTwintPageController');

@Component({
  template: `<ng-content></ng-content>`,
  // tslint:disable-next-line: directive-selector
  selector: 'app-data-protection-of-twint.page-controller'
})

export class DataProtectionOfTwintPageControllerDirective  implements IDGoodsButtonDelegate {
  @Input() hiddenDataProtection: boolean;
  @Output() hideDataProtection = new EventEmitter<any>();
  constructor(injector: Injector, private router: Router, private route: ActivatedRoute) {
  }
  /**
   * IDGoodsTextButtonDelegate
   */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    debug('Button clicked');
    this.hideDataProtection.emit();
  }

}
