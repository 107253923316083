import { Injectable } from '@angular/core';
import { of , Observable} from 'rxjs';
import { IAppStateProtocolModel } from '../models/appStateProtocol.model';
​
@Injectable()
export class LocalStorageService {
​
    save(key: string , data: IAppStateProtocolModel) : Observable<IAppStateProtocolModel> {
        localStorage.setItem(key, JSON.stringify(data));        
        return of(data)
    }
​
    get(key: string) : Observable<IAppStateProtocolModel> {
        const protocol = localStorage.getItem(key);
        return protocol ? of(JSON.parse(protocol)) : of({});
    }
}