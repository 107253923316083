
<div class="email-overlay">
  <app-hamburger-menu-component [backBtnRoute]="emailNavigation.redirectFrom" [menuItems]="menuItems" [menuState]="menuState"></app-hamburger-menu-component>

  <div class="page-padding">

    <p class="header-style">{{"dgoods_shop_email_title" | localize | async}}</p>
    <p class="paragraph-style"> {{"dgoods_shop_email_description" | localize | async}} </p>

    <create-or-update-email-address-component (isFormValid)="checkFormValidity($event)" [existingUserEmail]="existingUserEmail"></create-or-update-email-address-component>


  <div class="email-hint">
    {{'dgoods_shop_save_new_email_title' | localize | async }}
  </div>

  <div class="fixed-padding"></div>

  <app-checkout-flyout-component *ngIf="navigationData"
            [twintTC]="navigationData.twintTC"
            [product]="navigationData.product"
            [price]="navigationData.amount"
            [disablePurchaseBtn]="disablePurchaseBtn"
            [couponState]="navigationData.couponState"
            [currency]="navigationData.currency"
            [redirectFrom]="redirectFrom"
            (userPurchasedProduct)="onButtonClicked()"
          >
    </app-checkout-flyout-component>

</div>

</div>

<app-product-checkout-component #productCheckout></app-product-checkout-component>


<dgoods-overlay-new #errorOverlay [hidden]="true" [message]="message" [delegate]="this" [error]="true">
</dgoods-overlay-new>
<dgoods-dialogbox #dialogBoxOverlay [hidden]="true" [dialogBoxData]="dialogBoxObj" [delegate]="this"
  [descriptionFromLocalization]="true">
</dgoods-dialogbox>
