
import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import Debug from 'debug';
import { Location } from '@angular/common';
import { CartProvider, Order } from '@modeso/dgoods-lib-cart-fe';
import { OrderConfirmationControllerDirective } from './order-confirmation.page.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as fromApp from './../../state/app.state.reducer';
import { Store } from '@ngrx/store';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { DGoodsOverlayComponent } from '@modeso/twint-lib-core-fe';
const debug = Debug('dgoods:shared:OrderConfirmationPage');

@Component({
  selector: 'app-order-confirmation.page',
  templateUrl: './order-confirmation.page.view.html',
  styleUrls: ['./order-confirmation.page.view.scss']
})
export class OrderConfirmationPage extends OrderConfirmationControllerDirective implements OnInit, OnDestroy {
  receiptUrl: string;
  dataSource: Order ={} as Order;
  image: string;
  inProgress = 'in progress...';
  orderStatuses = ['ORDER_SUCCESS', 'PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED', 'PAYMENT_SUCCESS'];
  failedStatuses = ['PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED'];
  merchantFailedStatuses = ['REVERSE_CONFIRMED', 'REVERSE_FAILED'];
  retries = 3;
  counter = 0;
  isInProductionMode = environment.production;
  lastOrderConfirmation;
  protected retrySubscriptions = [];
  protected cancelAllRequest = false;
  orderConfirmationStatus: string;
  directedFromHistory=false;
  @ViewChild('incognitoOverlay', { static: false }) incognitoOverlay: DGoodsOverlayComponent;

  retryRequestTimeOut;

  retryRequest = (counter) => {
    debug('reject');
    this.retryRequestTimeOut = setTimeout(() => {
      if (this.cancelAllRequest) {
        return;
      }
      this.getOrderByReceiptUrl().then(this.successRequest).catch(this.retryRequest);
    }, 3000);
  }
  successRequest = (success) => {
    debug('resolve');
    debug(success);
    if (success) {
      // DO nothing
      this.retrySubscriptions.forEach(subscribe => {
        subscribe.unsubscribe();
      });
      debug('Display success');
      debug(this.orderConfirmationStatus);
      //Merchant Failed
      if(this.merchantFailedStatuses.includes(this.orderConfirmationStatus)){
        super.onRefundStatus();
      }
    } else {
      debug('Display onFailedStatus');
      this.retrySubscriptions.forEach(subscribe => {
        subscribe.unsubscribe();
      });
      super.onFailedStatus();
    }
  }

  constructor(analyticsProvider: AnalyticsProvider, location: Location, injector: Injector, private cartProvider: CartProvider,
    route: ActivatedRoute, router: Router, store: Store<fromApp.AppState>) {
    super(analyticsProvider, location, injector, router, route, store);
    this.receiptUrl = route.snapshot.paramMap.get('receiptUrl');
    this.directedFromHistory = route.snapshot.paramMap.get('history') ? true : false;
    this.subsripeToErrors();
    this.getOrderByReceiptUrl().then(this.successRequest).catch(this.retryRequest);
  }



  ngOnInit() {
    super.ngOnInit();

  }

  subsripeToErrors() {
    this.subscriptions.push(this.cartProvider.getError$().subscribe((error) => {
      if (error) {
        debug('HAS ERROR');
        debug(error);
        if (error.status.toString() === '404') {
          this.counter = 20;
          this.cancelAllRequest = true;
          this.ngOnDestroy();
          super.onFailedStatus();
        }
      }
    }));
  }
  getOrderByReceiptUrl() {
    return new Promise((resolve, reject) => {
      const sub = this.cartProvider.getOrderByReceiptUrl$(this.receiptUrl)
        .subscribe((orderConfirmation: Order) => {
          debug('SUBSC');
          debug(orderConfirmation);
          try {

            if(!Object.keys(orderConfirmation).length ){
              return;
            }
            debug(orderConfirmation.status);
            if (orderConfirmation.status) {
              this.orderConfirmationStatus = orderConfirmation.status
              this.lastOrderConfirmation = orderConfirmation;
              this.dataSource = orderConfirmation;
              if ((this.failedStatuses.includes(orderConfirmation.status) && environment.production) ||
                (environment.production && this.counter >= 10)) {
                this.counter = 20;
                resolve(false);
                return;
              }

              if (!(this.orderStatuses.includes(orderConfirmation.status)) && this.counter !== 10) {
                this.counter++;
                reject(this.counter);
                return;
              }
              (orderConfirmation.productImages || []).forEach((element) => {
                if (element.imageSize === 'MASTER_IMAGE') {
                  this.image = element.frontImage;
                }
                if (element.imageSize === 'LARGE' && this.image === undefined) {
                  this.image = element.frontImage;
                }
              });
              resolve(true);
            } else {
              this.counter++;
              reject(this.counter);
            }
          } catch (error) {
            debug(error);
            debug('ERROR');
            reject(this.counter);
          }

        }, (error) => {
          debug('ERROR');
          this.counter++;
          reject(this.counter);
        }
        );
      this.retrySubscriptions.push(sub);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cancelAllRequest = true;
    clearTimeout(this.retryRequestTimeOut);
    this.retrySubscriptions.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }


}
