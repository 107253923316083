<div class="extra-margin">
  <h1>{{ "dgoods_shop_title" |localize |async }}</h1>

  <div class="category-list">
    <div class="category-item" [class.active]="activeCategory === 'ALL'" (click)="onCategoryClick('ALL')">
      <span>{{ "dgoods_shop_all" |localize |async }}</span>
    </div>
    <div *ngFor="let category of categories" [class.active]="activeCategory._id === category._id" class="category-item" (click)="onCategoryClick(category)">
      <img [class.active]="activeCategory._id !== category._id" src="assets/svgs/light/{{category.icon}}.svg" alt="{{ category.name }} icon">
      <img [class.active]="activeCategory._id === category._id" src="assets/svgs/dark/{{category.icon}}.svg" alt="{{ category.name }} icon">
      <span>{{ category.text[language] }}</span>
    </div>
  </div>
</div>

