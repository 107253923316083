import { Component, Injector, OnInit, Input } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import {Location} from '@angular/common';


const debug = Debug('dgoods:shop:FakeCheckinPage');
@Component({
  selector: 'app-page-fakeCheckin',
  templateUrl: './fakeCheckin.page.html',
  styleUrls: ['./fakeCheckin.page.scss']
})
export class FakeCheckinPage extends BasePageComponent implements OnInit {

  /**
   *
   * ATTENTION
   *
   * Don't take this implementation as good code, it's done quick and dirty,
   * because this will not be used in PRODUCTION
   *
   */

  public token: string = 'Your Token';
  public username: string = '';
  public password: string = '';

  constructor(injector: Injector, private sessionProvider: DgoodsSessionStoreProvider, private location: Location,
    private router: Router
  ) {
    super(injector);
    debug('Test CheckIn');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  // generating random values for pairing uuid and customerRelationUuid
  randomString(len, charSet) {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
      let randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  onClick() {
    debug('should login with ' + this.token + ' ' + this.username + ' ' + this.password);
    this.generateUrl(this.token, 'BUY');
  }

  generateUrl(token, action) {
    const credentials = {
      username: this.username,
      password: this.password
    };
    const body = {
      pairingUuid: this.randomString(10, null),
      customerRelationUuid: this.randomString(10, null),
      code: 'test#Adidas',
    };
    this.sessionProvider.checkin(body, credentials);
    this.subscriptions.push(this.sessionProvider.getSessionState$().subscribe(
      (response) => {
        debug(response);
        if (response.url) {
          setTimeout(() => {
            const urlSegments = response.url.split('/');
            this.router.navigate(['../../de-ch/', urlSegments[urlSegments.length - 1]], { queryParams: { returnAppPackage: 'protocol' }});
          }, 1000);
        }
      }
    ));
  }
}

