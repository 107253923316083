import { Action, createReducer, on, createSelector } from '@ngrx/store'
import * as fromActions from './../actions/cart.actions'
import { CheckoutData, Order, OrderMetadata } from '../models/domain.models'
import { CouponValidationDTO } from '@modeso/types__twint-lib-coupons';
export const cartFeatureKey = 'modesoCartMicroservice';

// State Declarations - START

export interface FeatureState {
  cart: CheckoutData;
  order: Order;
  purchasedProducts: Array<OrderMetadata>;
  error: any;
  checkOutError: any;
  couponState: boolean;
  coupon:CouponValidationDTO;
  // checkoutStatus: 'initial' | 'success' | 'failed';
}

export interface AppState {
  modesoCartMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoCartMicroservice;
export const featureCart = createSelector(
  selectFeature,
  (state: FeatureState) => state.cart
);

export const featureOrder = createSelector(
  selectFeature,
  (state: FeatureState) => state.order
);

export const featureError = createSelector(
  selectFeature,
  (state: FeatureState) => state.error
);

export const featurePurchasedProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.purchasedProducts
);

export const getCouponState = createSelector(
  selectFeature,
  (state: FeatureState) => state.couponState
);

export const getCodeValidity = createSelector(
  selectFeature,
  (state: FeatureState) => { return  { coupon: state.coupon , error: state.error }}
);

export const featureCheckoutError = createSelector(
  selectFeature,
  (state: FeatureState) => state.checkOutError
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  cart: {} as CheckoutData,
  order: {} as Order,
  purchasedProducts: new Array<OrderMetadata>(),
  error: undefined,
  checkOutError: undefined,
  couponState:false,
  coupon: undefined
};

const cartReducer = createReducer(
  initialState,
  on(fromActions.postCart, (state) => ({ ...state, error: undefined, checkOutError: undefined })),
  on(fromActions.onCartPostedSuccessfully, (state, action) => ({ ...state, cart: action.payload, checkOutError: undefined })),
  on(fromActions.onCartPostingFailed, (state, action) => ({ ...state, cart: {} as CheckoutData, checkOutError: action.payload })),

  on(fromActions.flushCartError, (state, action) => {
    return {...state, checkOutError:undefined, error:undefined};
}
),
  on(fromActions.getOrdersByUserId, (state) => (initialState)),
  on(fromActions.onGetOrdersByUserIdSuccessfully, (state, action) => ({ ...state, purchasedProducts: action.payload })),
  on(fromActions.onGetOrdersByUserIdFailed, (state, action) => ({ ...state, error: action.payload  })),

  on(fromActions.getOrderByReceiptUrl, (state) => ({ ...state })),
  on(fromActions.onGetOrderByReceiptUrlSuccessfully, (state, action) => ({ ...state, order: action.payload, error: undefined })),
  on(fromActions.onGetOrderByReceiptUrlFailed, (state, action) => ({ ...state, order:  {} as Order, error: action.payload })),

  // get coupon state
  on(fromActions.getCouponState, (state) => ({ ...state })),
  on(fromActions.getCouponStateSuccessfully, (state, action) => ({ ...state, couponState: action.payload, coupon:undefined , error: undefined })),
  on(fromActions.getCouponStateFailed, (state, action) => ({ ...state, coupon:undefined ,error: action.payload })),

  // check code validity
  on(fromActions.checkCodeValidity, (state) => ({ ...state , error:undefined})),
  on(fromActions.onCheckCodeValiditySuccessfully, (state, action) => ({ ...state,coupon:action.payload ,error: undefined })),
  on(fromActions.onCheckCodeValidityFailed, (state, action) => ({ ...state, coupon:undefined ,error: action.payload }))

  );

export function reducer(state: FeatureState | undefined, action: Action) {
  return cartReducer(state, action);
}
