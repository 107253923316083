/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component, Input } from '@angular/core';
import { HtmlEnitityDecoder } from '../../shared/classes/HtmlDecoder';

interface ExpandableComponent {
  title:string;
  text:string;
}

@Component({
  selector: 'app-expandable-text-component',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.scss'],
})
export class ExpandableTextComponent  {


  @Input() expandableObject: ExpandableComponent;
  decodedExpandableText;

  ngOnInit() { 
    this.decodedExpandableText = new HtmlEnitityDecoder().decodeHTML(this.expandableObject?.text);
  }


}
