import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReducer from './app.state.reducer';
import * as fromActions from './app.state.actions';
import Debug from 'debug';
import { Observable } from 'rxjs';
const debug = Debug('dgoods:shop:AppStateDispatcher');

@Injectable({
  providedIn: 'root'
})
export class AppStateDispatcher {

  constructor(private store: Store<fromReducer.AppState>) {
  }

  public saveNavigationData(data: any): void {
    this.store.dispatch(fromActions.saveNavigationData({ payload: data }));
  }

  public getNavigationData(): Observable<any> {
    return this.store.pipe(select(fromReducer.selectNavigationData));
  }

  public flushNavigationData():void {
    this.store.dispatch(fromActions.flushNavigationData());
  }
}
