import { IDigitalProductTileDelegate, IDigitalProductListDelegate , ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { Router, ActivatedRoute } from '@angular/router';
import Debug from 'debug';
import { Observable, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { BasePageComponent } from '../base.page';
import { Injector, OnInit, ViewChild, Component, ElementRef, HostListener } from '@angular/core';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { DGoodsDialogBoxComponent, IDGoodsDialogBoxDelegate } from '@modeso/twint-lib-core-fe';
import { CategoryDto, ProductLineDto } from '@modeso/types__dgoods-products';
import { MenuItems } from '../../shared/classes/MenuItems';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { CartProvider } from '@modeso/dgoods-lib-cart-fe';
import { IContentPageResponse } from '@modeso/dgoods-lib-admin-fe/models/content.pages';

const debug = Debug('dgoods:project:LandingBuyPageController');
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-buy.page.view.html',
  styleUrls: ['./landing-buy.page.view.scss']
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LandingBuyPageController extends BasePageComponent implements
  OnInit,
  IDigitalProductListDelegate,
  IDigitalProductTileDelegate,
  IDGoodsDialogBoxDelegate {

  observables = new Map<string, Observable<ProductLineDto[]>>();
  activeFilter$: Observable<string | CategoryDto>;
  categories$: Observable<CategoryDto[]>;
  providers = [];
  currentDialogBox = { title: '', description: '' };
  terminalId: string;
  blockedProductLines: boolean[] = [];
  showDisclaimer: boolean;
  language: string;
  menuItems = [];
  menuItemsCombined = [];
  backBtnRoute = '../../de-ch/landing-buy';
  menuState = MenuItemStates.COLLAPSED;
  contentPages: IContentPageResponse[];
  menuItemsContentPages = [];

  productLineDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_productLine_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_productLine_exceed_limit',
  };

  shopDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_shop_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_shop_exceed_limit',
  };

  @ViewChild('dialogBoxShopOverlay', { static: true }) protected dialogBoxShopOverlay: DGoodsDialogBoxComponent;
  @ViewChild('dialogBoxProductLineOverlay', { static: true }) protected dialogBoxProductLineOverlay: DGoodsDialogBoxComponent;
  @ViewChild('filterElement', { read: ElementRef, static: true }) filterElement: ElementRef;
  @ViewChild('newsTicker', { read: ElementRef }) newsTicker: ElementRef;
  targetPosition: any;
  targetCategory: CategoryDto;
  initialTopPosition: any;
  spotlights = [];

  constructor(
    private analyticsProvider: AnalyticsProvider,
    private router: Router,
    private route: ActivatedRoute,
    private productProvider: ProductStoreProvider,
    private store: Store<fromApp.AppState>,
    private injector: Injector,
    private cartProvider: CartProvider,
    private appStateProvider: AppStateDispatcher
  ) {
    super(injector);
    this.dispatchAndGetAllProducts();
    this.terminalId = sessionStorage.getItem('terminalId');
    this.activeFilter$ = productProvider.getActiveFilter$();
    this.language = `${document.documentElement.lang}` === 'en' ? 'en-us' : `${document.documentElement.lang}-ch`;
    this.showDisclaimer = sessionStorage.getItem('isP2P') === 'true' ? true : false;
  }

  dispatchAndGetAllProducts() {
    this.getAllProducts();
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    // Check if we've reached or passed the target scroll position
    if (this.targetPosition && window.scrollY >= this.targetPosition) {
      debug("finish scroll")
      // In Angular, you can't remove a specific HostListener,
      // you would need to use a condition to stop executing your function instead
      this.targetPosition = null;
      this.productProvider.setFilter(this.targetCategory)
    }
  }
  
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent): void {
    window.location.reload();
  }


  calculateOffset = () => {
    // If the element is in the viewport, use getBoundingClientRect().top
    if (this.filterElement.nativeElement.getBoundingClientRect().top > 0) {
      return this.filterElement.nativeElement.getBoundingClientRect().top;
    }
    // If the element is not in the viewport, its position is the same as the initial top position
    else {
      return this.initialTopPosition;
    }
  };




  handleCategoryClick(category: CategoryDto) {


    const newsTickerHeight = this.newsTicker.nativeElement.offsetHeight;

    const addressBarHeight = window.outerHeight - window.innerHeight

    const yOffset = this.calculateOffset() + newsTickerHeight;
    debug("handleCategoryClick", yOffset, "inital: " + this.initialTopPosition, "addressbar:" + addressBarHeight)
    if(window.pageYOffset > yOffset) {
      this.targetCategory = category
      this.targetPosition = yOffset
      window.scrollTo({
        top: yOffset,
        behavior: 'smooth'
      });
    } else {

      this.productProvider.setFilter(category)
    }
  }


  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.savePageView();
    this.observeFilterdProducts();
    this.cartProvider.flushError();
    this.dispatchAndGetSpotlights(); 
    this.appStateProvider.flushNavigationData();
    this.categories$ = this.productProvider.getCategories$();
    this.menuItems =  new MenuItems(this.productProvider).getMenuItems();
    this.subscriptions.push(
      this.productProvider.selectAllContentPages$().pipe(
        take(1), // Limit to only one emission
        tap((response) => {
          if (response.length === 0) {
            // State is not loaded, dispatch action to get all content pages
            this.productProvider.getAllContentPages$().subscribe();
          }
        }),
        switchMap(() => this.productProvider.selectAllContentPages$()) // Retrieve content pages after ensuring state is loaded
      ).subscribe((contentPages) => {
        this.contentPages = contentPages;
        this.populateMenuItemsContentPages();
      })
    );


  }
  populateMenuItemsContentPages() {
    this.menuItemsContentPages = [];
    for (const page of this.contentPages) {

        this.menuItemsContentPages.push({
          title: page.title[this.language.replace('-', '_')],
          routeLink: `../../de-ch/contentpage/${page._id}`,
          target: "_self",
          icons: page.icon ? `${page.icon}.svg` : 'dummy_content_page_icon.svg',
          contentPageId: page._id
        });

    }
    this.initialTopPosition =  this.filterElement.nativeElement.getBoundingClientRect().top;
  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Landing Buy',
    terminalId: this.terminalId, });
  }

  /** OnInit --- END */
  /** IDigitalProductTileDelegate --- START */

  onTileClick(product: ProductLineDto): void {
    debug('On Tile Click: Product');
    debug(product);
    if (product.productLineId === 'history') {
     this.router.navigate(['../../de-ch/history']);
    }
  }

  onDialogBoxButtonClicked(): void {
    this.dialogBoxShopOverlay.hidden = true;
    this.dialogBoxProductLineOverlay.hidden = true;
  }

  /** IDigitalProductTileDelegate --- END */
  /** IDigitalProductListDelegate --- START */

  onListItemClicked(product: ProductLineDto): void {
    debug("onListItemClicked", product)
    if (product.blocked) {
      this.currentDialogBox = this.productLineDialogBox;
      this.dialogBoxProductLineOverlay.hidden = false;
    } else {
      this.router.navigate(['../../de-ch/product-buy/' + product.productLineId]);
    }
  }
  /** IDigitalProductListDelegate --- END */

  getAllProducts() {
    localStorage.removeItem('products');
    this.productProvider.getAllProducts$().pipe(take(2)).subscribe(products => {
        if (products && products.length !== 0) {
          products.forEach((element) => {
            if (element.blocked) {
              this.blockedProductLines.push(element.blocked);
            }
          });
          if (this.blockedProductLines.length === products.length) {
            debug("getAllProducts", this.blockedProductLines, products)
            this.currentDialogBox = this.shopDialogBox;
            this.dialogBoxShopOverlay.hidden = false;
          }
          localStorage.setItem('products', JSON.stringify(products));
        } else {
          this.productProvider.fetchAllProducts();
        }
    });
  }

  observeFilterdProducts() {
    this.subscriptions.push(this.productProvider.getFitleredProducts$().subscribe(products => {
      this.providers = products;
    }));
  }

  dispatchAndGetSpotlights(){
    this.subscriptions.push(
      this.productProvider.selectSpotlightFromStore$().pipe(
        withLatestFrom(this.productProvider.selectUserHasSpotlightsFromStore$()),
      ).subscribe(([spotlights, userHasSpotlights]) => {
        if(userHasSpotlights === undefined){
          this.productProvider.getSpotlights$();
        }else if (userHasSpotlights) {
          this.spotlights = spotlights;
        }
      
      })
    );
  }
}

