import { Injectable, InjectionToken, Inject } from '@angular/core';
import Debug from 'debug';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay, take } from 'rxjs/operators';
import { DgoodsSessionStoreProvider } from '../providers/dgoods.session.provider';
const debug = Debug('dgoods:session:AuthHandler');

export interface IAuthHandlerDelegate {
  onSessionExired();
}

export const AUTH_INTERCEPTOR = new InjectionToken<IAuthHandlerDelegate>('AUTH_INTERCEPTOR');
@Injectable()
export class AuthHandler implements HttpInterceptor {

    constructor(private sessionProviders: DgoodsSessionStoreProvider,
                @Inject(AUTH_INTERCEPTOR) private authHandlerDelegate: IAuthHandlerDelegate) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            withCredentials: true
        });


        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 440) {
                    // redirect to checkin again
                    // localStorage.clear();

                    if (this.authHandlerDelegate) {
                      debug('onSessionExired');
                      debug(request.url);
                      this.authHandlerDelegate.onSessionExired();
                    } else {
                      debug('onSessionExired event not fired. No delegate defined for the AuthHandler.');
                    }
                }

                return throwError(err);
            }),
            retryWhen(errors => errors
                .pipe(
                    concatMap((error) => {
                        if ((error.status === 401)) {
                            debug('JWT Token exired, lease new one');
                            debug(request.url);
                            this.sessionProviders.getNewAccsessToken();
                            return of(error.status);
                        }
                        return throwError(error);
                    }),
                    take(2),
                    delay(1000)
                )
            )
        );

    }
}
