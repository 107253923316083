/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {AfterViewInit, Component, ElementRef, EventEmitter , Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.touched);
    const invalidParent = !!(control && control.parent && control.parent?.hasError('doesntMatch') && control.parent.dirty);
    return (invalidCtrl || invalidParent);
  }
}


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-or-update-email-address-component',
  templateUrl: './create-update-email-address.component.html',
  styleUrls: ['./create-update-email-address.component.scss'],
})
export class CreateOrUpdateEmailAddressComponent implements AfterViewInit   {
  @ViewChild('emailInput') emailInput: ElementRef;
  @Input() existingUserEmail;
  @Output() isFormValid = new EventEmitter<any>();

  isEmailCancelBtnEnabled:boolean = false;
  isConfirmEmailCancelBtnEnabled:boolean = false;
  emailForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  emailPattern: RegExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

  constructor( private fb: FormBuilder) { }

  ngOnInit(): void {

    this.createForm();

    if(this.existingUserEmail){
      this.isFormValid.emit({formValidity:true,newUserEmail:this.email.value})
      this.populateFields(this.existingUserEmail);
    }

    this.emailForm.statusChanges.subscribe(value => {
      if(value === 'VALID'){
        this.isFormValid.emit({formValidity:true,newUserEmail:this.email.value})
      }else{
        this.isFormValid.emit({formValidity:false,newUserEmail:this.email.value})
      }
    });

  }

  ngAfterViewInit(): void {
    
  }

  createForm() {
    this.emailForm = this.fb.group({
        email: this.fb.control('',  [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]),
        confirmEmail: this.fb.control('', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]),
    },{
      validators: this.ConfirmedValidator('email','confirmEmail')
    })
  }


  populateFields(existingUserEmail) {
    this.emailForm.setValue({
      email: existingUserEmail,
      confirmEmail: existingUserEmail,
    });
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if ( matchingControl.errors && !matchingControl.errors.doesntMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ doesntMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }


  enableCloseBtn(action,type){
    if(type==='email'){
      this.isEmailCancelBtnEnabled = action
    }else{
      this.isConfirmEmailCancelBtnEnabled = action
    }
  }


  get email() {
    return this.emailForm.get('email');
  }

  get confirmEmail() {
    return this.emailForm.get('confirmEmail');
  }

  get emailErrorMessage(): string {
    const form: FormControl = (this.email as FormControl);
    if(form.hasError('required')){
      return 'dgoods_email_required'
    }else if(form.hasError('pattern')){
      return 'dgoods_email_provide_valid_email'
    }else{
      return ''
    }
  }

  get confirmEmailErrorMessage(): string {
    const form: FormControl = (this.confirmEmail as FormControl);
    if(form.hasError('required')){
      return 'dgoods_email_confirmation_required'
    }else if(form.hasError('pattern')){
      return 'dgoods_email_provide_valid_email'
    }else if(form.hasError('doesntMatch')){
      return 'dgoods_email_address_doesnt_match_confirm_address'
    }else{
      return ''
    }
  }

}
