import { ProductStoreProvider } from "@modeso/dgoods-lib-products-fe";
import { take } from 'rxjs/operators';
import menuItemRoutes from '../jsonValues/menuItems.routes.json'

export class MenuItems{

     menuItems = []; 

    constructor(private productProvider: ProductStoreProvider){}
    

    public getMenuItems() {
        let browserLanguage = this.getBrowserLang();
        // this.subscriptions.push(
        this.productProvider.getMenuItems$().pipe(take(2)).subscribe((menuItems)=>{

            if(menuItems.length !== 0){
                menuItems.filter(item=>{
                    this.menuItems.push(
                    {...item,
                    routeLink: menuItemRoutes[item.title].routeLink.replace(':language',browserLanguage),
                    target: menuItemRoutes[item.title].target } )
                })

            } else {
                // get from the store
            }
        })
        return this.menuItems;
    
        // )
      }

     getBrowserLang(){
        const browserLanguage = navigator.language;
        let agbLanguage = '';
        if (browserLanguage.includes('de')) {
            agbLanguage = 'de';
        } else if (browserLanguage.includes('fr')) {
            agbLanguage = 'fr';
        } else if (browserLanguage.includes('it')) {
            agbLanguage = 'it';
        } else {
            agbLanguage = 'en';
        }
        return agbLanguage;
      }
    
}
