/* eslint-disable @angular-eslint/component-class-suffix */

import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import Debug from 'debug';
import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
import { CartProvider, Cart } from '@modeso/dgoods-lib-cart-fe';
import { ViewChild, Injector, Component, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { Observable, Subject } from 'rxjs';
import { map , takeUntil , take , skip} from 'rxjs/operators';
import { BasePageComponent } from '../base.page';
import { UserProvider } from '@modeso/dgoods-lib-user-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { ProductLineDto } from '@modeso/types__dgoods-products';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
import { AppStateDispatcher } from '../../state/app.state.dispatcher';
import { ProductCheckoutComponent } from '../../components/ProductCheckout/product-checkout.component';
import { ILookUpAccountResponse } from '@modeso/dgoods-lib-user-fe/services/dgoods.user.service';
import { IContentBoxResponse } from '@modeso/types__dgoods-products';

export const vh = window.innerHeight * 0.01;

const debug = Debug('dgoods:project:ProductDetailsPage');

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss']
})
export class ProductDetailsPage extends BasePageComponent implements
  IDGoodsButtonDelegate{

  product: ProductLineDto;
  provider;
  productLineName: string;
  displayImage: string;
  checkBoxSelected: boolean;
  menuItems = [];
  backBtnRoute = '../../de-ch/landing-buy';
  menuState = MenuItemStates.CHILD;
  unviersalProductCode;
  currency;
  needsKYC = false;
  amount = 0;
  userEmail;
  purchaseBtnDisabled = true;
  twintTC;
  otpLength;
  phoneNumber
  productLineId;
  redirectFrom;
  observables = new Map<string, Observable<ProductLineDto>>();
  destroy$ = new Subject();
  discountCouponCode:string;
  couponFeatureState = false;
  userAcceptedProductTC = false;
  twintGTCs = false;
  isPageRefreshed = true;
  addedEventListener = false;

  expandableDescription = {
    title:'',
    text:''
  };

  contentBox: IContentBoxResponse = {
    productlineId: '',
    title: {
      en_us: '',
      de_ch: '',
      fr_ch: '',
      it_ch: ''
    },
    content: {
      en_us: '',
      de_ch: '',
      fr_ch: '',
      it_ch: ''
    },
    published: false,
  };


  @ViewChild('productCheckout', { static: false }) productCheckout: ProductCheckoutComponent;

  @ViewChild('couponForm') couponForm: ElementRef;
  @ViewChild('flyout') flyout: ElementRef;

  constructor(
    private analyticsProvider: AnalyticsProvider,
    public router: Router,
    private route: ActivatedRoute,
    private cartProvider: CartProvider,
    private productProvider: ProductStoreProvider,
    injector: Injector,
    private changeDetector: ChangeDetectorRef,
    private appStateProvider: AppStateDispatcher,
    private userProvider: UserProvider,

  ) {
    super(injector);
    this.productLineId = this.route.snapshot.paramMap.get('productLineId');
    this.getProductByProductLineIdeFromStore();
    this.savePageView('Product Buy');
    this.getUserEmail();
    this.getUserTwintTC();
    this.getCouponState();
    this.checkBoxSelected = sessionStorage.getItem('isP2P') === 'true' ? true : false;
    document.documentElement.style.setProperty('--vh', vh + 'px');

    // to override browser back btn history
    router.events
    .pipe(takeUntil(this.destroy$))
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.router.navigate([this.backBtnRoute])
    }});

    this.appStateProvider.getNavigationData()
    .pipe(takeUntil(this.destroy$))
    .subscribe((data)=>{
      // to set data back from the store
      if(!data.refreshed){
        this.amount = data.price;
        this.currency = data.currency;
        this.isPageRefreshed = data.refreshed
        this.unviersalProductCode = data.unviersalProductCode;
      }

    })
  }


  ngAfterViewChecked() {
    this.checkValidCheckout();
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.productCheckout.ngAfterViewInit();
  }

  hideFlyout = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {

    if(!this.addedEventListener){

      this.couponForm.nativeElement.querySelector('input').addEventListener('focus', (event)=>{
        this.hideFlyout = true;
      });
      this.couponForm.nativeElement.querySelector('input').addEventListener('blur', (event)=>{
        this.hideFlyout = false;
      });


      this.addedEventListener = true;
    }

  }




  /** NgInit */
  ngOnInit() {
    super.ngOnInit();
    this.getProduct();
    this.productCheckout?.ngOnInit();
    this.subscriptions.push(this.observables.get('getProductByProductLineIdeFromStore').subscribe(
        (product: ProductLineDto) =>  this.product = product));

    let requestedContentBox = false;
    this.observables.get('getProductByProductLineIdeFromStore').subscribe(x=>{
      this.productLineName =x.productLineName;
      this.needsKYC =x.needsKYC;
      this.contentBox = x.contentBox;
      debug(x)
    });


  }

  savePageView(pageName) {
    const productLine = JSON.parse(localStorage.getItem('products')).find(x => x.productLineId === this.productLineId);
    this.analyticsProvider.savePageView$({  terminalId: sessionStorage.getItem('terminalId'),
    productLine: productLine?.productLineName, pageName });
  }

  /** IDGoodsButtonDelegate --- START */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    debug('Button clicked');
    this.purchaseBtnDisabled = true;
    this.checkout();
  }
  /** IDGoodsButtonDelegate --- END */

  getProduct() {
    debug('subscribe to product observable');
    this.subscriptions.push(this.observables.get('getProductByProductLineIdeFromStore').pipe(
      map((product: ProductLineDto) => {
        if (product) {
          // get the product image
          let displayImage = product.productLineImages.find(x => x.imageSize === 'MASTER_IMAGE');
          if (displayImage === undefined) {
            displayImage = product.productLineImages.find(x => x.imageSize === 'LARGE');
          }
          this.displayImage = product.productLineImages && product.productLineImages && displayImage ?
            displayImage.frontImage : 'assets/placeholder.png';
        }
        if (!product) {
          this.router.navigate(['../../de-ch/error']);
        }
        return product;
      })
    ).subscribe((product: ProductLineDto) => {
      this.productLineName = product ? product.productLineName : null;
      this.redirectFrom = `de-ch/product-buy/${this.productLineId}`;
      this.provider = product;
      this.expandableDescription = {
        title: 'dgoods_shop_more_info',
        text: this.provider.productLineDescription
      }
    }));
  }

  checkValidCheckout() {

    // check all conditions valid for activating the checkout button after each change
    const mainValidations = this.userAcceptedProductTC && this.amount &&
      ((!this.twintGTCs && this.twintTC) || this.twintGTCs);

    this.purchaseBtnDisabled = mainValidations ? false : true;
  }


  getProductByProductLineIdeFromStore() {
    debug('register get product observable');
    this.observables.set('getProductByProductLineIdeFromStore', this.productProvider.getProduct$(this.productLineId));
  }

  validateEmailAndCheckout(checkoutRequest){

    if (this.userEmail) {
      localStorage.setItem('twintTC', true.toString());
      this.productCheckout.checkoutProduct(checkoutRequest)
    } else {

      let navigationData = {
        twintTC:this.twintTC,
        product:this.provider,
        amount:this.amount,
        couponState: this.couponFeatureState,
        currency:this.currency,
        refreshed: false
      }

      this.appStateProvider.saveNavigationData(navigationData);

      this.router.navigate([`de-ch/register-email/${this.productLineId}`])

    }
  }

  private checkout() {
    let checkoutRequest: Cart;
    debug('checkingout');
    this.getUserEmail();
    if(this.needsKYC){
      this.userProvider.getSweepayAccount$().pipe(take(2), skip(1)).subscribe((userData: ILookUpAccountResponse) => {

        if(userData ==null){
          debug('No user Data')
        } else {
          if (userData.isEmptyData === true) {

            localStorage.setItem('sweepayId',userData.sweepayId);

            let navigationData = {
              twintTC:this.twintTC,
              product:this.provider,
              amount:this.amount,
              couponState: this.couponFeatureState,
              currency:this.currency,
              refreshed: false
            }

            this.appStateProvider.saveNavigationData(navigationData);

            this.router.navigate([`de-ch/phone-registeration/${this.productLineId}`])
          } else if (userData.isEmptyData === false){

            checkoutRequest = {
                productId: this.unviersalProductCode,
                amount: this.amount,
                receiverEmail: this.userEmail,
                twintTC: this.twintTC ? 'true' : null,
                terminalId: sessionStorage.getItem('terminalId'),
              };

            if(this.discountCouponCode){
              checkoutRequest.couponCode = this.discountCouponCode
            }

            this.validateEmailAndCheckout(checkoutRequest);
        }
      }
    });

    } else {

      checkoutRequest = {
        productId: this.unviersalProductCode,
        amount: this.amount,
        receiverEmail: this.userEmail,
        twintTC: this.twintTC ? 'true' : null,
        terminalId: sessionStorage.getItem('terminalId'),
      };

      if(this.discountCouponCode){
        checkoutRequest.couponCode = this.discountCouponCode
      }
      this.validateEmailAndCheckout(checkoutRequest)
    }
  }

  couponCode(code){
    this.discountCouponCode = code;
  }

  private getUserEmail() {
    const email = localStorage.getItem('email');
    this.userEmail = email ? email : null;
  }

  private getUserTwintTC() {
    const twintTC = localStorage.getItem('twintTC');
    this.twintTC = twintTC ? twintTC : null;
    if(this.twintTC){
      this.appStateProvider.saveNavigationData({twintTermsAndConditions: true})
    }
  }

  getCouponState(){
    this.cartProvider.getCouponState$()
    this.subscriptions.push(
      this.cartProvider.selectCouponState$().subscribe((state)=>{
        this.couponFeatureState = state;
    }));
  }


  setGTCs(selection){
    this.twintGTCs = selection;
    this.checkValidCheckout();
  }

  selectedProductPrice(price){

    if(price.isDenomination){
      this.amount = price.denominatedPrice;
    }else{
      this.amount = price.minimumPrice;
    }

    this.unviersalProductCode = price.productId;
    this.currency = price.currency;

    this.appStateProvider.saveNavigationData({price:this.amount, currency:this.currency, unviersalProductCode: price.productId })
    this.checkValidCheckout();

  }

  enterPurchaseBtn(event){
    this.purchaseBtnDisabled = event;
  }

  checkProductTermsAndConditionAcceptance(event){
    this.userAcceptedProductTC = event;
    this.checkValidCheckout();
  }

  checkUserInvalidCodeLimitation(event){
    this.productCheckout.checkUserInvalidCodeLimitation(event)
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
