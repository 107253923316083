<div class="price-container" [ngClass]="{'selected' : price.selected}" (click)="openPopup(price)">

<span class="text" *ngIf="!price.isDenomination">{{price.currency}} {{displayedPrice}}</span>

<span class="text" *ngIf="price.isDenomination">
  <span *ngIf="!denominatedPrice">{{ 'dgoods_shop_enter_own_amount' | localize | async }}</span>
  <span *ngIf="denominatedPrice">{{price.currency}} {{denominatedPrice}}</span>
</span>

</div>


