import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProvider } from '@modeso/dgoods-lib-user-fe';
import { ISendMobilePhoneOtpRequest } from '@modeso/dgoods-lib-user-fe/services/dgoods.user.service';
import { take , takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MenuItemStates } from '../../../components/HamburgerMenu/hamburger-menu.component';
import { AppStateDispatcher } from '../../../state/app.state.dispatcher';
import { Subject } from 'rxjs';
import { Cart } from '@modeso/dgoods-lib-cart-fe';
import { ProductCheckoutComponent } from '../../../components/ProductCheckout/product-checkout.component';


@Component({
  selector: 'app-sweepay-otp',
  templateUrl: './sweepay-otp.view.html',
  styleUrls: ['./sweepay-otp.view.scss']
})
export class SweepayOTPComponent implements OnInit , AfterViewChecked {

  otpLength: number;
  phoneNumber: string;
  value = '' ;
  disablePurchaseBtn = true;
  otpForm:FormGroup;
  isMsgDisappear=true
  errorMsg :string;
  menuItems = []; 
  productId;
  menuState = MenuItemStates.CHILD;
  redirectFrom;
  navigationData;
  destroy$ = new Subject();
  sweepayOTPNavigation;
  userEmail;
  @ViewChild('productCheckout', { static: false }) productCheckout: ProductCheckoutComponent;


  constructor(private injector: Injector, 
              private userProvider: UserProvider, 
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef, 
              private router: Router,
              private appStateProvider: AppStateDispatcher) {


    this.productId = this.route.snapshot.paramMap.get('productLineId');
    
    this.sweepayOTPNavigation = {
      redirectTo : `de-ch/register-email/${this.productId}`,
      redirectFrom: `de-ch/phone-registeration/${this.productId}`,
      afterRefreshRedirection : `de-ch/product-buy/${this.productId}`
    }

    this.redirectFrom = `de-ch/otp-verification/${this.productId}`;

    this.userEmail = localStorage.getItem('email');

      // to override browser back btn history
      router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate([this.sweepayOTPNavigation.redirectFrom])
      }});

    }

  ngOnInit(): void {

    this.otpForm =this.fb.group({
      otpValue: this.fb.control('', { updateOn: 'blur' }),
    });

     // get Phone number from database
     this.userProvider.getUser$().pipe(take(2)).subscribe((userPhoneNumber: string)=>{
      this.phoneNumber = userPhoneNumber;
    });

    this.appStateProvider.getNavigationData()
    .pipe(takeUntil(this.destroy$))
    .subscribe((navigationData)=>{

      if(navigationData.refreshed){
        this.router.navigate([this.sweepayOTPNavigation.afterRefreshRedirection])
      }

      if(navigationData){
        this.navigationData = navigationData;
        this.otpLength = navigationData.otpLength;
      }

    })

  }
  ngAfterViewChecked(): void {
    this.setValidators()
    this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions && this.otpValue.valid );
    this.changeDetector.detectChanges();
  }

  onChange(value: string): void {

    if(value === ''){
      this.otpForm.reset()
    }
    this.isMsgDisappear = true;
    this.value = value;
    this.disablePurchaseBtn = !(this.navigationData.twintTermsAndConditions && this.navigationData.productTermsAndConditions && this.otpValue.valid );

  }

  setValidators() {
    if(this.otpLength){
      this.otpForm.get('otpValue').setValidators([Validators.required, Validators.maxLength(this.otpLength), Validators.minLength(this.otpLength)]);
      this.otpForm.get('otpValue').updateValueAndValidity();
    }else{
      this.otpForm.get('otpValue').setValidators(null);
      this.otpForm.get('otpValue').updateValueAndValidity();
    }
  }


  urlfn() {
    this.isMsgDisappear =true
    this.otpForm.reset()
    const body : ISendMobilePhoneOtpRequest= {
      otpIdentifier: localStorage.getItem('otpIdentifier'),
      sweepayId: localStorage.getItem('sweepayId')
    };
     this.userProvider.resendSweepayAccount$(body).pipe(take(2))
     .subscribe((userData) => {
       if(!userData) return;
       localStorage.setItem('otpIdentifier',userData.otpIdentifier);
    });
  }

  onButtonClicked() {
   
    this.disablePurchaseBtn = true;
    const body = {
      otpValue: this.value,
      otpIdentifier : localStorage.getItem('otpIdentifier'),
      sweepayId: localStorage.getItem('sweepayId')
    };

    this.userProvider.validateSweepayOtp$(body).pipe(take(2)).subscribe((userData) => {
        if(!userData) return;
        if(userData.isAccepted === true){
          
          if(this.userEmail){

            let checkoutRequest: Cart = {
              productId: this.navigationData.unviersalProductCode,
              amount: this.navigationData.price,
              receiverEmail: this.userEmail,
              twintTC: localStorage.getItem('twintTC') ? 'true' : null,
              terminalId: sessionStorage.getItem('terminalId'),
            };
            
            if(this.navigationData.couponCode){
              checkoutRequest.couponCode = this.navigationData.couponCode
            }
            
            this.productCheckout.checkoutProduct(checkoutRequest);

          }else{

            this.router.navigate([this.sweepayOTPNavigation.redirectTo]);

          }

        }else if(userData.isAccepted === false && userData.remainingAttempts>0){
          this.isMsgDisappear = false
          this.errorMsg = 'dgoods_shop_enter_code_again_sweepay';
        }else if (userData.isAccepted === false && userData.remainingAttempts<1) {
          this.isMsgDisappear = false
          this.errorMsg = 'dgoods_shop_wrong_otp_sweepay'
        } else if (userData.responseError){
          if(userData.responseError.code === 37) {
            this.isMsgDisappear = false
            this.errorMsg ='dgoods_shop_wrong_otp_sweepay'
          }else if (userData.responseError.code === 2){
            this.isMsgDisappear = false
            this.errorMsg ='dgoods_shop_enter_code_again_sweepay'
          }
          else {
            this.isMsgDisappear = true;
            this.router.navigate(['../../../de-ch/error'], { queryParams : {canUpgradeLimit: false, type: undefined }});
          }
        }
        this.disablePurchaseBtn  = false;
      });
  }



  get otpValue() {
    return this.otpForm.get('otpValue');
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
