<form #form="ngForm">
  <div>
    <div class="title">
      {{ 'dgoods_shop_enter_own_amount' | localize | async }}
    </div>
    <div>
      <mat-form-field appearance="fill" class="mat-form-field-max-width">
        <input #price name="price"  [(ngModel)]="priceValue" matInput  type="number" pattern="[0-9]*" inputmode="numeric" [min]="priceData.minimumPrice" [max]="priceData.maximumPrice" [errorStateMatcher]="matcher" />
        <mat-error>{{'dgoods_shop_own_amount_validation' | localize | async }} {{priceData.minimumPrice}}-{{priceData.maximumPrice}}</mat-error>
      </mat-form-field>
    </div>
    <mat-dialog-actions class="action-buttons">
      <button  mat-button class="btn" [mat-dialog-close]="false">{{ 'dgoods_shop_emailview_abort' | localize | async }}</button>
      <button  mat-button class="btn" (click)="confirm()" [disabled]="!priceValue || form.invalid">{{'dgoods_shared_overlaycompontent_ok' | localize | async}}</button>
    </mat-dialog-actions>
  </div>
</form>

