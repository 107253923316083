import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductsService } from './services/products.service';
import { CommonModule } from '@angular/common';
import * as fromProducts from './reducers/products.reducer';
import { ProductsEffects } from './effects/products.effects';
import { ProductsConfig, ProductsConfigService } from './products.config';
import { DigitalProductTileComponent } from './components/digital-product-tile/digital-product-tile.component';
import { DigitalProductListComponent } from './components/digital-product-list/digital-product-list.component';
import { CategorieFilterComponent } from './components/categorie-filter/categorie-filter.component';
import { ModesoLocalizationModule } from "../../../modeso-lib-localization-fe/src/lib/localization.module";
@NgModule({
    declarations: [
        DigitalProductTileComponent,
        DigitalProductListComponent,
        CategorieFilterComponent,
    ],
    providers: [ProductsService],
    exports: [
        DigitalProductTileComponent,
        DigitalProductListComponent,
        CategorieFilterComponent
    ],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromProducts.productsFeatureKey, fromProducts.reducer),
        EffectsModule.forFeature([ProductsEffects]),
        ModesoLocalizationModule
    ]
})
export class ProductsModule {
  static forRoot(config: ProductsConfig): ModuleWithProviders<ProductsModule> {
    return {
      ngModule: ProductsModule,
      providers: [
        ProductsService,
        {
          provide: ProductsConfigService,
          useValue: config
        },

      ]
    };
  }
}
