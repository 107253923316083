/* eslint-disable @angular-eslint/use-lifecycle-interface */
import {Component,  Input , Output , EventEmitter} from '@angular/core';
import { ProductDto, ProductLineDto } from '@modeso/types__dgoods-products';

@Component({
  selector: 'app-price-list-component',
  templateUrl:'./price-list.component.html',
  styleUrls: ['./price-list.component.scss'],
})
export class PriceListComponent {


  prices = []
  displayFullWidth;
  denominatedPrice; 
  @Input() product: ProductLineDto;
  @Input() isPageRefreshed;
  @Input() unviersalProductCode;  
  @Input() previousSelectedPrice;
  @Output() selectedPrice = new EventEmitter<any>();

  
  constructor() {}


   ngAfterViewInit(){

    this.prices = [...this.product.availableProducts];

    // sort by value then sort by denomenation value
    this.prices.sort((a, b) => a.minimumPrice && (a.minimumPrice > b.minimumPrice) ? 1 : -1);
    this.prices.sort((a, b) => ((a.isDenomination as any) - (b.isDenomination as any)));

    this.displayFullWidth = this.prices.length % 2;

    if(this.isPageRefreshed){
      this.prices = this.prices.map((price) => ({
        ...price,
        selected : price.productId === this.prices[0].productId && !price.isDenomination }))
  
      if(!this.prices[0].isDenomination){
        this.selectedPrice.emit(this.prices[0])
      }
      
    }else{

      // price was selected before and fetched from the store

      this.prices = this.prices.map((price) => {
        
        if(price.productId === this.unviersalProductCode && price.isDenomination){
          this.denominatedPrice = this.previousSelectedPrice;
        }

       return {...price, selected : price.productId === this.unviersalProductCode  }
      })
    }

  

   }

   selectPrice(productPrice){
    if(!productPrice.isDenomination){
      this.prices.map((price) =>  price.selected = price.productId === productPrice.productId && !price.isDenomination );
      this.selectedPrice.emit(productPrice)
    }
   }

   denominatedPriceSelected(event){

    if(event.denominatedPrice){
      this.prices.map((price) =>  price.selected = price.productId === event.price.productId );
      this.selectedPrice.emit({...event.price,denominatedPrice:event.denominatedPrice}) 
    }

  }
}
