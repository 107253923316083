/* eslint-disable @angular-eslint/component-class-suffix */
import {
  DGoodsButtonComponent, IDGoodsTableDelegate,
  IDGoodsButtonDelegate, DGoodsNavigationComponent
} from '@modeso/twint-lib-core-fe';
import { Location } from '@angular/common';
import { Injector, OnInit, Directive, Component } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { MenuItemStates } from '../../components/HamburgerMenu/hamburger-menu.component';
const debug = Debug('dgoods:shop:OrderConfirmationController');

@Component({
  template: `<ng-content></ng-content>`,
// tslint:disable-next-line: directive-selector
selector: 'app-order-confirmation.page-controller'
})
export class OrderConfirmationControllerDirective extends BasePageComponent implements
OnInit,
IDGoodsButtonDelegate {

  public showNavigationBar = !this.route.snapshot.queryParams['navigation'] ? true : false;
  terminalId: string;
  menuItems = [];
  backBtnRoute = '../../de-ch/history';
  menuState = MenuItemStates.CHILD;

  constructor(private analyticsProvider: AnalyticsProvider, private location: Location, private injector: Injector, private router: Router,
              private route: ActivatedRoute, private store: Store<fromApp.AppState>) {
    super(injector);
    this.terminalId = sessionStorage.getItem('terminalId');
  }

  ngOnInit() {
    super.ngOnInit();
    this.savePageView();
  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Order Confirmation',  terminalId: this.terminalId });
  }

  onButtonClicked(sender: DGoodsButtonComponent): void {

    const backToPurchasingHistory = this.route.snapshot.queryParams['navigation'];
    if (!backToPurchasingHistory) {
      this.switchToAppAndCloseWindow();
    } else {
      this.router.navigate(['../../de-ch/history']);
    }

  }

  onFailedStatus() {
    this.router.navigate(['../../de-ch/paymentcanceld']);
  }

  //refund payment
   onRefundStatus() {
    this.router.navigate(['../../de-ch/paymentrefund']);
  }

  switchToAppAndCloseWindow() {
    const device = localStorage.getItem('device');
    const protocol = localStorage.getItem('protocol');
    if (!protocol || !device) {
      debug('state is not defined');
      return;
    }
    debug(device);
    debug(protocol);
    if (device === 'ios') {
    // [returnAppScheme]://ch.twint.wallet
      const redirectURL = `${protocol}://ch.twint.wallet`;
      debug(redirectURL);
      window.location.href = redirectURL;
    } else if (device === 'android') {
    // tslint:disable-next-line:max-line-length
    //intent://ch.twint.wallet#Intent;package=[returnAppPackage];scheme=twint;end
      const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
      debug(`android browser-app-switch-redirect ${redirectURL}`);
      debug(redirectURL);
      window.location.href = redirectURL;
    }
  }

  /** IDGoodsNavigationDelegate --- START */

  onBackClicked(sender: DGoodsNavigationComponent) {
    this.router.navigate(['../../de-ch/history']);
  }

/** IDGoodsNavigationDelegate --- END */
}
