<div *ngIf="couponState" class="code-container" #couponInput2>

  <span class="title">{{ 'dgoods_shop_add_code_placeholder' | localize | async }}</span>

  <form [formGroup]="codeForm" autocomplete="off" >
<div class="form-layout">
    <div class="column-flex">
      <mat-form-field  appearance="outline" class="coupon-layout">
        <mat-label class="coupon-form-field-label">{{ 'dgoods_shop_enter_code' | localize | async }}</mat-label>
        <input type="text" #couponInput matInput formControlName="discountCode" (change)="onChangeCode()" (focus)="handleFocused()">
      </mat-form-field>

      <div *ngIf="codeValid && discountCode.valid && discountAmount > 0  && !idle" class="valid-text-div">
        <span  class="valid-text" [innerHTML]="'dgoods_shop_valid_code_text' | localize | async | replace:'{{originalPrice}}': formattedAmount
        | replace:'{{discountAmount}}': formattedDiscountAmount | replace:'{{discountPrice}}': formattedDiscountPrice  | markdownToSafeHtml">
        </span>
      </div>

    </div>
    <div class="second-column">
      <button type="button" class="use-code-button" [disabled]="!this.amount || !this.discountCode.value || codeForm.invalid || hasAppliedCode || !minLengthValidationIsOk" (click)="submit()">
        {{ 'dgoods_shop_use_code_button' | localize | async }}
      </button>

    </div>
  </div>

  </form>
  <mat-error class="error-text" *ngIf="discountCode.value && discountCode.invalid && !idle">{{ getErrorMessage() | localize | async }}</mat-error>

</div>
