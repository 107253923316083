<div>
  <div class="terms-of-service-container">
    <div class="title-content-container">
      <dgoods-title-with-content title="dgoods_shared_termsofservicecomponent_title">
        <div [ngClass]="enforceTC? 'content enforce' : 'content'">
          <div [innerHtml]="decodeTermsAndCondition"></div>
        </div>
      </dgoods-title-with-content>
    </div>
    <div class="button-container">
      <div class="sticky" *ngIf="enforceTC===false">
        <dgoods-button *ngIf="enforceTC===false" [delegate]="this">
          {{ "dgoods_shared_termsofservicecompontent_conclude" | localize | async }}
        </dgoods-button>
      </div>

      <div *ngIf="enforceTC">

          <div class="button-padding-style">
            <dgoods-button [delegate]="this" [disabled]="false">
              {{ "dgoods_shared_termsofservicecompontent_agree" | localize | async }}
            </dgoods-button>
          </div>

          <dgoods-button [delegate]="this" [state]="'notAction'">
            {{ "dgoods_shared_termsofservicecompontent_disagree" | localize | async }}
          </dgoods-button>
      </div>
    </div>
  </div>
</div>
