import { Component, OnInit } from '@angular/core';
import { ProductStoreProvider } from '@modeso/dgoods-lib-products-fe';
import { NewstickerTypes , PublishStatus} from '@modeso/types__dgoods-products';
import { switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-news-ticker',
  templateUrl: './news-ticker.component.html',
  styleUrls: ['./news-ticker.component.scss']
})
export class NewsTickerComponent implements OnInit {

  currentItem: any;

  constructor(
    protected productProvider: ProductStoreProvider,
    ) {
  }

  ngOnInit() {

    const  blueKey = Object.keys(NewstickerTypes)[Object.values(NewstickerTypes).indexOf(NewstickerTypes.BLUE)];
    const  blackKey = Object.keys(NewstickerTypes)[Object.values(NewstickerTypes).indexOf(NewstickerTypes.BLACK)];



    this.productProvider.selectNewsTicker().pipe(
      take(1), // Limit to only one emission
      tap((response) => {
        if (!response) {
          // State is not loaded, dispatch action to get all content pages
          this.productProvider.getNewsTicker(PublishStatus.PUBLISHED).pipe(take(2));
        }
      }),
      switchMap(() => this.productProvider.selectNewsTicker()), // Retrieve content pages after ensuring state is loaded
      take(2)
    ).subscribe( newsticker => {

      if (newsticker) {
        let colorBG;
        let fontColor;
        const language = `${document.documentElement.lang}` === 'en' ? 'en-us' : `${document.documentElement.lang}-ch`;


        if (newsticker.type === blueKey) {
          colorBG = NewstickerTypes.BLUE;
          fontColor= "#000000"
        } else if (newsticker.type === blackKey) {
           colorBG = NewstickerTypes.BLACK;
          fontColor= "#FFFFFF"
        }

        if (newsticker.text) {
          this.currentItem = {
            text: newsticker.text[language],
            color: colorBG,
            fontColor
          };
          return this.currentItem;
        }

      }
    });


  }
}
