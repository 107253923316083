import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/dgoods.analytics.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { DgoodsAnalyticsService } from '../services/dgoods.analytics.service';
const debug = Debug('modeso:twint-lib-analytics-fe:AnalyticsEffects');


@Injectable()
export class AnalyticEffects {

  constructor(private actions$: Actions, private service: DgoodsAnalyticsService) { }

  onSavePageView$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onSavePageView.type),
      mergeMap(
        (payload: any) => {
          return this.service.savePageView(payload.payload)
            .pipe(
              // retry(1),
              tap(response => debug(response.message)
              ),
              map(
                response => (fromActions.onSavePageViewSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onSavePageViewFailed()))
            );
        }
      )
    )
  );

  errorOnSavePageView$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onSavePageViewFailed.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllAnalyticsErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllAnalyticsErrors(error) {
    debug(error);
    return error;
  }
}
