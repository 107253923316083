import { Component, Input, TemplateRef } from '@angular/core';
import { ProductLineDto } from '@modeso/types__dgoods-products';
import { IDigitalProductTileDelegate } from '../digital-product-tile/digital-product-tile.component';
import { LoadingService } from 'projects/dgoods-project-shop/src/app/shared/services/loading.service';

export interface IDigitalProductListDelegate {
  onListItemClicked(productLine: ProductLineDto): void;
}

@Component({
  selector: 'modeso-digital-product-list',
  templateUrl: './digital-product-list.component.html',
  styleUrls: ['./digital-product-list.component.scss']
})
export class DigitalProductListComponent implements IDigitalProductTileDelegate {

  @Input() templateRef: TemplateRef<ProductLineDto>;
  @Input() products: ProductLineDto[] = [];
  @Input() delegate?: IDigitalProductListDelegate;
  imagesLoadedCount: number = 0;
  imagesLoadedTimeoutStarted = false;
  imagesWithErrorCount: number = 0;

  constructor(private loadingService: LoadingService) {
    this.loadingService.setlandingPageLoading(true);
  }

  onTileClick(product: ProductLineDto): void {
    if (this.delegate) {
      this.delegate.onListItemClicked(product);
    }
  }

  hasEvenProducts() {
    return this.products.length % 2 === 0;
  }
  handleImageLoaded(state: boolean){
    if (!this.imagesLoadedTimeoutStarted){
      this.imagesLoadedTimeoutStarted = true;
      setTimeout(() => {
        this.loadingService.setlandingPageLoading(false);
      } , 6000);
      // Start a timeout after the first image is loaded to show the page after 5secs even if some images are not loaded yet.
    }

    if (state){
      this.imagesLoadedCount++;
    }else{
      this.imagesWithErrorCount++;
    }
    if(this.imagesLoadedCount >= (this.products.length - this.imagesWithErrorCount)){
      this.loadingService.setlandingPageLoading(false);
    }
  }
}
